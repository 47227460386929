@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url("../assets/Proxima-Nova-Sbold.otf") format("openType"); }

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url("../assets/ProximaNova-Regular.woff") format("openType"); }

@font-face {
  font-family: "OrpheusPro";
  font-weight: 600;
  src: url("../assets/fonts/OrpheusPro/OrpheusPro-Bold.eot");
  src: url("../assets/fonts/OrpheusPro/OrpheusPro-Bold.eot") format("embedded-opentype"), url("../assets/fonts/OrpheusPro/OrpheusPro-Bold.woff2") format("woff2"), url("../assets/fonts/OrpheusPro/OrpheusPro-Bold.woff") format("woff"), url("../assets/fonts/OrpheusPro/OrpheusPro-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "OrpheusPro";
  font-weight: 400;
  src: url("../assets/fonts/OrpheusPro/OrpheusPro-Medium.eot");
  src: url("../assets/fonts/OrpheusPro/OrpheusPro-Medium.eot") format("embedded-opentype"), url("../assets/fonts/OrpheusPro/OrpheusPro-Medium.woff2") format("woff2"), url("../assets/fonts/OrpheusPro/OrpheusPro-Medium.woff") format("woff"), url("../assets/fonts/OrpheusPro/OrpheusPro-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "OrpheusPro";
  font-weight: 200;
  src: url("../assets/fonts/OrpheusPro/OrpheusPro.eot");
  src: url("../assets/fonts/OrpheusPro/OrpheusPro.eot") format("embedded-opentype"), url("../assets/fonts/OrpheusPro/OrpheusPro.woff2") format("woff2"), url("../assets/fonts/OrpheusPro/OrpheusPro.woff") format("woff"), url("../assets/fonts/OrpheusPro/OrpheusPro.ttf") format("truetype"); }

@font-face {
  font-family: "OrpheusPro";
  font-style: italic;
  src: url("../assets/fonts/OrpheusPro/OrpheusPro-Italic.eot");
  src: url("../assets/fonts/OrpheusPro/OrpheusPro-Italic.eot") format("embedded-opentype"), url("../assets/fonts/OrpheusPro/OrpheusPro-Italic.woff2") format("woff2"), url("../assets/fonts/OrpheusPro/OrpheusPro-Italic.woff") format("woff"), url("../assets/fonts/OrpheusPro/OrpheusPro-Italic.ttf") format("truetype"); }

@font-face {
  font-family: "ProximaNova";
  font-weight: 700;
  src: url("../assets/fonts/ProximaNova/ProximaNova-Bold.eot");
  src: url("../assets/fonts/ProximaNova/ProximaNova-Bold.eot") format("embedded-opentype"), url("../assets/fonts/ProximaNova/ProximaNova-Bold.woff2") format("woff2"), url("../assets/fonts/ProximaNova/ProximaNova-Bold.woff") format("woff"), url("../assets/fonts/ProximaNova/ProximaNova-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "ProximaNova";
  font-weight: 600;
  src: url("../assets/fonts/ProximaNova/ProximaNova-Semibold.eot");
  src: url("../assets/fonts/ProximaNova/ProximaNova-Semibold.eot") format("embedded-opentype"), url("../assets/fonts/ProximaNova/ProximaNova-Semibold.woff2") format("woff2"), url("../assets/fonts/ProximaNova/ProximaNova-Semibold.woff") format("woff"), url("../assets/fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype"); }

@font-face {
  font-family: "ProximaNova";
  font-weight: 400;
  src: url("../assets/fonts/ProximaNova/ProximaNova-Regular.eot");
  src: url("../assets/fonts/ProximaNova/ProximaNova-Regular.eot") format("embedded-opentype"), url("../assets/fonts/ProximaNova/ProximaNova-Regular.woff2") format("woff2"), url("../assets/fonts/ProximaNova/ProximaNova-Regular.woff") format("woff"), url("../assets/fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype"); }

body {
  overflow-x: hidden !important; }
