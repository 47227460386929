@import "flex";
@import "~react-image-gallery/styles/css/image-gallery.css";

$Background_for_Rows_Color: #f5f7f8;
$Dark_Titles_Color: #3d4148;
$Small_Light_Grey_Text_Color: #8a8a8a;
$Light_Grey_Status_Color: #e3e3e3;
$Light_Green_Status_Color: #ceffce;

$Border_Color: #e3e3e3;
$Light_Blue_Color: #b6d2f2;
$Light_Red_Color: #f1cece;
$Dark_Red_Color: #aa0707;

body::-webkit-scrollbar-track {
  // background-color: darkgrey;

  width: 0.5em;
}

body {
  font-family: "Proxima Nova Regular";
}
.modal {
  @media (max-width: 767px) {
    display: flex !important;
    align-items: center;
  }
}
._loading_overlay_overlay {
  z-index: 111111 !important;
  height: 100vh !important;
  .css-42igfv {
    position: fixed !important;
    left: 50%;
    top: 45%;
    @media (max-width: 767px) {
      left: 40%;
      top: 50%;
    }
  }
}
.es__d-flex {
  display: flex;
}
.es__flex-xs-column {
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.Toastify__toast-container {
  z-index: 10021 !important;
}
.css-1mnns6r {
  position: relative;
}
.Toastify__toast {
  min-height: 54px !important;
}
.Toastify__toast--default {
  background: #cecece !important;
  color: #3c3c3c !important;
}
@media (max-width: 767px) {
  .Toastify__toast-container {
    width: fit-content !important;
    padding: 0 !important;
    right: 10px !important;
    left: auto !important;
    top: 10px;
    .Toastify__toast-body {
      padding: 0 20px !important;
    }
  }
}
.original {
  .dia-image-icon {
    min-width: 60px;
    min-height: 90px;
    max-height: 90px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .product-image-icon {
    max-width: 100%;
    max-height: 100px;
    margin-top: 12px;
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  .pos-relative {
    position: relative;
  }
  .iframeModal {
    z-index: 10000;
    cursor: pointer;
    .modal-dialog {
      width: 95vw;
      @media (max-width: 767px) {
        width: 100%;
        max-height: 90%;
      }
    }
    .modal-body {
      padding: 0;
    }
    iframe {
      width: 100%;
      min-height: 85vh;
      @media (max-width: 767px) {
        min-height: 75vh;
      }
    }
  }
  .iframe-link {
    cursor: pointer;
  }
  .image-modal {
    z-index: 10000;
    cursor: pointer;
    .modal-dialog {
      @media (max-width: 767px) {
        min-width: 95%;
        max-height: 90%;
      }
      .modal-content {
        border-radius: 0;
        border: 1px solid grey;
        .modal-body {
          min-height: 40vh;
          .image-gallery-image {
            max-height: 68vh;
            min-height: 68vh;
          }
          .image-gallery-thumbnails-container {
            float: left;
            .image-gallery-thumbnail {
              width: 80px;
            }
          }
        }
      }
    }
    .modal-header {
      border-bottom: 0;
      padding: 15px 15px 0px 15px;
      .close:focus {
        outline: none;
      }
    }
    .image-modal-con {
      text-align: center;
      img{
        max-width: 60%;
      }
    }
  }
  //css of Full modal

  .full-popup-modal {
    background: none !important;
    padding: 0 !important;
    font-family: "Proxima Nova Regular";
    .customer-name {
      color: red !important;
    }
    .product-ring-detail {
      min-width: 430px;
      margin-right: 8px;
      .product-info_box {
        padding-left: 5px !important;
      }
    }
    .product-stock_details {
      width: 28%;
      min-width: 280px;
      margin-right: 8px;
      .product-info_box {
        padding-left: 5px !important;
      }
      .product-info_box {
        min-height: 130px;
      }
      .product-info_background {
        padding: 0px 10px 0px 10px;
        line-height: 18px;
      }
    }

    .edit-btn {
      float: right;
      margin-right: 16px;
    }
    .modal-dialog {
      max-width: 50%;

      .product-d-flex-container {
        margin-top: 10px;
        div {
          padding-left: 0;
        }
      }
      .modal-content {
        img {
          width: 100%;
          height: auto;
        }
        .product-img {
          border: 1px solid #e3e3e3;
          margin-bottom: 15px;
          background-color: #e3e3e3;
        }
        h3 {
          text-align: center;
          color: red;
        }
        .cust-name {
          color: red;
        }
        border-radius: 0;
        border: 2px solid #8a8a8a;
        background-color: ghostwhite;
        .modal-header-con {
          text-align: center;
          .modal-title {
            color: $Dark_Titles_Color;
            margin-left: 66px;
          }
          border-bottom: 0;
        }
      }
    }
  }

  .stock-popup-modal {
    font-family: "Proxima Nova Regular";
    background: none !important;
    padding: 0 !important;
    .product-details_line-break {
      font-size: 16px;
    }
    .product-details-view-label {
      font-size: 14px !important;
      line-height: 24px !important;
      color: #58585a !important;
    }
    .product-details-label {
      color: #58585a !important;
    }
    .highlight-text {
      color: green !important;
    }
    .border-bottom {
      border-bottom: 1px dotted black;
      margin-bottom: 13px;
    }
    .product-details_lcol {
      margin-right: 50px;
      @media (max-width: 767px) {
        margin-right: 0px;
      }
    }
    .product-details_mcol {
      @media (max-width: 767px) {
        margin-left: 8px;
      }
    }
    .product-info_background {
      padding-top: 15px;
      padding-left: 15px;
      section.brought_in_for {
        justify-content: flex-end !important;
        > div {
          width: 44%;
          flex: inherit;
        }
      }
    }
    .see-more {
      margin-top: 5px;
      text-align: center;
      .see-more-btn {
        background: #8a8a8a;
        background-image: none;
        text-shadow: none;
        color: #fff;
        width: 50%;
        border-radius: 0;
      }
    }
    z-index: 9999;
    .modal-dialog {
      max-width: 50%;
      @media (max-width: 767px) {
        min-width: 95%;
        max-height: 90%;
      }
      .modal-content {
        border-radius: 0;
        border: 2px solid #8a8a8a;
        .modal-header-con {
          text-align: center;
          .modal-title {
            color: $Dark_Titles_Color;
          }
          border-bottom: 0;
        }
      }
    }
  }

  .product {
    width: 100%;
    display: flex;
    margin-bottom: 4px;
    border: 1px solid #d2d4d7;
    background: $Background_for_Rows_Color;
    padding: 12px 0 8px;
    font-size: 0.75em;
    @media (max-width: 767px) {
      font-size: 0.65em;
    }
    .index-share {
      min-height: 100%;
      width: 2%;
      margin: 0 5px;
      @media (max-width: 767px) {
        width: 4%;
      }
      .product-index_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        @media (max-width: 767px) {
          width: auto;
        }
        .mobile__basket__add-section {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 10px;
          .es__basket-icon,
          .mail__share-icon,
          .print__share-icon {
            width: 100%;
          }
        }
      }
      .serial-hovered {
        display: none !important;
        @media (max-width: 767px) {
          display: flex;
        }
      }
      .es__product__hovered {
        width: 100%;
        padding: 2px !important;
        margin: 0;
        display: none;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: space-around;
        cursor: pointer;
        .es__basket-icon {
          width: 18px;
        }
        .share-icons {
          width: 15px;
        }
      }
      .share-hovered {
        display: flex;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .product-details_lcol {
      text-align: left;
    }
    .product-details-label {
      margin: 3px 0 8px 0;
      color: #3d4148;
      a {
        color: #337ab7;
      }
      a:hover {
        color: #23527c;
      }
      span {
        margin-top: 2px;
        line-height: 10px;
      }
    }
    .product-customer-details-label {
      margin: 3px 0 0 0;
      color: $Dark_Red_Color;
      line-height: 7px;
    }

    .product-info_box_heading {
      padding: 5px;
      margin: 0;
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      text-align: center;
      span {
        background: $Background_for_Rows_Color;
        margin-left: 8px;
      }
    }
    .stock-modal-info_box_heading {
      span {
        background: #fff !important;
        font-size: 16px;
      }
    }
    .es__isfl {
      position: relative;
      height: 17px;
      margin-left: 0 !important;
      padding: 0 !important;
      bottom: 3px;
      content: url("https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/FL-DarkGrey.svg");
    }
    .es__istiara {
      position: relative;
      width: 20px;
      margin: 0px 2px !important;
      padding: 0 !important;
      bottom: 2px;
      content: url(https://svgshare.com/i/G9L.svg);
    }
    .product-stylenumber_heading {
      padding: 5px;
      margin: 0;
      position: absolute;
      top: -10px;
      display: flex;
      left: 0;
      right: 0;
      text-align: left;
      span {
        background: $Background_for_Rows_Color;
        padding: 1px 2px;
        margin-left: 8px;
      }
      img {
        background-color: white;
        cursor: pointer;
      }
    }
    .loose-results {
      line-height: 11px !important;
    }
    .additional-height {
      line-height: 15px !important;
    }

    .product-info_background {
      line-height: 11px;
      padding: 4px 10px 0px 10px;
      .product-description {
        font-size: 12px;
        font-style: italic;
        margin-top: 2px;
        margin-bottom: 5px;
        display: flex;
        column-gap: 8px;
        span:nth-child(2) {
          color: #337ab7;
          font-size: 12px;
          cursor: pointer;
          font-style: normal;
        }
      }
    }

    .product-image_background {
      padding-top: 1px;
    }
    .product-d-flex-container {
      display: flex !important;
      width: 88%;
      @media (max-width: 767px) {
        width: 65%;
      }
      & > div {
        padding-left: 8px;
      }
      @media (max-width: 767px) {
        .mobile-view {
          display: none;
        }
      }
      .holding-details {
        width: 25%;
        padding-right: 8px;
        @media (min-width: 768px) and (max-width: 1024px) {
          width: 20%;
        }

        .product-info_box {
          display: flex;
          flex-direction: column;
          gap: 0px;
          padding: 10px 2px 2px 2px;
          .hold_notes_actions {
            position: relative;
            top: -10px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            /* padding: 0 10px; */
            justify-content: end;
            gap: 0;
            span {
              cursor: pointer;
              color: cornflowerblue;
              padding: 0px 8px;
              font-size: 17px;
              display: inline-block;
              box-sizing: border-box;
              i {
                font-size: 15px;
                cursor: pointer;
              }
            }
            // span:first-child {
            //   text-decoration: underline;
            // }
          }
          .hold_notes_actions + .product-info_background {
            position: relative;
            top: -10px;
          }
        }
        .entered-by,
        .customer-name {
          word-break: break-word;
        }
        .holding-description {
          .holding-con {
            word-break: break-all;
          }
        }
        .is_hold_sold {
          color: red;
        }
        .product-info_background {
          padding: 0px 10px 0px 10px;
          line-height: 14px;
          display: flex;
          flex-direction: column;
          // align-items: center;
          gap: 5px;
          @media (min-width: 768px) and (max-width: 1024px) {
            .responsive-hold-notes {
              display: table-caption;
            }
          }
          .product-details-label {
            margin: 0;
          }
        }
      }
      .product-details {
        width: 50%;
        @media (max-width: 767px) {
          width: 100%;
          padding-right: 7px;
        }
        .product-info_background {
          padding: 0px 10px 0px 10px;
          .flex-con {
            display: flex;
          }
          .product-description-con {
            word-break: break-word;
          }
          .product-description_info {
            word-break: break-word;
            cursor: auto;
          }
          .product-details_info {
            font-family: "Proxima Nova Semibold";
          }
          .pdf-icon {
            height: 12px;
            width: auto;
            margin-left: 5px;
            margin-top: -3px;
          }
          .cursor-pointer {
            cursor: pointer;
          }
          .cursor-none {
            cursor: auto;
          }
        }
      }
      .product-status_details,
      .product-sold_details,
      .product-pricing_details {
        width: 14%;
        @media (min-width: 768px) and (max-width: 1024px) {
          width: 17%;
        }
        .product-status-con {
          .product-details-label {
            div {
              line-height: 11px;
            }
          }
          .status,
          .location {
            word-break: break-all;
          }
        }
        .product-info_background {
          padding: 3px 10px 0px 10px;
        }
        .product-info_box {
          padding-left: 8px;
        }

        section {
          line-height: 7px !important;
          .product-details_lcol {
            line-height: 7px;
          }
          .product-details_mcol {
            .product-details-label {
              position: relative;
              top: -2px;
            }
          }
        }
        section:last-child {
          padding-top: 0px;
          .product-details-label {
            margin-top: 0px;
          }
        }
      }
    }
    .product-info_box {
      padding: 10px 2px 2px 2px;
      border: 1px solid #e3e3e3;
      height: 100%;
      background: #fff;
    }
    .product-image_box {
      border: 1px solid #e3e3e3;
      height: 100%;
      background: #fff;
      .web-image-icon {
        width: 23px;
        float: right;
        cursor: pointer;
      }
    }
    // .product-index_container {
    //   display: flex;
    //   align-items: center;
    //   vertical-align: middle;
    //   max-width: 110px;
    // }

    .product-image_container {
      background: #fff;
      width: 9%;
      @media (max-width: 767px) {
        width: 30%;
      }
      .not-clickable {
        cursor: default;
      }

      .product-image {
        top: 14%;
        left: 0;
        right: 0;
        margin-top: 6px !important;
        margin-left: 4px !important;
        margin-right: 4px;
        margin-bottom: 6px;
        text-align: center;
        .result-images {
          max-width: 100px !important;
          max-height: 100px !important;
        }
      }
    }
    .product-details,
    .holding-details,
    .product-ring-detail,
    .product-sold_details,
    .product-stock_details {
      section {
        display: flex;
        justify-content: space-around;
        & > div {
          flex: 1 1 0;
        }
      }
    }

    .product-dark_title_color {
      color: $Dark_Titles_Color;
      font-family: "Proxima Nova Semibold";
      font-size: 1em;
      font-weight: bold;
      text-transform: uppercase;
    }
    .product-light_title_color {
      color: $Small_Light_Grey_Text_Color;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .product-details-info_label {
      font-size: 9px;
      color: $Small_Light_Grey_Text_Color;
      opacity: 0.5;
      margin-left: 5px;
    }
    .product-details-view-label {
      font-size: 10px;
      color: $Small_Light_Grey_Text_Color;
      opacity: 0.5 !important;
    }
    .product-details_line-break {
      display: block;
      // line-height: 7px;
      // padding-bottom: 2px;
    }
    .product-status {
      position: absolute;
      bottom: 0px;
      width: 100%;
      text-align: center;
      left: 0;
      padding: 2px 5px;
      &.mounted {
        background: #e3e3e3;
        color: #3d4148;
        text-transform: uppercase;
      }
      &.sold {
        background: $Light_Blue_Color;
        color: #3d4148;
        text-transform: uppercase;
        cursor: pointer;
      }
      &.adjusted {
        background: $Light_Blue_Color;
        color: #3d4148;
        text-transform: uppercase;
        cursor: pointer;
      }
      &.kwiat {
        background: $Light_Blue_Color;
        color: $Dark_Red_Color;
        text-transform: uppercase;
      }
      &.RTV {
        background: $Light_Red_Color;
        color: #3d4148;
        text-transform: uppercase;
      }
      &.in-production {
        background: #e3e3e3;
        color: #aa0707;
        text-transform: uppercase;
      }
      &.semimount {
        background: #e3e3e3;
        color: #aa0707;
        text-transform: uppercase;
      }
      &.ponumber {
        background: #b6d2f2;
        color: #3d4148;
        text-transform: uppercase;
      }
      &.is-showcase {
        background: $Light_Green_Status_Color;
        color: #3d4148;
        text-transform: uppercase;
      }
    }
    .es__mobile-product {
      display: none;
      width: 100%;
      @media (max-width: 767px) {
        display: block;
        text-align: right;
      }
      table {
        width: 100%;
        tbody {
          line-height: 21px;
          td {
            padding-left: 3px;
            font-weight: 700;
            width: 50%;
            text-align: left;
            .es__mobile-product-details-table__label {
              font-size: 0.75em;
              color: #a0a0a0;
              margin-left: 6px;
              font-weight: 100;
            }
            .pdf-icon {
              height: 13px;
              margin: -1px 0px 0px 7px;
            }
            button {
              width: 22px;
              padding: 0;
              background-color: #f9f9f9;
              margin-bottom: 3px;
              border-radius: 0px;
              float: right;
              margin-right: 6px;
              border: #d6e0e8 1px solid;
              box-shadow: none;
              background-image: none;
            }
          }
          .gia-pdf-data {
            > div {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  // old scss start

  .page {
    /*padding: 1.5em;*/
    background: #ffffff;
  }
  #container {
    border: 10px solid;
    padding: 5px;
  }
  .img-title {
    margin-top: 22px;
    margin-left: 5px;
    height: 69px;
    width: 90px;
    border: 2px solid grey;
    h1 {
      width: 30px;
      font-size: 8px;
      margin-top: -10px;
      margin-left: 35px;
      background: white;
    }
  }

  .image-box {
    width: 100%;
    height: 88%;
    margin: 10px 0px 0px 20px;
    border: 1px solid lightgray;
    border-top: 0px;
    .background {
      display: table;
      white-space: nowrap;
      > span {
        margin-top: 2px;
        display: inline-block;
        color: #b5acac;
      }
      &:before,
      &:after {
        border-top: 1px solid lightgray;
        content: "";
        display: table-cell;
        position: relative;
        top: 0.5em;
        width: 45%;
      }
      &:before {
        right: 0.5%;
        top: 11px;
      }
      &:after {
        left: 0.5%;
        top: 11px;
      }
    }

    p {
      margin: 0 auto;
      position: relative;
      top: -12px;
      font-size: 12px;
      font-weight: bold;

      span {
        font-weight: normal;
      }
    }
  }

  #title {
    float: left;
    padding: 0 5px;
    margin: -20px 0 0 30px;
    background: #fff;
  }

  .dropdown-sort > div > ul > ul > li:hover {
    background-color: #efefef;
  }
  .dropdown-sort {
    float: right;
    margin-right: 30px;
  }
  .row {
    overflow: visible !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .title {
    h3 {
      margin-top: 0;
      margin-bottom: 30px;
      text-transform: uppercase;
    }
  }
  .es-scroll-button {
    .ScrollUpButton__Container {
      border: 5px solid lightgrey !important;
      margin-right: 12px;
      > svg {
        fill: lightgrey;
      }
    }
  }

  .es__base-component {
    font-family: "Proxima Nova Regular" !important;
  }
  .es-product-loader {
    text-align: center;
    padding: 5em;
    font-size: 22px;
  }
  .is-fl {
    .check-filters__toggle {
      display: flex;
      h2 {
        margin-top: 10px;
        margin-left: 10px;
        font-size: 14px;
        color: #58585a;
        margin-bottom: 0px;
      }
      .check-filters__toggle--check-select {
        a {
          font-size: 14px;
          background: #ffff;
          border-color: #b3afaf;
          min-height: 90%;
          width: 14px;
          margin-top: 9px;
          padding: 0;
        }
      }
    }
  }
  .include-sold,
  .include-virtual,
  .is-kwiat,
  .is-tiara,
  .is-flround,
  .is-fl,
  .is-rtv {
    > label {
      color: #58585a;
    }
  }

  .TabContentLoader {
    text-align: center;
    padding: 5em;
    font-size: 22px;
  }
  .dashboard {
    .carat-range-input {
      div > div > input {
        background: #fff;
        padding: 5px;
      }
      display: flex;
      .carat-range-input__carat-slider {
        display: flex;
        align-items: center;
        vertical-align: middle;
        width: 30%;
        h2 {
          font-size: 14px;
          font-family: "Proxima Nova Regular" !important;
          line-height: 30px;
          color: #58585a;
          margin: 0px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .carat-range-input__carat-input {
        width: 70%;
        height: 35px;
      }
    }
    .date-range {
      .date-range-input {
        display: flex;
        h2.css-1pwe92q {
          font-size: 14px;
          font-family: "Proxima Nova Regular" !important;
          line-height: 30px;
          color: #58585a;
          margin: 0px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 30%;
        }
        .css-ylmnjo {
          width: 70%;
          height: 35px;
          display: flex;
          flex-direction: row;
          div.css-1j6xlwo {
            width: 40%;
            border: 1px solid #ccc;
            background: white !important;

            .DayPickerInput {
              height: 34px !important;
              input {
                padding: 5px;
                height: 32px;
                margin: 1px 0px 1px 0px;
                // border: 1px solid #ccc;
                font-size: 12px;
                color: #636363;
              }

              .DayPickerInput-OverlayWrapper {
                // visibility: visible;
                display: block;
                width: 100%;
              }
            }
            svg {
              // font-size: 11px;
              height: 7px;
              flex-basis: 20%;
            }
          }
          .css-w7gaf3 {
            flex-basis: 20%;
          }
        }
      }
    }
    .collapse-tab {
      cursor: pointer;
    }
    .nav-tabs {
      border-bottom: 0;
    }
    .nav-tabs > li {
      border-top: 0.5px solid #d2d4d7;
      border-right: 0.5px solid #d2d4d7;
      border-left: 0.5px solid #d2d4d7;
      display: flex;
      font-size: 16px !important;
    }
    .active {
      border-right: 1px solid #d2d4d7 !important;
      background-color: #f5f7f8;
    }
    .notActive:hover {
      background-color: #efeeef !important;
    }
    .activeTab {
      display: flex;
      padding-top: 11px;
    }
    .nav-tabs > li > a {
      color: black;
      text-transform: uppercase;
    }
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:focus {
      color: #000;
      background-color: #f5f7f8;
      display: flex;
      border-right: 1px solid #f5f7f8;
      border-top: 1px solid #f5f7f8;
      border-left: 1px solid #f5f7f8;
      margin-right: 0px !important;
      font-family: "Proxima Nova Semibold" !important;
    }

    .nav-tabs > li > a {
      margin-right: 0px !important;
      border-bottom: none;
    }
    .tab-content,
    .body-con {
      padding: 0px 3px;
      h4 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .es__filters-list-column {
        padding-left: 15px;
        padding-right: 15px;
        width: 25%;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .color-input-dropdowns {
        display: flex;
        > h2 {
          font-size: 14px;
          color: #58585a;
          width: 30%;
          line-height: 20px;
          margin: 0;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        > span {
          font-size: 14px;
          color: #58585a;
          width: 45px;
          @media (min-width: 768px) and (max-width: 1024px) {
            width: 17px;
          }
          display: flex;
          margin: 2px 0 0;
          justify-content: center;
          flex: 1;
        }
        .start-range,
        .end-range {
          width: 23%;
          margin-top: 0px;
          @media (max-width: 767px) {
            width: 28%;
          }
          > div {
            > button {
              font-size: 12px;
              color: #58585a;
              min-height: 30px;
              padding: 5px;
              > div {
                width: 100%;
                text-overflow: unset;
              }
            }
            > ul {
              top: 30px;
              font-size: 11px;
              max-height: 220px !important;
              li {
                padding: 4px 6px !important;
              }
            }
          }
        }
      }
      .diamond-con-range-input {
        width: 100%;
        div > div > input {
          background: #fff;
        }
        display: inline-block;
        .diamond-slider-container {
          display: inline-block;
          width: 30%;
          h2 {
            font-size: 14px;
            font-family: "Proxima Nova Regular" !important;
            color: #58585a;
            margin: -5px 0px;
            position: relative;
            top: -4px;
            display: block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .diamond-input-container {
          width: 70%;
          height: 28px;
          display: inline-block;
          div {
            display: inline-block;
            width: 34.5%;
            @media (max-width: 767px) {
              width: 20%;
            }
            @media (min-width: 768px) and (max-width: 1024px) {
              width: 34.5%;
            }
            text-align: center;
          }
          div:first-child {
            width: 32.6%;
            @media (max-width: 767px) {
              width: 40%;
            }
            input {
              padding: 4px;
              height: 30px;
            }
          }
          div:last-child {
            width: 32.6%;
            @media (max-width: 767px) {
              width: 40%;
            }
            input {
              padding: 5px;
              height: 30px;
            }
          }
        }
      }
      .diamond-range {
        margin-bottom: 8px;
        .e1i1ftc40 {
          display: none;
        }
        .diamond-range-input {
          display: none;
          > div {
            width: 30%;
            > div {
              > input {
                background: #fff;
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                  height: 30px;
                }
              }
            }

            > h2 {
              font-size: 14px;
              color: #58585a;
              line-height: 26px;
              margin: 0px;
            }
          }

          span {
            margin: 2px 0 0;
            text-align: center;
            display: inline-block;
            width: 45px;
          }
          .diamond-input-container {
            width: 73%;
            height: 30px;
            @media screen and (min-width: 1330px) and (max-width: 1430px) {
              width: 73%;
            }
            @media screen and (min-width: 1100px) and (max-width: 1320px) {
              width: 73%;
            }
          }
        }
      }
      .carat-weight {
        margin-bottom: 8px;
        .carat-weight-input {
          display: flex;
          > div {
            width: 30%;
            > div {
              > input {
                background: #fff;
                padding: 5px;
              }
            }
            > h2 {
              font-size: 14px;
              color: #58585a;
              line-height: 20px;
              display: block;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .carat-weight-container {
            width: 70%;
            height: 30px;
          }
        }
      }
      .price-range {
        margin-bottom: 8px;
        .price-range__input {
          display: flex;
          > div {
            width: 30%;
            > div {
              > input {
                background: #fff;
                padding: 5px;
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                  height: 30px;
                }
              }
            }
            > h2 {
              font-size: 14px;
              color: #58585a;
              line-height: 20px;
              display: block;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .price-range__input--price-input {
            width: 70%;
            height: 30px;
          }
        }
      }
      .toggle {
        display: flex;
        > h2 {
          margin-top: 0;
          margin-left: 7px;
          font-size: 14px;
          color: #58585a;
          margin-bottom: 0px;
          line-height: 25px;
        }
      }

      .checkSelect {
        a {
          margin: 0;
          font-size: 16px;
          background: #ffff;
          border-color: #b3afaf;
          min-height: 90%;
          width: 14px;
          margin-top: 0;
          padding: 0;
        }
      }
      .diamond-keySearch {
        > h4 {
          margin: 0;
          line-height: 40px;
          margin-bottom: 13px;
        }
      }
      .keyword-search {
        > h4 {
          margin: 0;
          line-height: 40px;
          margin-bottom: 13px;
        }
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        .col-sm-4 {
          display: contents;
        }
      }

      .form-field-wrap {
        margin-bottom: 8px;
        .form-field {
          display: flex;
          > h2 {
            width: 30%;
            font-size: 14px;
            color: #58585a;
            line-height: 30px;
            margin: 0px;
            display: block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > div {
            width: 70%;
            > h2 {
              font-size: 14px;
            }
            > input {
              font-size: 13px;
              height: 30px;
              background-color: #fff;
              padding-right: 5px;
            }
            > button {
              font-size: 13px;
              color: #58585a;
              min-height: 30px;
              padding: 5px;
              > div {
                padding: 4px;
                width: 100%;
              }
            }
            > ul {
              top: 30px;
              max-height: 250px;
              input {
                font-size: 13px;
              }
              > li {
                font-size: 12px;
                line-height: 14px;
                padding: 4px 10px !important;
              }
            }
          }
        }
      }
      .select {
        padding-top: 5px;
      }
      .memo-out > .form-field > h2 {
        font-size: 14px;
        color: #58585a;
        line-height: 29px;
        margin: 0px;
      }
      .flex-lr-placer {
        display: flex;
        align-items: baseline;
        h4 {
          flex: 1;
          margin-top: 0;
        }
      }
    }
    .selectedFilters {
      margin: 8px 0;
      > a {
        background: #fff;
        font-size: 12px;
      }
    }
    .es__results {
      margin: -20px 0;
      padding: 0px;
      background: #fff;
      > div {
        > p {
          font-size: 14px;
        }
      }
      //gemstone
      .gemstone-diamond-product {
        padding: 15px;
        padding-bottom: 5px;
        background: #f5f7f8;
        margin-bottom: 15px;
        .productDetails {
          display: flex;
        }
        .detail-view {
          .row {
            display: flex;
          }
        }
        .product-title {
          padding: 0px;
          line-height: 12px;
          margin-bottom: 15px;
          a {
            color: #000;
          }
          p {
            margin: 0px;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }
          span {
            font-size: 11px;
          }
        }
        .unknown {
          color: red;
        }
        .product-img {
          max-width: 100%;
          img {
            max-width: 100%;
          }
        }
        .serialNumber {
          font-size: 12px;
          padding-top: 10px;
          > p {
            margin-bottom: 0px !important;
          }
          > span {
            font-size: 10px;
          }
        }
        .product-left-description,
        .product-center-description,
        .product-right-description {
          margin-top: 0px;
          display: flex;
        }
        .product-details {
          padding: 0px 10px;
          font-size: 12px;
          .product-description {
            padding-top: 12px;
            display: flex;
            justify-content: space-between;
            .left-col,
            .right-col {
              padding: 15px;
              padding-bottom: 0px;
              span {
                font-size: 10px;
              }
            }
            .left-col {
              width: 75%;
              .gia-number {
                padding-top: 10px;
              }
              p {
                margin-bottom: 20px;
                font-weight: bold;
                span {
                  font-weight: normal;
                }
              }
            }
            .right-col {
              padding-right: 0px;
              p {
                margin-bottom: 12px;
                font-weight: bold;
                span {
                  font-weight: normal;
                }
              }
            }
          }
        }
        .product-sold-feature {
          width: 40%;
          margin-left: 1%;
        }
        .product-sold-feature,
        .product-ring-feature,
        .product-stock-feature {
          width: 100%;
          height: 88%;
          margin: 20px 0px 0px 30px;
          border: 1px solid lightgray;
          border-top: 0px;
          .background {
            display: table;
            white-space: nowrap;
            > span {
              margin-top: 2px;
              display: inline-block;
              color: #b5acac;
            }
            &:before,
            &:after {
              border-top: 1px solid lightgray;
              content: "";
              display: table-cell;
              position: relative;
              top: 0.5em;
              width: 45%;
            }
            &:before {
              right: 0.5%;
              top: 11px;
            }
            &:after {
              left: 0.5%;
              top: 11px;
            }
          }
          p {
            margin: 0 auto;
            position: relative;
            top: -12px;
            font-size: 12px;
            font-weight: 600;

            span {
              font-weight: normal;
            }
          }
          .product-ring-detail,
          .product-sold-detail,
          .product-stock-detail {
            display: flex;
            justify-content: space-between;
            padding: 6px;
            padding-bottom: 0px;
            margin-top: 0px;
            p {
              margin-bottom: 10px;
            }
            span {
              font-size: 10px;
            }
          }
          .product-ring-detail {
            > div {
              padding: 0px 15px 0px 20px;
            }
            > p {
              margin-bottom: 13px;
            }
          }
          .product-stock-detail {
            line-height: 25px;
            > div {
              padding: 0px 26px 0px 26px;
            }
            p {
              margin-bottom: 11px;
            }
          }
          .product-sold-detail {
            > div {
              padding-left: 7px;
            }
            p {
              margin-bottom: 18px;
              padding-left: 10px;
            }
          }
        }
        .product-ring-feature {
          margin-left: 0px;
        }
        .product-sold-feature {
          width: 45%;
          margin-left: 1%;
        }
      }

      // For loading overlay
      .css-42igfv {
        margin: 10% 50%;
      }
      //jewelry
      .jewelry-product {
        padding: 15px;
        padding-bottom: 5px;
        background: #f5f7f8;
        margin-bottom: 15px;
        .row {
          display: flex;
        }
        .detail-view {
          .row {
            display: flex;
          }
        }
        .product-title {
          padding: 0px;
          padding-bottom: 14px;
          line-height: 12px;
          a {
            color: #000;
          }
          p {
            margin: 0px;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }
          span {
            font-size: 11px;
          }
        }
        .unknown {
          color: red;
        }
        .product-img {
          max-width: 100%;
          img {
            max-width: 100%;
          }
        }
        .serialNumber {
          font-size: 12px;
          padding-top: 10px;
          > p {
            margin-bottom: 0px !important;
          }
          > span {
            font-size: 10px;
          }
        }
        .product-left-description {
          margin-top: 0px !important;
        }
        .product-left-description,
        .product-center-description,
        .product-right-description {
          margin-top: 0px;
          display: flex;
        }
        .product-details {
          padding: 0px 10px;
          font-size: 12px;
          .product-description {
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
            .left-col,
            .right-col {
              padding: 15px;
              padding-bottom: 0px;

              span {
                font-size: 10px;
              }
            }
            .left-col {
              width: 50%;
              .gia-number {
                padding-top: 10px;
              }
              p {
                margin-bottom: 20px;
                font-weight: 600;
                span {
                  font-weight: normal;
                }
              }
            }
            .right-col {
              padding-right: 0px;
              padding-top: 25px;
              p {
                margin-bottom: 16px;
                font-weight: 600;
                span {
                  font-weight: normal;
                }
              }
            }
          }
        }
        .product-sold-feature {
          width: 40%;
          margin-left: 1%;
        }
        .product-sold-feature,
        .product-ring-feature,
        .product-stock-feature {
          width: 100%;
          height: 88%;
          margin: 20px 0px 0px 30px;
          border: 1px solid lightgray;
          border-top: 0px;
          .background {
            display: table;
            white-space: nowrap;
            > span {
              margin-top: 2px;
              display: inline-block;
              color: #b5acac;
            }
            &:before,
            &:after {
              border-top: 1px solid lightgray;
              content: "";
              display: table-cell;
              position: relative;
              top: 0.5em;
              width: 45%;
            }
            &:before {
              right: 0.5%;
              top: 11px;
            }
            &:after {
              left: 0.5%;
              top: 11px;
            }
          }
          p {
            margin: 0 auto;
            position: relative;
            top: -12px;
            font-size: 12px;
            font-weight: 600;
            span {
              font-weight: normal;
            }
          }
          .product-ring-detail,
          .product-sold-detail,
          .product-stock-detail {
            display: flex;
            justify-content: space-between;
            padding: 6px;
            padding-bottom: 0px;
            margin-top: 0px;
            p {
              margin-bottom: 10px;
            }
            span {
              font-size: 10px;
            }
          }
          .product-ring-detail {
            > div {
              padding: 0px 15px 0px 20px;
            }
            p {
              margin-bottom: 13px;
            }
          }
          .product-stock-detail {
            > div {
              padding: 0px 26px 0px 26px;
            }
            p {
              margin-bottom: 11px;
            }
          }
          .product-sold-detail {
            > div {
              padding-left: 7px;
            }
            p {
              margin-bottom: 18px;
              padding-left: 10px;
            }
          }
        }
        .product-ring-feature {
          margin-left: 0px;
        }
        .product-sold-feature {
          width: 45%;
          margin-left: 1%;
        }
      }
    }
    // @media screen and (min-width: 1370px) {
    //   background-color: red;
    //   .diamond-con-range-input {
    //     .diamond-input-container {
    //       width: 49.5% !important;
    //       height: 28px;
    //       div {
    //         flex: 1 !important;
    //       }
    //       div:first-child {
    //         flex: 2 !important;
    //         input {
    //            width: 100% !important;
    //         }
    //       }
    //       div:last-child {
    //         flex: 2 !important;
    //         input {
    //           margin-left: 2.5% !important;
    //            width: 100% !important;
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .main-content {
    background: white;
    // padding-top: 10px;
    padding: 10px 3px 0px 3px;
    display: flex;
    .header-con {
      width: 100%;
      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .es__basket-icon {
          // display: inline-block !important;
          margin-right: 4px;
          position: fixed;
          right: 11px;
          z-index: 999;
          cursor: pointer;
          .items__count {
            position: absolute;
            right: -7px;
            top: -8px;
            padding: 0px 7px;
            background-color: #444444;
            color: #fff;
            border-radius: 10px;
          }
          img {
            width: 35px;
          }
        }
      }
      button {
        background: none;
        border: none;
        box-shadow: none;
      }
      button:focus,
      button:hover,
      button:visited {
        background: none;
        border: none;
        box-shadow: none;
      }
      @media (max-width: 320px) {
        width: 100%;
        .nav-tabs {
          li:first-child {
            width: 44%;
            a {
              padding: 10px 0 !important;
            }
            .activeTab {
              width: 100%;
              padding-top: 7px;
              .tab-con {
                width: 50%;
                padding: 6px 3px;
              }
            }
          }
          li {
            font-size: 1em !important;
            width: 28%;
            a {
              padding: 10px 2px !important;
            }
          }
        }
      }
      @media (min-width: 321px) and (max-width: 767px) {
        width: 100%;
        .nav-tabs {
          li:first-child {
            width: 44%;
          }
          li {
            width: 28%;
            font-size: 1em !important;
            .activeTab {
              width: 100%;
              padding-top: 7px;
              .tab-con {
                width: 50%;
                font-size: 11px;
                padding: 7px 0 0 0;
                a {
                  padding: 10px 4px;
                }
              }
            }
            a {
              padding: 10px 7px;
            }
          }
        }
      }
    }
    .tab-con {
      text-transform: capitalize;
      cursor: pointer;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 2px;
      font-size: 14px;
      a {
        color: black !important;
      }
    }
  }

  #test {
    text-transform: uppercase;
    padding-left: 0px;
  }
  .selected {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 16px;
    text-decoration: underline;
  }
  .dropdown-menu {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    > ul {
      margin: 0px;
    }
    li {
      cursor: pointer;
      width: 100%;
    }
  }
  .navbar-nav > li > a {
    padding: 5px 0 0 10px;
    line-height: 25px;
    font-family: "Proxima Nova Regular";
    color: #424242;
  }
  div.dropdown-sort > div > ul {
    left: -90px !important;
  }
  #sort {
    font-size: 14px !important;
    margin-right: 10px;
    background: none !important;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  ul.nav-sort > li {
    padding: 5px 0 0 10px;
    line-height: 25px;
    font-family: "Proxima Nova Regular";
    color: #424242;
  }

  // style for mobile pop up modal
  .es__mobile-popup-modal {
    .modal-dialog {
      width: 100%;
      max-height: 90%;
    }
    .modal-header {
      background: #f5f7f8;
      border: none;
      position: relative;
      text-align: center;
      padding: 15px 0 0 0;
      > button {
        position: absolute;
        right: 16px;
      }
      .es__product-dark-title {
        color: $Dark_Titles_Color;
        font-family: "Proxima Nova Semibold";
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .modal-body {
      background: #f5f7f8;
      padding: 10px 15px 15px 15px;
      .es__product-modal-section1 {
        display: flex;
        .es__product-image-title {
          background: #fff;
          border: 1px solid #e3e3e3;
          background: #fff;
          flex-basis: 46%;
          .es__product-image-container {
            margin: 10px 4px 4px 4px;
            height: auto;
            .es__product-image {
              width: 100px;
              height: 90px;
            }
          }
        }
        .es__product-details {
          width: 100%;
          margin-left: 10px;
          & > div {
            padding-left: 8px;
            width: 24%;
          }
          .es__product-description {
            width: auto;
            display: grid;
          }
        }
      }
      .es__product-modal-section2 {
        margin-top: 5px;
        table {
          width: 95%;
          margin: 0px 9px;
          tr {
            height: 27px;
          }
          td {
            width: 50%;
            font-size: 0.75em;
            color: #3d4148;
            .es__mobile-product-details-table__label {
              font-size: 0.71em;
              color: #8a8a8a;
              margin-left: 6px;
            }
            hr {
              border-top: 1px solid #e3e3e3;
              margin-top: 10px;
              margin-bottom: 10px;
            }
            .pdf-icon {
              height: 12px;
              width: auto;
              margin-left: 5px;
              margin-top: -3px;
            }
          }
        }
      }
      .es__product-modal-section3 {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 15px;
        .es__product-pricing-section,
        .es__product-status-section,
        .es__product-hold-notes {
          width: 49%;
          .hold_notes_actions {
            position: relative;
            top: -10px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            /* padding: 0 10px; */
            justify-content: end;
            gap: 0;
            span {
              cursor: pointer;
              color: cornflowerblue;
              padding: 0px 8px;
              font-size: 17px;
              display: inline-block;
              box-sizing: border-box;
              i {
                font-size: 15px;
                cursor: pointer;
              }
            }
            // span:first-child {
            //   text-decoration: underline;
            // }
          }
          // .hold_notes_actions + .product-info_background {
          //   position: relative;
          //   top: -10px;
          // }
          table {
            width: 100%;
            tr {
              height: 18px;
            }
            .es__product-details__label-row {
              td {
                font-size: 0.6em;
                color: #a0a0a0;
                vertical-align: top;
                padding-left: 10px;
              }
            }
            .es__product-details__data-row {
              td {
                font-size: 0.75em;
                color: #3d4148;
                vertical-align: bottom;
                padding-left: 10px;
              }
            }
          }
        }
        .es__product-hold-notes {
          width: 100% !important;
        }
      }
      .es__product-modal-section4 {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 8px 0 !important;
        max-height: 38px;
        .share-icons {
          width: 20px;
        }
      }
      .es__bordered-box {
        padding: 10px 2px 2px 2px;
        border: 1px solid #e3e3e3;
        background: #fff;
      }
      .es__product-dark-title {
        color: $Dark_Titles_Color;
        font-family: "Proxima Nova Semibold";
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
      }
      .es__product-details-heading {
        padding: 5px;
        margin: 0;
        position: absolute;
        top: -10px;
        @media (max-width: 767px) {
          top: -12px;
        }
        left: 0;
        right: 0;
        text-align: center;
        span {
          background: $Background_for_Rows_Color;
          padding: 0 5px;
        }
      }
    }
  }

  .nextResultLoader {
    font-size: 20px;
    padding: 50px;
    text-align: center;
  }
  .visiblityHidden {
    visibility: hidden;
  }
  .include-sold-range,
  .include-virtual-range,
  .is-rtv-range,
  .is-kwiat-range,
  .is-tiara-range,
  .is-flround-range,
  .is-fl-range,
  .in-production-range {
    display: none;
  }
  .include-mount-range {
    display: none;
  }
  .show-code {
    cursor: pointer;
    font-size: 14px;
    color: #58585a;
    display: flex;
    text-align: center;
    line-height: 25px;
    column-gap: 5px;
    input[type="checkbox"] {
      margin: 7px 0px;
      height: 14px;
      width: 14px;
    }
    label {
      margin: 0;
    }
  }
  .code-component {
    margin-top: 38px;
  }
  .include-sold,
  .include-virtual,
  .include-mounted,
  .is-rtv,
  .is-fl,
  .in-production {
    margin-bottom: 0px !important;
  }

  .searchContainer {
    background: #f5f7f8;
    padding: 10px 5px;
    border-bottom: 1px solid #d2d4d7;
    border-right: 1px solid #d2d4d7;
    border-left: 1px solid #d2d4d7;
    border-top: 1px solid #d2d4d7;
  }
  .css-u5onnz li {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .css-ixn26d {
    margin-left: 30px;
  }
  .css-dnqvel {
    font-size: 11px !important;
  }
  .product-description_accordion-test {
    border: 1px solid #58585a;
    padding: 1px;
    color: #58585a;
    background-color: white;
  }

  .see-more-btn {
    margin-top: 7px;
    font-size: 12px;
  }
  .middle-label-margin {
    margin-top: 17px !important;
  }
  .unknown-margin {
    margin-top: 13px !important;
  }
  .unknown-status-margin {
    margin-top: 21px !important;
  }
  .unknown-status-margin-diamond {
    margin-top: 18px !important;
  }
  .unknown-status-margin-jewelry {
    margin-top: 15px !important;
  }
  .unknown-status-margin-gemstone {
    margin-top: 16px !important;
  }
  .unknown-price-margin {
    margin-top: 16px !important;
  }
  .unknown-jewelry-margin {
    margin-top: 20px !important;
  }
  .known-status-margin-diamond {
    margin-top: 5px;
  }
  .unknown-middle-margin {
    margin-top: 23px !important;
  }
  .known-status-margin {
    margin-top: 19px !important;
  }
  .checkFilters {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .toggle-filters-listCol {
    padding: 0;
  }
  .clickable {
    cursor: pointer;
  }
  .banner {
    opacity: 1;
    max-width: 1275px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    > img {
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
  .csv-btn {
    color: #58585a;
    border: none;
    background: transparent;
    margin-bottom: 10px;
    font-size: 14px;
    outline: none;
    text-decoration: underline;
  }
  .search_result_add_basket {
    // width: 24%;
    // height: 33px;
    background: #424242;
    border: none;
    color: #fff;
    outline: none;
    padding: 1px 6px;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 10px;
    position: relative;
    left: 92%;
    @media (max-width: 767px) {
      left: 70% !important;
      // margin: 9px 0;
    }
  }
  .search-btn__button {
    /* default for <button>, but useful for <a> */
    @media (max-width: 767px) {
      width: 100%;
      margin: 9px 0;
    }
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    /* create a small space when buttons wrap on 2 lines */
    margin: 2px 0;

    /* invisible border (will be colored on hover/focus) */
    border: solid 1px transparent;
    border-radius: 0px;

    /* size comes from text & padding (no width/height) */
    padding: 0.5em 3.5em;

    /* make sure colors have enough contrast! */
    color: #ffffff;
    background-color: #58585a;
  }
  .search-btn__button.focus,
  .search-btn__button:focus,
  .search-btn__button:hover {
    color: #fff !important;
  }
  .clear-filter-btn,
  .clear-all-btn {
    font-size: 14px;
    margin: 4px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: transparent;
    .clear--icon {
      margin-left: 6px;
      opacity: 0.7;
    }
  }
  .css-16nr2ca {
    top: 7px !important;
  }
  .css-2r2nqm {
    top: 6px !important;
  }
  .search-btn {
    text-align: center;
  }
  .mobile-view-all {
    @media (max-width: 767px) {
      display: block;
    }
  }
  .mobile-view-less {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .mobile-view-option {
    margin: auto;
    text-align: center;
    @media (min-width: 767px) {
      display: none;
    }
  }
  .es__bordered-box {
    padding: 10px 2px 2px 2px;
    border: 1px solid #e3e3e3;
    background: #fff;
  }
  .load__more--button {
    margin: 40px 0;
    button {
      border-radius: 5%;
      background: #58585a !important;
      color: #fff !important;
      text-shadow: none !important;
      border-color: #58585a !important;
    }
  }

  .toggle__filters {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      input[type="checkbox"] {
        margin-right: 7px;
      }
      li {
        display: flex;
        .toggle--label {
          font-size: 14px;
          color: #58585a;
          line-height: 20px;
          margin: 0px;
        }
      }
      li:first-child {
        display: none;
      }
    }
  }
  .hide__multilist {
    display: none;
  }
}
.basket-modal {
  z-index: 9999;
  .modal-dialog {
    width: 95%;
    margin: 15px auto;
    .modal-content {
      border: 2px solid #000;
      border-radius: 2px;
      // loading overlay styling
      .css-42igfv {
        margin: 41vh auto;
      }
      .modal-header {
        border-bottom: none;
        display: flex;
        max-height: 70px;
        justify-content: space-between;
        background-color: #f5f7f8;
        padding: 8px 10px;
        .es__basket-title {
          width: 6%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          justify-content: space-between;
          h3 {
            font-weight: 700;
            margin: 0;
            width: 100%;
            font-size: 20px;
          }
          span {
            font-size: 15px;
          }
        }
        .basket__action__group {
          width: 88%;
          background-color: #fff;
          padding: 10px 10px 5px;
          height: fit-content;
          z-index: 999;
          border: 1px solid transparent;
          button {
            font-size: 14px;
            margin: 2px 7px;
            border: 1.5px solid #58585a;
            padding: 5px 10px;
            border-radius: 0px;
            color: #ffffff;
            background-color: #58585a;
            outline: none;
            a {
              color: #ffffff;
            }
          }

          .basket__open {
            // padding: 5px 30px;
          }
          .basket__open,
          .basket__save-as {
            background-color: #58585a;
            color: #fff;
            border: 1.5px solid #58585a;
          }
          .collapsed {
            background-color: #58585a;
            color: #fff;
            border: 1.5px solid #58585a;
          }
          .basket__save {
            margin-left: 0;
            // padding: 5px 30px;
          }

          .basket__save,
          .basket__open,
          .basket__save-as,
          .basket__clone,
          .basket__email,
          .basket__export,
          .basket__print {
            img {
              margin-right: 4px;
            }
          }
          // accordion styles
          .basket__open__grouped,
          .basket__saveas__grouped {
            margin: 12px 0 52px;
            .my__basket__container,
            .all__basket__container {
              display: flex;
              margin-top: 5px;
              label {
                width: 20%;
                height: 30px;
                display: flex;
                align-items: center;
              }
              .open__input {
                display: flex;
                flex-direction: column;
                width: 70%;
                select {
                  height: 33px;
                  border: 1px solid $Border_Color;
                  background-color: #fff;
                  outline: none;
                }
                .Select {
                  height: 33px;
                  // border: 1px solid $Border_Color;
                  // background-color: #fff;
                  // outline: none;
                  margin: 4px;
                }
                button {
                  margin: 12px 0px;
                  width: 20%;
                  background: #000;
                }
              }
            }
          }
          .email_template {
            margin: 0 !important;
            background: inherit !important;
            color: #828282 !important;
            border: none !important;
            text-decoration: underline !important;
            // position: absolute !important;
            // right: 7% !important;
          }
        }
        .basket__export__group {
          width: 3%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .acumatica__export {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            font-weight: bolder;
            line-height: 15px;
            color: #000;
            border: none;
            background: transparent;
            outline: none;
          }
          .export {
            display: flex;
            align-items: center;
            font-size: 30px;
          }
          .acumatica__export,
          .export {
            margin: 0 10px;
            cursor: pointer;
          }
        }
        .basket__close--btn {
          width: 3%;
          background: #fff;
          border: none;
          border-radius: 2px;
          font-size: 20px;
          color: #58585a;
          outline: none;
        }
      }
    }
  }
  .modal-body {
    padding: 0 15px 15px;
    label {
      margin: 0;
    }
    .basket__form__section {
      .collapse__form {
        text-align: right;
        padding: 5px 0;
        button {
          font-size: 17px;
          padding: 4px 10px 0px;
          line-height: 18px;
          font-weight: 700;
        }
      }
      .basket__details__form {
        display: flex;
        padding: 5px 0 20px 0;
        .basket__input__wrapper {
          width: 53%;
          .basket__input {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            label {
              width: 20%;
              font-size: 15px;
              color: $Dark_Titles_Color;
              letter-spacing: 0.6px;
              span {
                color: red;
                font-size: 12px;
              }
            }
            input,
            select {
              width: 80%;
              height: 33px;
              background: #fff;
              border: 1.3px solid #bbbbbb;
              outline: none;
              padding: 0 10px;
              border-radius: 0;
            }
            .form-control-feedback {
              top: 0 !important;
              color: #0014ff;
              img {
                width: 22px;
              }
            }
            ::placeholder {
              color: #9a7e8f;
            }
            .form-control:focus {
              box-shadow: none;
            }
          }
          .customer__input {
            .input__dropdown__container {
              width: 80%;
              display: flex;
              flex-direction: column;
              input {
                width: 100%;
              }
              .downshift-dropdown {
                list-style: none;
                margin: 0;
                padding: 0;
                border: 0.5px solid #bbbbbb;
                position: absolute;
                top: 100%;
                left: 20%;
                max-height: 250px;
                overflow: auto;
                z-index: 999;
                width: 80%;
                li {
                  border-bottom: 0.5px solid #bbbbbb;
                  padding: 5px;
                }
              }
            }
          }
          .basket__input:last-child {
            margin-bottom: 0;
          }
        }
        .summary__private__wrapper {
          min-width: 21%;
          max-width: 35%;
          margin-left: 25px;
          .basket__summary {
            // margin-left: 25px;
            background: #f5f7f8;
            // min-width: 21%;
            height: fit-content;
            padding: 10px 15px;
            border-radius: 1px;
            h4 {
              text-transform: uppercase;
              font-weight: 600;
              font-size: 16px;
              color: $Dark_Titles_Color;
              padding-left: 10px;
              text-align: center;
            }
            .basket__summary__details {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              line-height: 26px;
              color: #828282;
              .summary__info {
                display: flex;
                width: 50%;
                .summary__label {
                  width: 65%;
                  font-weight: 600;
                }
                .summary__value {
                  width: 35%;
                  font-weight: 600;
                  color: #000;
                }
              }
              .summary__wo-price {
                .summary__value {
                  color: red;
                }
              }
            }
          }
          div.setAssetOrMemo {
            display: flex;
            flex-direction: row;
            margin: 10px 0px 0px 0px;
            text-align: center;
            column-gap: 4px;
            .asset-memo__toggle {
              // margin-top: 5px;
              display: flex;
              align-items: center;
              font-size: 13px;
              input[type="radio"] {
                margin: 2px 6px 0px 10px;
                width: 13px;
                height: 13px;
                @media (max-width: 767px) {
                  width: auto;
                  height: auto;
                }
              }
              label {
                margin: 0px;
                cursor: pointer;
              }
            }
          }
          .make__private__checkbox {
            // margin-top: 50px;
            display: flex;
            align-items: center;
            column-gap: 4px;
            input[type="checkbox"] {
              // margin: 0 10px 0 10px;
              width: 18px;
              height: 18px;
              margin: 0 0 0 0px !important;
            }
            label {
              cursor: pointer;
              white-space: nowrap;
            }
            button {
              width: 24%;
              height: 23px;
              background: #424242;
              border: none;
              color: #fff;
              outline: none;
              margin-left: 10px;
            }
            label:nth-child(5) {
              margin-left: 15px;
              text-decoration: underline;
              color: rgb(63, 160, 224);
              font-weight: normal;
              cursor: pointer;
              white-space: nowrap;
            }
          }
        }
      }
      .basket__quick__add {
        display: flex;
        padding: 20px 0;
        border-top: 1px solid #bbbbbb;
        border-bottom: 1px solid #bbbbbb;
        .quick__add__form {
          display: flex;
          width: 50%;
          justify-content: space-between;
          select {
            width: 25%;
            height: 33px;
            background: #fff;
            padding: 0 2px;
            outline: none;
            // margin-left: 5px;
          }
          select:first-child {
            margin-right: 5px !important;
          }
          .input__button__wrapper {
            width: 100%;
            display: flex;
            margin-left: 5px;
            .quickadd__input {
              margin: 0;
              width: 75%;
              input {
                width: 100%;
                height: 33px;
                border-radius: 0;
                border: 1px solid #767676;
              }
              .downshift-dropdown {
                list-style: none;
                margin: 0;
                padding: 0;
                border: 0.5px solid #bbbbbb;
                position: absolute;
                top: 100%;
                left: 0%;
                max-height: 250px;
                overflow: auto;
                z-index: 999;
                width: 100%;
                background-color: #fff;
                li {
                  border-bottom: 0.5px solid #bbbbbb;
                  padding: 5px;
                }
              }
            }
            button {
              width: 24%;
              height: 33px;
              background: #424242;
              border: none;
              color: #fff;
              outline: none;
              margin-right: 10px;
            }
          }
        }
        .price__checkbox__wrapper {
          display: flex;
          .price__toggle {
            margin-left: 50px;
            display: flex;
            align-items: center;
            input[type="checkbox"] {
              margin: 0 0 0 10px;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
    .basket__items__container {
      .toggle__item__desc {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        margin: 20px 0;
        .basic-multi-select {
          min-width: 15%;
          .basket__sort__control {
            border-color: #424242;
          }
        }
        .basket__sort__select {
          margin-right: 5px;
          outline: none;
          height: 30px;
        }
        button {
          outline: none;
          background: #424242;
          color: #fff;
          height: 38px;
          padding: 0 14px;
          border-radius: 5px;
        }

        .basket_customer_class {
          font-size: 27px;
          font-weight: 500;
          width: 45%;
          text-align: center;
          // padding: 10px 0px 0px 0px;
          // height: 80%;
        }
        .basket_check_price {
          // margin-right: -25%;
          width: 23%;
          display: flex;
          /* gap: 10px; */
          justify-content: space-between;
        }
        .select_all_items {
          // margin-left: -25%;
          // font-size: 18px;
          // padding: 10px;
          display: flex;
          column-gap: 6px;
          align-items: center;
          width: 9%;
          input[type="checkbox"] {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          label {
            font-weight: normal;
            font-size: 16px;
            cursor: pointer;
            // margin: 0px 0px 2px 0px;
          }
        }
      }
      .basket__items__grouped {
        .less__details__container {
          display: grid !important;
          grid-template-columns: repeat(auto-fill, 140px);
          grid-gap: 2rem 1rem;
          justify-content: space-between;
          width: auto !important;
          height: auto !important;
          margin-top: 15px;
        }
        .desktop__items__box {
          width: 100%;
          height: 100%;
          padding: 6px 4px;
          position: relative;
          border: 1px solid #bbb;
          display: flex;
          flex-direction: column;
          align-items: center;
          .remove_desktop_product {
            padding-right: 80%;
            cursor: pointer;
          }
          .item__serial__number {
            position: absolute;
            top: -11px;
            background: #fff;
            padding: 0 5px;
            font-weight: 700;
          }
          .item__thumbnail__box {
            display: flex;
            align-items: center;
            height: 100px;
            img {
              width: 100%;
              max-width: 100%;
              max-height: 100px;
            }
          }
          .items__details {
            width: 100%;
            font-size: 11px;
            .items__style__number {
              display: flex;
              div {
                white-space: nowrap;
              }
            }
            span {
              margin-left: 5px;
            }
          }
        }
        .less__details__container::after {
          content: "";
          flex: auto;
        }
      }
    }
    .basket__items__list {
      padding: 10px 8px;
      border: 1px solid $Border_Color;
      background-color: $Background_for_Rows_Color;
      margin: 20px 0;
      > input[type="checkbox"] {
        width: 18px;
        height: 18px;
      }
      .basket__item__header {
        display: flex;
        // justify-content: space-between;
        column-gap: 12px;
        align-items: center;
        .drag__class {
          width: 3%;
          text-align: center;
          cursor: all-scroll;
          img {
            width: 20px;
          }
        }
        input[type="checkbox"] {
          margin: 3px 0 0;
        }
        .item__serial-style__number {
          width: 27%;
          // padding-left: 20px;
          h5 {
            margin: 0;
            font-weight: 600;
            white-space: nowrap;
            .serial__style__interval {
              padding: 0 20px;
            }
          }
        }
        .item_warehouse_value {
          width: 27%;
          // padding-left: 20px;
          h5 {
            margin: 0;
            font-weight: 600;
            white-space: nowrap;
            text-align: center;
          }
        }
        .item__internal-note__input {
          width: 40%;
          input {
            width: 100%;
            background: #fff;
            height: 33px;
            padding: 3px 10px;
            border: 1px solid #000;
          }
        }
      }
      .basket__item__box-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        gap: 15px;
        .item__quantity__info {
          width: 3%;
          background-color: #fff;
          border: 1px solid $Border_Color;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // .quantity__title {
          //   font-size: 12px;
          //   font-weight: 600;
          // }
          // input {
          //   width: 77%;
          //   text-align: center;
          //   height: 22px;
          //   margin: 13px 0;
          // }
          // input[type="number"]::-webkit-inner-spin-button,
          // input[type="number"]::-webkit-outer-spin-button {
          //   -webkit-appearance: none;
          //   -moz-appearance: none;
          //   appearance: none;
          //   margin: 0;
          // }
          // .product_no {
          //   margin-bottom: 10px;
          // }
          // .remove__product {
          //   img {
          //     width: 25px;
          //     cursor: pointer;
          //   }
          // }
          .pos-relative {
            position: relative;
          }

          .product-index_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100%;
            // @media (max-width: 767px) {
            //   width: auto;
            // }
            // .mobile__basket__add-section {
            //   margin-top: 10px;
            //   .es__basket-icon {
            //     width: 100%;
            //   }
            // }
          }
          .serial-hovered {
            display: none !important;
            @media (max-width: 767px) {
              display: flex;
            }
          }
          .es__product__hovered {
            width: 100%;
            padding: 2px !important;
            margin: 0;
            display: none;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: space-around;
            cursor: pointer;
            img {
              width: 25px;
              cursor: pointer;
            }
          }
          .share-hovered {
            display: flex;
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
        .item__thumbnail__box {
          width: 11%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          background-color: #fff;
          border: 1px solid $Border_Color;
          text-align: center;
          .product-thumbnail {
            max-width: 100%;
            max-height: 100px;
            /*min-height: 75px;*/
          }
          .dia-image-icon {
            min-width: 100%;
            min-height: 100px;
            max-height: 90px;
            margin-top: 0;
            margin-bottom: 0;
          }
          .product-image-icon {
            max-width: 100%;
            max-height: 100px;
            /*            min-height: 100px;*/
            margin-top: 0;
          }
        }
        .item__description__box {
          width: 60%;
          background-color: #fff;
          border: 1px solid $Border_Color;
          padding: 10px 14px;
          font-size: 12px;
          position: relative;
          .product-dark_title_color {
            color: #3d4148;
            font-family: "Proxima Nova Semibold";
            font-size: 1em;
            font-weight: 700;
            text-transform: uppercase;
          }
          .product-stylenumber_heading {
            padding: 5px;
            margin: 0;
            position: absolute;
            top: -10px;
            display: flex;
            left: 0;
            right: 0;
            text-align: left;
            span {
              background: #f5f7f8;
              padding: 1px 2px;
              margin-left: 8px;
            }
          }
          table {
            border-collapse: separate;
            border-spacing: 0 4px;
            .item__description {
              font-style: italic;
            }
            .info__label {
              margin-left: 5px;
              color: $Small_Light_Grey_Text_Color;
            }
            td {
              width: 25%;
            }
            .lab__report--container {
              display: flex;
              width: 100%;
              img {
                width: 14px;
                height: auto;
                margin-left: 5px;
              }
            }
          }
        }
        .holding-details {
          background: white;
          background-color: #fff;
          border: 1px solid #e3e3e3;
          padding: 10px 14px;
          font-size: 12px;

          section {
            display: flex;
            justify-content: space-around;
            & > div {
              flex: 1 1 0;
            }
          }
          width: 27%;
          padding-right: 8px;
          @media (min-width: 768px) and (max-width: 1024px) {
            width: 20%;
          }

          .product-info_box {
            display: flex;
            flex-direction: column;
            gap: 0px;
            padding: 10px 2px 2px 2px;
            .product-light_title_color {
              color: $Small_Light_Grey_Text_Color;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
            }
            .product-info_box_heading {
              padding: 5px;
              margin: 0;
              position: absolute;
              top: -10px;
              left: 0;
              right: 0;
              text-align: center;
              span {
                background: $Background_for_Rows_Color;
                margin-left: 8px;
              }
            }
            .hold_notes_actions {
              position: relative;
              top: -10px;
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              /* padding: 0 10px; */
              justify-content: end;
              gap: 0;
              span {
                cursor: pointer;
                color: cornflowerblue;
                padding: 0px 8px;
                font-size: 17px;
                display: inline-block;
                box-sizing: border-box;
                i {
                  font-size: 15px;
                  cursor: pointer;
                }
              }
              // span:first-child {
              //   text-decoration: underline;
              // }
            }
            .hold_notes_actions + .product-info_background {
              position: relative;
              top: -10px;
            }

            .entered-by,
            .customer-name {
              word-break: break-word;
            }
            .holding-description {
              .holding-con {
                word-break: break-all;
              }
            }
            .is_hold_sold {
              color: red;
            }
            .product-info_background {
              padding: 0px 0px 0px 0px;
              line-height: 14px;
              display: flex;
              flex-direction: column;
              // align-items: center;
              gap: 5px;
              @media (min-width: 768px) and (max-width: 1024px) {
                .responsive-hold-notes {
                  display: table-caption;
                }
              }
              .product-details-label {
                margin: 0;
                .product-details-view-label {
                  font-size: 10px;
                  color: #8a8a8a;
                  opacity: 0.5 !important;
                }
              }
            }
          }
        }
        .item__price__box {
          width: 25%;
          background-color: #fff;
          border: 1px solid $Border_Color;
          padding: 10px 14px;
          font-size: 12px;
          display: flex;
          flex-wrap: wrap;
          column-gap: 9px;
          position: relative;
          .product-light_title_color {
            color: $Small_Light_Grey_Text_Color;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
          }
          .product-info_box_heading {
            padding: 5px;
            margin: 0;
            position: absolute;
            top: -10px;
            left: 0;
            right: 0;
            text-align: center;
            span {
              background: $Background_for_Rows_Color;
              margin-left: 8px;
            }
          }
          table {
            tr {
              display: flex;
              td:first-child {
                width: 80%;
              }
            }
            p {
              margin: 0;
            }
            .basket__item__edit {
              width: 100%;
            }
            .price__value {
              width: 100%;
              vertical-align: bottom;
              display: flex;
              align-items: center;
              padding-top: 6px;
              .price__label {
                margin-left: 5px;
                vertical-align: text-top;
                color: $Small_Light_Grey_Text_Color;
              }
            }
            .price__label {
              vertical-align: text-top;
              color: $Small_Light_Grey_Text_Color;
            }
            tr:nth-child(even) {
              td {
                padding-bottom: 5px;
              }
            }
            .edit__price__icon {
              font-size: 20px;
              text-align: right;
              width: 20%;
              img {
                width: 25px;
                cursor: pointer;
              }
            }
          }
          select {
            width: 30%;
            height: 33px;
            background: #fff;
            padding: 2px;
            outline: none;
            font-size: 11px;
          }
        }
      }
    }
  }
}

.drag__line {
  height: 30px !important;
  background-color: #fff !important;
  border-top: 2px dashed red !important;
}

.drag__line::after {
  content: "Drop Here..." !important;
  color: $Dark_Titles_Color !important;
}

.mobile__basket__modal {
  align-items: initial !important;
  .modal-dialog {
    width: 100%;
    .modal-content {
      // loading overlay styling
      .css-42igfv {
        margin: 41vh auto;
      }
      .modal-header {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        background-color: #f5f7f8;
        border-bottom: none;
        .es__basket-title {
          width: 80%;
          h3 {
            margin: 0;
            font-weight: 700;
            text-transform: uppercase;
          }
        }
        .basket__export__group {
          // display: flex;
          justify-content: space-between;
          // margin-bottom: 10px;
          background: #f5f7f8;
          padding: 10px;
          .acumatica__export {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            font-weight: bolder;
            line-height: 15px;
            color: #000;
            border: none;
            background: transparent;
            outline: none;
          }
        }
        .basket__close--btn {
          font-size: 30px;
          background: #fff;
          border: none;
          padding: 0 20px;
        }
      }
      .modal-header::before,
      .modal-header::after {
        content: none;
      }
      .modal-body {
        padding: 10px;
        .basket__details__form {
          border-bottom: 1.3px solid #bbbbbb;
          .basket__export__group {
            // display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            background: #f5f7f8;
            padding: 10px;
            .acumatica__export {
              display: flex;
              flex-direction: column;
              font-size: 13px;
              font-weight: bolder;
              line-height: 15px;
              color: #000;
              border: none;
              background: transparent;
              outline: none;
            }
          }
          label {
            margin-bottom: 0;
            color: $Dark_Titles_Color;
          }
          select,
          input {
            margin-bottom: 0;
            height: 33px;
          }
          .basket__input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            label {
              width: 30%;
            }
            input,
            select {
              width: 70%;
              border-radius: 0;
              border: 1.3px solid #bbbbbb;
              outline: none;
              background: transparent;
              padding: 0 10px;
            }
          }
          .basket__more__inputs {
            margin-bottom: 10px;
            background: transparent;
            border: none;
            padding: 0;
            outline: none;
          }
          .customer__input {
            .input__dropdown__container {
              width: 70%;
              display: flex;
              flex-direction: column;
              input {
                width: 100%;
              }
              .downshift-dropdown {
                list-style: none;
                margin: 0;
                padding: 0;
                border: 0.5px solid #bbbbbb;
                position: absolute;
                top: 100%;
                left: 30%;
                max-height: 250px;
                overflow: auto;
                z-index: 999;
                width: 70%;
                li {
                  border-bottom: 0.5px solid #bbbbbb;
                  padding: 5px;
                }
              }
            }
            .form-control-feedback {
              top: 0 !important;
              img {
                width: 24px;
              }
            }
          }
          .basket__input:last-child {
            margin-bottom: 15px;
          }
        }
        .basket__actions__grouped {
          padding: 15px 0;
          border-bottom: 1.3px solid #bbbbbb;
          .visible-action__button__wrapper {
            display: flex;
            .initial__visible__buttons {
              width: 70%;
              display: flex;
              justify-content: space-between;
              button {
                width: 48%;
                // height: 45px;
                font-size: 14px;
                border: 1.5px solid #58585a;
                padding: 5px 15px;
                border-radius: 0px;
                color: #ffffff;
                background-color: #58585a;
                outline: none;
              }
              img {
                margin-right: 4px;
              }
            }
            .basket__more__actions {
              width: 30%;
              background: transparent;
              border: none;
              outline: none;
              white-space: nowrap;
              font-size: 13px;
              // margin-right: 3px;
            }
          }
          .toggled__action__buttons {
            display: flex;
            width: 70%;
            justify-content: space-between;
            margin-top: 10px;
            button {
              width: 48%;
              // height: 50px;
              font-size: 14px;
              border: 1.5px solid #58585a;
              padding: 5px 0;
              border-radius: 0px;
              color: #ffffff;
              background-color: #58585a;
              outline: none;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            img {
              margin-right: 4px;
            }
          }
          .open__bakset__section {
            align-items: center;
            margin-top: 10px;
            .basket__open__grouped,
            .basket__saveas__grouped {
              .all__basket__container,
              .my__basket__container {
                margin-top: 10px;
              }
              .my__basket__container,
              .all__basket__container {
                display: flex;
                label {
                  margin-bottom: 0;
                  width: 30%;
                }
                .open__input {
                  width: 70%;
                  display: flex;
                  flex-direction: column;
                  select {
                    width: 100%;
                    height: 33px;
                    border-radius: 0;
                    background: transparent;
                    border: 1.3px solid #cccccc;
                    outline: none;
                  }
                  button {
                    width: 25%;
                    margin-top: 10px;
                    height: 33px;
                    background: #000;
                    color: #fff;
                    border: none;
                    border-radius: 0;
                    outline: none;
                  }
                }
              }
            }
          }
          .additional_basket_actions {
            // margin-top: 50px;
            display: flex;
            align-items: center;
            column-gap: 4px;
            padding-top: 12px;
            input[type="checkbox"] {
              // margin: 0 10px 0 10px;
              width: 18px;
              height: 18px;
              margin: 0 0 0 10px !important;
            }
            label {
              cursor: pointer;
              white-space: nowrap;
            }
            button {
              width: 24%;
              height: 23px;
              background: #424242;
              border: none;
              color: #fff;
              outline: none;
              margin-left: 10px;
              padding: 0px 6px 4px 6px;
            }
            label:nth-child(5) {
              margin-left: 15px;
              text-decoration: underline;
              color: rgb(63, 160, 224);
              font-weight: normal;
              cursor: pointer;
              white-space: nowrap;
            }
          }
          div.setAssetOrMemo {
            display: flex;
            flex-direction: row;
            margin: 11px 0px 0px 0px;
            text-align: center;
            column-gap: 4px;
            label {
              margin: 0;
            }
            .asset-memo__toggle {
              // margin-top: 5px;
              display: flex;
              align-items: center;
              font-size: 13px;
              input[type="radio"] {
                margin: 2px 6px 0px 10px;
                width: 13px;
                height: 13px;
                @media (max-width: 767px) {
                  width: auto;
                  height: auto;
                }
              }
              label {
                margin: 0px !important;
                cursor: pointer;
              }
            }
          }
        }
        .quickadd__price-visibility__grouped {
          padding: 15px 0;
          border-bottom: 1.3px solid #bbbbbb;
          .price__checkbox__wrapper {
            display: flex;
            align-items: center;
            label {
              margin-bottom: 0;
            }
            input[type="checkbox"] {
              margin: 0 0 0 6px;
              width: 19px;
              height: 20px;
            }
            .price__toggle {
              display: flex;
            }
            .wholesale__checkbox {
              margin-left: 10px;
            }
          }
        }
        .basket__items__summary {
          margin: 10px 0;
          display: flex;
          flex-wrap: wrap;
          .summary__value {
            font-weight: bold;
            margin-left: 4px;
          }
          .price__count {
            color: $Dark_Red_Color;
          }
          .summary__divider {
            margin: 0 15px;
          }
        }
        .quick__add__form {
          display: flex;
          // justify-content: space-between;
          column-gap: 4px;
          border-bottom: 1px solid #bbb;
          select {
            width: 27%;
            padding: 0 6px;
          }
          select,
          input,
          button {
            height: 40px;
            border-radius: 0;
            border: 1.3px solid #bbbbbb;
            background: transparent;
            outline: none;
            font-size: 13px;
          }
          .input__button__wrapper {
            width: 73%;
            display: flex;
            flex-direction: row;
            column-gap: 2px;
            // white-space: nowrap;
            input {
              width: 100%;
            }
            button {
              width: 25%;
              background: #58585a;
              color: #fff;
            }
            input[type="text"] {
              padding: 6px;
            }
            .downshift-dropdown {
              list-style: none;
              margin: 0;
              padding: 0;
              border: 0.5px solid #bbbbbb;
              position: absolute;
              top: 100%;
              left: 0%;
              max-height: 250px;
              overflow: auto;
              z-index: 999;
              width: 100%;
              background-color: #fff;
              li {
                border-bottom: 0.5px solid #bbbbbb;
                padding: 5px;
              }
            }
          }
        }
        .mobile__sort__select {
          display: flex;
          justify-content: flex-end;
          margin: 10px 0;
          .basket_customer_class {
            font-size: 27px;
            font-weight: 500;
            min-width: 65%;
          }
          .basic-multi-select {
            min-width: 35%;
            .basket__sort__control {
              border-color: #424242;
            }
          }
        }
        .mobile_basket_select_all {
          display: flex;
          align-items: center;
          // column-gap: 4px;
          justify-content: space-between;
          margin-bottom: 10px;
          padding-top: 4px;
          .select_all {
            display: flex;
            align-items: center;
            column-gap: 4px;
            input[type="checkbox"] {
              // margin-top: 0;
              margin-left: 4px;
              height: 16px;
              width: 16px;
            }
            label {
              margin: 0;
            }
          }
          button {
            margin-top: 1px;
            background: #58585a;
            color: #fff;
            height: 30px;
            border-radius: 0;
            border: 1.3px solid #bbbbbb;
            outline: none;
            font-size: 13px;
            padding: 2px 6px;
          }
        }
        .basket__items__list--mobile {
          margin-bottom: 10px;
          border: 1px solid #d2d4d7;
          background: #f5f7f8;
          .es__basket__product__warehouse__value {
            padding-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            // column-gap: 110px;

            input[type="checkbox"] {
              margin-left: 10px;
              // margin-top: 1px \9;
              height: 14px;
              width: 14px;
            }
            label {
              margin-bottom: 0;
              white-space: nowrap;
              width: 80%;
              text-align: center;
            }
          }
          .es__basket__product {
            width: 100%;
            font-size: 0.85em;
            display: flex;
            padding: 12px 0 8px;
            .product__actions__container {
              width: 10%;
              display: flex;
              flex-direction: column;
              align-items: center;
              // justify-content: space-between;
              justify-content: center;
              .drag__class {
                width: 60%;
                .drag__icon {
                  width: 100%;
                  height: auto;
                }
              }
              .item__quantity__info {
                width: 70%;
                input {
                  width: 100%;
                  text-align: center;
                  border: 1px solid #616161;
                  border-radius: 0;
                }
              }
              .remove__product {
                width: 60%;
                .remove__icon {
                  width: 100%;
                  height: auto;
                }
              }
              .product_no {
                margin-bottom: 8px;
                text-align: center;
              }
            }
            .product__image__box {
              width: 30%;
              border: 1px solid #e3e3e3;
              background: #fff;
              position: relative;
              .product-info_box_heading {
                padding: 5px;
                margin: 0;
                position: absolute;
                top: -10px;
                left: 0;
                right: 0;
                text-align: center;
                color: #3d4148;
                font-family: "Proxima Nova Semibold";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                span {
                  background: $Background_for_Rows_Color;
                  margin-left: 8px;
                }
              }
              .item__thumbnail__box {
                top: 14%;
                left: 0;
                right: 0;
                margin-top: 6px !important;
                margin-left: 7px !important;
                margin-right: 7px;
                margin-bottom: 6px;
                text-align: center;
                .product-thumbnail {
                  max-width: 100%;
                  max-height: 100px;
                  margin-top: 5px;
                }
              }
            }
            .product__info__container {
              width: 60%;
              padding: 0 10px;
              .product-info_box {
                padding: 10px 2px 2px 2px;
                border: 1px solid #e3e3e3;
                height: 100%;
                background: #fff;
                position: relative;
                .product-stylenumber_heading {
                  padding: 5px;
                  margin: 0;
                  position: absolute;
                  top: -10px;
                  display: flex;
                  left: 0;
                  right: 0;
                  text-align: left;
                  color: #3d4148;
                  font-family: "Proxima Nova Semibold";
                  font-size: 1em;
                  font-weight: bold;
                  text-transform: uppercase;
                  span {
                    background: $Background_for_Rows_Color;
                    padding: 1px 2px;
                  }
                }
                .product__details__container {
                  display: none;
                  width: 100%;
                  @media (max-width: 767px) {
                    display: block;
                    text-align: right;
                  }
                  table {
                    width: 100%;
                    tbody {
                      line-height: 21px;
                      td {
                        padding-left: 3px;
                        font-weight: 700;
                        width: 50%;
                        text-align: left;
                        .es__mobile-product-details-table__label {
                          font-size: 0.75em;
                          color: #a0a0a0;
                          margin-left: 6px;
                          font-weight: 100;
                        }
                        .pdf-icon {
                          height: 13px;
                          margin: -1px 0px 0px 7px;
                        }
                        button {
                          width: 22px;
                          padding: 0;
                          background-color: #f9f9f9;
                          margin-bottom: 3px;
                          border-radius: 0px;
                          float: right;
                          margin-right: 6px;
                          border: #d6e0e8 1px solid;
                          box-shadow: none;
                          background-image: none;
                          outline: none;
                        }
                      }
                      .gia-pdf-data {
                        > div {
                          display: inline-block;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .details__divider {
            border-top: 1px solid $Border_Color;
            padding-bottom: 8px;
          }
          .more__details__container {
            width: 90%;
            float: right;
            padding-right: 8px;
            .basic__details {
              padding: 5px;
              border: 1px solid $Border_Color;
              background: #fff;
              table {
                tr,
                td {
                  height: 15px;
                }
              }
            }
            .holding-details {
              background: white;
              background-color: #fff;
              border: 1px solid #e3e3e3;
              padding: 10px 0px 10px 14px;
              font-size: 12px;

              section {
                display: flex;
                justify-content: space-around;
                & > div {
                  flex: 1 1 0;
                }
              }
              width: 27%;
              padding-right: 8px;
              @media (max-width: 767px) {
                width: auto !important;
                margin-top: 8px;
              }
              @media (min-width: 768px) and (max-width: 1024px) {
                width: 20%;
              }

              .product-info_box {
                display: flex;
                flex-direction: column;
                gap: 0px;
                padding: 10px 2px 2px 2px;
                .product-light_title_color {
                  color: $Small_Light_Grey_Text_Color;
                  font-size: 12px;
                  font-weight: bold;
                  text-transform: uppercase;
                }
                .product-info_box_heading {
                  padding: 5px;
                  margin: 0;
                  position: absolute;
                  top: -10px;
                  left: 0;
                  right: 0;
                  text-align: center;
                  span {
                    background: $Background_for_Rows_Color;
                    margin-left: 8px;
                  }
                }
                .hold_notes_actions {
                  position: relative;
                  top: -10px;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-end;
                  /* padding: 0 10px; */
                  justify-content: end;
                  gap: 0;
                  span {
                    cursor: pointer;
                    color: cornflowerblue;
                    padding: 0px 8px;
                    font-size: 17px;
                    display: inline-block;
                    box-sizing: border-box;
                    i {
                      font-size: 15px;
                      cursor: pointer;
                    }
                  }
                  // span:first-child {
                  //   text-decoration: underline;
                  // }
                }
                .hold_notes_actions + .product-info_background {
                  position: relative;
                  top: -10px;
                }

                .entered-by,
                .customer-name {
                  word-break: break-word;
                }
                .holding-description {
                  .holding-con {
                    word-break: break-all;
                  }
                }
                .is_hold_sold {
                  color: red;
                }
                .product-info_background {
                  padding: 0px 0px 0px 0px;
                  line-height: 14px;
                  display: flex;
                  flex-direction: column;
                  // align-items: center;
                  gap: 5px;
                  @media (min-width: 768px) and (max-width: 1024px) {
                    .responsive-hold-notes {
                      display: table-caption;
                    }
                  }
                  .product-details-label {
                    margin: 0;
                    .product-details-view-label {
                      font-size: 10px;
                      color: #8a8a8a;
                      opacity: 0.5 !important;
                    }
                  }
                }
              }
            }
            .pricing__internal-note__grouped {
              margin: 18px 0 25px;
              display: flex;
              justify-content: space-between;
              .price__section {
                width: 50%;
                position: relative;
                padding: 5px 5px 5px 10px;
                border: 1px solid #dadada;
                background: #fff;
                margin-right: 4px;
                .box__heading {
                  position: absolute;
                  width: 100%;
                  top: -7px;
                  left: 0;
                  margin: 0;
                  text-align: center;
                  font-weight: 700;
                  span {
                    padding: 0 6px;
                    background: #f5f7f8;
                  }
                }
                p {
                  margin: 5px 0 0 0;
                }
                .basket__item__edit {
                  width: 100%;
                  margin: 3px 0 0 0;
                  border-radius: 0;
                }
                .edit__price__icon {
                  text-align: end;
                }
                select {
                  width: 100%;
                  font-size: 12px;
                  height: 25px;
                  background: #fff;
                  padding: 0px 3px;
                  outline: none;
                  margin: 5px 0 0;
                }
              }
              .internal-note__section {
                width: 50%;
                margin-left: 4px;
                textarea {
                  width: 100%;
                  height: 100%;
                  padding: 7px;
                  border-radius: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sortable__item__container {
  background-color: transparent;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

.show-more {
  padding-top: 3px;
  padding-right: 5px;
}

.more-details-modal {
  z-index: 10001;
  .modal-dialog {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.Top {
  vertical-align: top;
}

.modal-table tr {
  line-height: 30px;
  border-bottom: 2px #000;
}

.sortableHelper.desktop__items__box {
  z-index: 10000;
  width: 100%;
  height: 100%;
  padding: 5px;
  position: relative;
  border: 1px solid rgb(35, 86, 180);
  background-color: #f5f7f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  .item__serial__number {
    position: absolute;
    top: -11px;
    background: #fff;
    padding: 0 5px;
    font-weight: 700;
  }
  .item__thumbnail__box {
    display: flex;
    align-items: center;
    height: 100px;
    img {
      width: 100%;
      max-width: 100%;
      max-height: 100px;
    }
  }
  .items__details {
    width: 100%;
    span {
      margin-left: 5px;
    }
  }
}

.p-0 {
  padding: 0px;
}
.sortableHelperMobile.basket__items__list--mobile {
  z-index: 10000;
  margin-bottom: 10px;
  border: 1px solid #0966f1;
  background: #dadada;
  .es__basket__product {
    width: 100%;
    font-size: 0.85em;
    display: flex;
    padding: 12px 0 8px;
    .product__actions__container {
      width: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .drag__class {
        width: 60%;
        .drag__icon {
          width: 100%;
          height: auto;
        }
      }
      .item__quantity__info {
        width: 70%;
        input {
          width: 100%;
          text-align: center;
          border: 1px solid #616161;
          border-radius: 0;
        }
      }
      .remove__product {
        width: 60%;
        .remove__icon {
          width: 100%;
          height: auto;
        }
      }
    }
    .product__image__box {
      width: 30%;
      border: 1px solid #e3e3e3;
      background: #fff;
      position: relative;
      .product-info_box_heading {
        padding: 5px;
        margin: 0;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        text-align: center;
        color: #3d4148;
        font-family: "Proxima Nova Semibold";
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
        span {
          background: $Background_for_Rows_Color;
          margin-left: 8px;
        }
      }
      .item__thumbnail__box {
        top: 14%;
        left: 0;
        right: 0;
        margin-top: 6px !important;
        margin-left: 7px !important;
        margin-right: 7px;
        margin-bottom: 6px;
        text-align: center;
        .product-thumbnail {
          max-width: 100%;
          max-height: 100px;
          margin-top: 5px;
        }
      }
    }
    .product__info__container {
      width: 60%;
      padding: 0 10px;
      .product-info_box {
        padding: 10px 2px 2px 2px;
        border: 1px solid #e3e3e3;
        height: 100%;
        background: #fff;
        position: relative;
        .product-stylenumber_heading {
          padding: 5px;
          margin: 0;
          position: absolute;
          top: -10px;
          display: flex;
          left: 0;
          right: 0;
          text-align: left;
          color: #3d4148;
          font-family: "Proxima Nova Semibold";
          font-size: 1em;
          font-weight: bold;
          text-transform: uppercase;
          span {
            background: $Background_for_Rows_Color;
            padding: 1px 2px;
          }
        }
        .product__details__container {
          display: none;
          width: 100%;
          @media (max-width: 767px) {
            display: block;
            text-align: right;
          }
          table {
            width: 100%;
            tbody {
              line-height: 21px;
              td {
                padding-left: 3px;
                font-weight: 700;
                width: 50%;
                text-align: left;
                .es__mobile-product-details-table__label {
                  font-size: 0.75em;
                  color: #a0a0a0;
                  margin-left: 6px;
                  font-weight: 100;
                }
                .pdf-icon {
                  height: 13px;
                  margin: -1px 0px 0px 7px;
                }
                button {
                  width: 22px;
                  padding: 0;
                  background-color: #f9f9f9;
                  margin-bottom: 3px;
                  border-radius: 0px;
                  float: right;
                  margin-right: 6px;
                  border: #d6e0e8 1px solid;
                  box-shadow: none;
                  background-image: none;
                  outline: none;
                }
              }
              .gia-pdf-data {
                > div {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.show-more {
  color: #337ab7;
  cursor: pointer;
}

.p-left {
  padding-left: 8px;
}

.text-left {
  text-align: right;
}

.pdf_modal,
.email_modal,
.attachment_modal {
  z-index: 110001;

  .modal-dialog {
    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      // margin: 3px 0px;
    }

    // @media (max-width: 767px) {
    //   width: 100%;
    //   height: 100%;
    // }

    .css-42igfv {
      margin: 40% 45%;
    }
    div.tab-content > div.tab-pane > h5 {
      margin: 10px 10px 0px !important;
    }

    h4 {
      margin-left: 10px;
      font-weight: bold;
    }

    .basket__input__wrapper {
      padding: 20px;
      .basket__input {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        letter-spacing: 0.6px;
        @media (max-width: 767px) {
          flex-direction: column;
        }
        label {
          width: 15%;
          font-size: 15px;
          color: $Dark_Titles_Color;
          letter-spacing: 0.6px;
          @media (max-width: 767px) {
            font-size: 13px;
            width: 100%;
          }
          display: flex;
          p {
            color: red !important;
            margin-right: 2px;
          }
        }
        .react-multi-email {
          padding: 2px 2px !important;
          border: 1.5px solid #58585a !important;
          height: auto;
          width: 80%;
          @media (max-width: 767px) {
            font-size: 13px;
            width: 100%;
          }
          label {
            width: auto;
            border: 1px solid black;
            padding: 5px 5px 5px 5px;
            border-radius: 25px;
            margin: 0 0 0 1px;
            font-size: 14px;
            span {
              padding: 1px 5px 2px 5px;
              // margin-top: 5px !important;
            }
          }
          input {
            margin-left: 3px;
            font-size: 14px !important;
            height: 28px;
          }
        }
        input {
          width: 90%;
          height: 35px;
          margin-left: 6px;
          @media (max-width: 767px) {
            margin-left: 0px !important;
            width: 100%;
          }
        }

        .Select {
          height: 33px;
          width: 85%;
          @media (max-width: 767px) {
            width: 100%;
          }
          // border: 1px solid $Border_Color;
          // background-color: #fff;
          // outline: none;
          // margin: 4px;
          .css-yk16xz-control,
          .css-1pahdxg-control {
            .css-1hwfws3 {
              height: 33px;
              .css-1wa3eu0-placeholder {
                // top: 65%;
              }
            }
          }
        }
        input.focus-visible {
          outline: none !important;
        }
        .quill,
        .message_container {
          width: 90%;
          margin-left: 6px;
          border: 1.5px solid #58585a !important;
          @media (max-width: 767px) {
            margin-left: 0px;
            width: 100%;
          }

          .ql-toolbar.ql-snow {
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            @media (max-width: 767px) {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: row;
              // max-height: 200px !important;
              .ql-toolbar.ql-snow > span.ql-formats {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: flex-start;
              }
            }
          }

          .ql-container.ql-snow {
            border: none;
            height: 210px;
            overflow: auto;

            .ql-editor.ql-blank,
            .ql-editor.focus-visible,
            .ql-editor {
              height: 100%;
              p {
                font-size: 12px !important;
                // margin: 7px 0px 0px 0px;
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
              }

              // p {
              //   height: 120px;
              // }
            }
          }
        }
        .price_label_checkbox_wrapper {
          // @media (max-width: 767px) {
          //   padding-right: 17%;
          // }
          width: 100%;
          padding: 1px 15%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
          column-gap: 8px;
          // justify-content: center;
          h4 {
            font-size: 14px;
            margin: 3px 0 0 0;
          }

          .price_label_toggle {
            margin-top: 8px;
            display: flex;
            align-items: center;
            font-size: 13px !important;
            column-gap: 4px;
            width: 100%;
            input[type="radio"] {
              margin: 2px 0px 0px 0px;
              width: 12px;
              height: 12px;
              @media (max-width: 767px) {
                width: auto;
                height: auto;
              }
            }
            input[type="checkbox"] {
              margin: 3px 0px 0px;
              width: 12px;
              height: 12px;
              @media (max-width: 767px) {
                width: auto;
                height: auto;
              }
            }

            label {
              // margin-top: 3px;
              margin-bottom: 0px !important;
              cursor: pointer;
              width: 80%;
              font-size: 13px;
            }
          }
        }
      }

      .template {
        flex-direction: row !important;
        flex-wrap: wrap !important;
      }
    }
    .email_modal_header {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
      .action-buttons {
        margin-left: 68%;
      }
    }

    .email_modal_buttons {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-end !important;
    }
    .pdf_modal_header,
    .email_modal_header,
    .email_modal_buttons {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      .lookbook_options {
        width: 60%;
        padding-top: 10px;
        .item_No_checkbox_wrapper,
        .price_label_checkbox_wrapper {
          @media (max-width: 767px) {
            padding-right: 17%;
          }
          padding: 1px 10px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
          column-gap: 8px;
          // justify-content: center;
          h4 {
            font-size: 14px;
            margin: 3px 0 0 0;
          }
          .item_No_toggle,
          .price_label_toggle {
            margin-top: 3px;
            display: flex;
            align-items: center;
            font-size: 13px;
            column-gap: 4px;
            input[type="radio"] {
              margin: 2px 0px 0px 0px;
              width: 12px;
              height: 12px;
              @media (max-width: 767px) {
                width: auto;
                height: auto;
              }
            }
            input[type="checkbox"] {
              margin: 3px 0px 0px;
              width: 12px;
              height: 12px;
              @media (max-width: 767px) {
                width: auto;
                height: auto;
              }
            }

            label {
              // margin-top: 3px;
              margin-bottom: 0px !important;
              cursor: pointer;
            }
          }
        }
        .include_stones_checkbox,
        .include_location_checkbox {
          padding: 1px 10px;
          display: flex;
          align-items: center;
          column-gap: 4px;
          input[type="radio"]#stone_check {
            margin-top: 0px;
          }
          label {
            margin: 0;
            cursor: pointer;
          }
        }
      }
      .action-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button.email {
          @media (max-width: 767px) {
            margin-left: 12px;
          }
          font-size: 14px;
          margin: 2px 7px;
          border: 1.5px solid #58585a;
          padding: 5px 15px;
          border-radius: 0px;
          color: #ffffff;
          background-color: #58585a;
          outline: none;
        }
        button.clear,
        button.back {
          @media (max-width: 767px) {
            margin-left: 12px;
          }
          font-size: 14px;
          margin: 2px 7px;
          border: 1.5px solid #58585a;
          padding: 5px 15px;
          border-radius: 0px;
          color: #434343;
          background-color: #ffffff;
          outline: none;
        }
      }
    }

    hr.divider {
      background-color: #58585a;
      height: 2px;
    }

    .preview-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      h4 {
        width: 80%;
        @media (max-width: 767px) {
          width: 60%;
        }
      }
      button,
      .csv-link {
        font-size: 12px;
        margin: 2px 7px;
        border: 1.5px solid #58585a;
        padding: 5px 15px;
        border-radius: 0px;
        color: #ffffff;
        background-color: #58585a;
        outline: none;
      }
    }
    .csvData_container {
      width: 100%;
      overflow: auto;
      height: 500px;
      margin: 20px 0px 0px;
    }

    .layout_second_header_preview {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      // position: absolute;
      // top: 0%;
      // right: 0px;
      width: 100%;
      padding: 20px;
      font-family: Arial, Helvetica, sans-serif;
      margin-top: 20px;
    }
    .layout_second_footer_preview {
      border-top: solid rgb(184, 184, 184) 1px;
      text-align: center;
      font-weight: 500;
      // position: absolute;
      // bottom: 0.25in;
      // right: 0px;
      // left: 0px;
      padding: 6px 0px 4px 0px;
      margin: 20px 0.4in 0px 0.4in;
      // width: 100%;
      height: 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      font-size: 9px;
      font-family: Arial, Helvetica, sans-serif;
      // margin-bottom: 20px;

      span:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .copyright {
          font-size: 5px;
        }
      }
      span:nth-child(2) {
        width: 50%;
        // margin-right: 10%;
      }

      .kwiat {
        max-width: 70px;
      }
      .fred {
        max-width: 230px !important;
      }
      .kwfl {
        max-width: 130px !important;
      }
      .bridal {
        max-width: 70px !important;
      }
      // img {
      //   max-width: 130px !important;
      //   // max-height: 120px !important;
      // }
      // left: 10px;
    }

    .modal-content > button {
      @media (max-width: 767px) {
        margin-left: 80%;
      }
      cursor: pointer;
      position: sticky;
      right: 10px;
      bottom: 35px;
      margin-left: 120%;
      width: 50px;
      height: 50px;
      border: 1.5px solid rgb(208, 208, 208) !important;
      background-color: rgb(208, 208, 208);
      border-radius: 80%;
      // margin-top: 100px;
    }
  }
}

.tooltip {
  // position: relative;
  z-index: 10000 !important;
}

.choose-layout-modal {
  z-index: 10000;
  .modal-dialog {
    width: 50vw;
    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
    }
    .modal-content {
      .modal-header {
        font-size: 18px;
        font-weight: bold;
        @media (max-width: 767px) {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .modal-body {
        @media (max-width: 767px) {
          padding: 5px;
        }
        h4 {
          // text-align: center;
          font-weight: bold;
          font-size: 14px;
          margin: 6px 0px;
        }
        .price__checkbox__wrapper {
          @media (max-width: 767px) {
            padding-right: 17%;
          }
          padding: 5px 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          .price__toggle {
            margin-top: 4px;
            display: flex;
            align-items: center;
            font-size: 13px;
            input[type="checkbox"] {
              margin: 0px 6px 0px 10px;
              width: 13px;
              height: 13px;
              @media (max-width: 767px) {
                width: auto;
                height: auto;
              }
            }
            label {
              cursor: pointer;
              margin: 0px 0px 2px 0px;
            }
          }
        }

        .links__checkbox__wrapper,
        .gia__checkbox__wrapper {
          @media (max-width: 767px) {
            padding-right: 17%;
          }
          padding: 5px 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          // justify-content: center;
          .links__toggle,
          .gia__toggle {
            // margin-top: 5px;
            display: flex;
            align-items: center;
            font-size: 13px;
            input[type="radio"] {
              margin: 2px 6px 0px 10px;
              width: 13px;
              height: 13px;
              @media (max-width: 767px) {
                width: auto;
                height: auto;
              }
            }
            label {
              margin: 0px;
              cursor: pointer;
            }
          }
        }
        .links__checkbox__wrapper,
        .mts__ktd__checkbox__wrapper {
          @media (max-width: 767px) {
            padding-right: 17%;
          }
          padding: 5px 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          // justify-content: center;
          .links__toggle,
          .gia__toggle {
            // margin-top: 5px;
            display: flex;
            align-items: center;
            font-size: 13px;
            input[type="checkbox"] {
              margin: 2px 6px 0px 10px;
              width: 13px;
              height: 13px;
              @media (max-width: 767px) {
                width: auto;
                height: auto;
              }
            }
            label {
              margin: 0px;
              cursor: pointer;
            }
          }
        }

        .shapes__checkbox__wrapper {
          @media (max-width: 767px) {
            padding-right: 17%;
          }
          padding: 5px 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          .price__toggle {
            margin-top: 4px;
            display: flex;
            align-items: center;
            font-size: 13px;
            input[type="checkbox"] {
              margin: 0px 6px 0px 10px;
              width: 13px;
              height: 13px;
              @media (max-width: 767px) {
                width: auto;
                height: auto;
              }
            }
            label {
              cursor: pointer;
              margin: 0px 0px 2px 0px;
            }
          }
        }
        .layouts,
        .covers,
        .covers_updated {
          padding: 5px 10px;
          @media (max-width: 767px) {
            margin-bottom: 10px;
          }
          h4 {
            font-weight: bold;
            // text-align: center;
            // padding-left: 5%;
          }
          .Select {
            height: 33px;
            width: 70%;
            @media (max-width: 767px) {
              width: 65%;
            }
            // border: 1px solid $Border_Color;
            // background-color: #fff;
            // outline: none;
            // margin: 4px;
            .css-yk16xz-control,
            .css-1pahdxg-control,
            .css-26l3qy-menu {
              .css-1hwfws3 {
                height: 33px;
                .css-1wa3eu0-placeholder {
                  // top: 65%;
                }
              }
              div.cover_option {
                display: flex;
                gap: 10px;
                align-items: center;
                img {
                  width: 30px;
                }
              }
            }
          }
          .layouts-wrapper,
          .covers-wrapper {
            list-style: none;
            display: inline-flex;
            align-items: flex-start;
            // justify-content: space-around;
            flex-wrap: wrap;
            width: 100%;
            row-gap: 15px;
            column-gap: 15px;
            .layout_option,
            .cover_option {
              font-size: 14px;
              font-weight: bold;
              padding: 10px;
              cursor: pointer;
              border: 1.5px solid #58585a !important;
              background-color: rgb(255, 254, 254);
              color: #58585a;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              @media (max-width: 767px) {
                font-size: 14px;
                padding: 5px;
                margin: 2px 10px;
                text-align: center;
              }
              img {
                max-width: 80px;
                min-height: 80px;
                max-height: 80px;
                @media (max-width: 767px) {
                  max-width: 100px;
                  height: auto;
                }
              }
              span {
                margin-top: 10px;
                font-size: 11px;
              }
            }
            .Active {
              border: 1.5px solid #58585a !important;
              background-color: #58585a;
              color: rgb(255, 254, 254);
            }
          }
        }
        .covers_updated {
          display: flex !important;
          align-items: center !important;
          gap: 15px !important;
          @media (max-width: 767px) {
            gap: 10px;
          }
        }
        .action-buttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          button {
            font-size: 14px;
            margin: 2px 7px;
            border: 1.5px solid #58585a;
            padding: 5px 15px;
            border-radius: 0px;
            color: #ffffff;
            background-color: #58585a;
            outline: none;
          }
        }
      }
    }
  }
}
.basket_change_modal {
  z-index: 10000;
  .modal-dialog {
    width: 25vw;
    @media (max-width: 767px) {
      width: 100%;
    }
    .modal-content {
      .modal-header {
        font-size: 18px;
        font-weight: bold;
        @media (max-width: 767px) {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .modal-body {
        .basket_change_title {
          padding: 20px;
          font-size: 16px;
          text-align: center;
        }
        .action-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            font-size: 14px;
            margin: 2px 7px;
            border: 1.5px solid #58585a;
            padding: 5px 8px;
            border-radius: 0px;
            color: #ffffff;
            background-color: #58585a;
            outline: none;
          }
        }
      }
    }
  }
}
.show-export-modal,
.show-diamond-export-modal {
  z-index: 10000;
  .modal-dialog {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .modal-content {
    .modal-header {
      font-size: 18px;
      font-weight: bold;
      @media (max-width: 767px) {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .price__checkbox__wrapper {
    @media (max-width: 767px) {
      padding-right: 17%;
    }
    padding-right: 7%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    .price__toggle {
      margin-left: 50px;
      display: flex;
      align-items: center;
      input[type="checkbox"] {
        margin: 0 0 0px 10px;
        width: 18px;
        height: 18px;
      }
    }
  }
  .export-option {
    padding: 10px 100px 20px 100px;
    @media (max-width: 767px) {
      padding: 25px 5px;
    }
    ul {
      margin: auto;
      list-style: none;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      // justify-items: center;
      // align-content: center;
      width: 100%;
      padding: 0;
      li,
      button {
        margin: 2px 10px;
        font-size: 14px;
        font-weight: bold;
        padding: 10px;
        cursor: pointer;
        border: 1.5px solid #58585a !important;
        background-color: #58585a;
        color: rgb(255, 254, 254);
        @media (max-width: 767px) {
          font-size: 13px;
          font-weight: bold;
          // white-space: nowrap;
          text-align: center;
          margin: 2px 10px;
          padding: 5px;
        }
      }
    }
  }
}

.show-diamond-export-modal {
  z-index: 100001 !important;
}

.export-jewelry-modal {
  z-index: 10001;
  .modal-dialog {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .modal-content {
    .modal-header {
      font-size: 18px;
      font-weight: bold;
      @media (max-width: 767px) {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .modal-body {
      .fields_checkbox_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 70%;
        margin: auto;
        h4 {
          position: relative;
          font-weight: 600;
          @media (max-width: 767px) {
            font-size: 15px;
            font-weight: bold;
          }
          input[type="checkbox"] {
            margin: 0 0 0 20px;
            width: 18px;
            height: 18px;
            position: absolute;
            // bottom: 0;
            @media (max-width: 767px) {
              // position: relative;
              // margin-top: 10px !important;
              width: 15px !important;
              height: 15px !important;
            }
          }
        }
        .field_toggle,
        .advanced_field_toggle {
          // margin-left: 50px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 5px;
          label {
            width: 50%;
            @media (max-width: 767px) {
              width: 70%;
            }
          }
          input[type="checkbox"] {
            margin: 0 0 0 10px;
            width: 18px;
            height: 18px;
            @media (max-width: 767px) {
              // position: relative;
              // margin-top: 10px !important;
              width: 15px !important;
              height: 15px !important;
            }
          }
          span {
            position: absolute;
            left: 100%;
            font-size: 22px;
            cursor: pointer;
            color: #337ab7;
            width: 5%;
            text-align: center;
            @media (max-width: 767px) {
              font-size: 18px;
            }
          }
        }
        .advanced_field_toggle {
          // opacity: 0;
          display: none;
        }
        button {
          font-size: 14px;
          font-weight: 800;
          margin: 15px 7px 2px 7px;
          border: 1.5px solid #58585a;
          padding: 5px 15px;
          border-radius: 0px;
          color: #ffffff;
          background-color: #58585a;
          outline: none;
        }
      }
    }
  }
}

.show_bulk_add_modal {
  z-index: 10001;
  .modal-dialog {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .modal-content {
    .modal-header {
      font-size: 18px;
      font-weight: bold;
      @media (max-width: 767px) {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .modal-body {
      .import_bulk_product_wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        select {
          height: 33px;
        }
        textarea {
          height: 250px;
          padding: 10px;
        }
        button {
          width: 24%;
          height: 33px;
          background: #424242;
          border: none;
          color: #fff;
          outline: none;
        }
      }
    }
  }
}

.show_alert_modal {
  z-index: 1000002 !important;
  // width: 30vw;
  .modal-dialog {
    width: 50vw;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .modal-content {
    .modal-header {
      font-size: 18px;
      font-weight: bold;
      @media (max-width: 767px) {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .modal-body {
      ul {
        list-style: none;
        li {
          font-weight: bold;
          padding: 2px 0px;
        }
      }
      button {
        width: 24%;
        height: 33px;
        background: #424242;
        border: none;
        color: #fff;
        outline: none;
      }
      .price_check_wrapper {
        display: flex;
        flex-direction: column;
        .price_check_item_wrapper {
          margin: 4px;

          .price_check_item {
            width: 40%;
          }
          button {
            height: auto;
            width: 15%;
            margin-left: 6px;
            padding: 4px 6px;
          }
        }
        > button:last-child {
          margin-top: 20px !important;
        }
      }
      .price_check_table {
        width: 100%;
        th {
          padding: 2px 12px 2px;
          margin-bottom: 5px;
          width: 10px;
          border-right: 1px solid #8a8a8a;
        }
        > thead tr th:last-child {
          border: none !important;
        }
        td {
          padding: 2px 12px 2px;
          button {
            height: auto;
            width: auto;
            // margin-left: 6px;
            padding: 4px 6px;
          }
          border-right: 1px solid #8a8a8a;
        }
        > tbody tr td:last-child {
          border: none !important;
        }
        > tbody tr:last-child {
          td {
            text-align: center;
            padding-top: 20px;
            button {
              width: 10%;
            }
          }
        }
      }
    }
  }
}

.email-template-modal,
.template-edit-modal {
  z-index: 9999 !important;
  .modal-dialog {
    @media (max-width: 767px) {
      width: 100%;
    }
    .modal-content {
      .modal-header {
        font-weight: 600;
        font-size: 18px;
      }
      .modal-body {
        .template_list,
        .template_edit {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          h5 {
            font-weight: 400;
            font-size: 16px;
          }

          .template_container,
          .template_input {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 6px;
            width: 100%;
            border-bottom: 0.5px solid lightgrey;
            padding: 5px;
            gap: 15px;

            label {
              width: 75%;
              font-weight: 500;
            }
            button {
              border-radius: 0;
              border: 0.5px solid;
            }
            input,
            textarea,
            select {
              width: 80%;
            }

            textarea {
              height: 220px;
            }
          }
          .template_container:last-child,
          .template_input {
            border: none !important;
          }
          .template_input {
            column-gap: 10px;
            label {
              width: 15% !important;
            }
          }
          .template_input:last-child {
            justify-content: flex-end;
          }
          button {
            width: 10%;
            @media (max-width: 767px) {
              width: 16%;
            }
          }
        }
      }
    }
  }
}

.hold_notes_modal {
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: hidden;
  .modal-dialog {
    width: 80%;
    max-height: 70%;
    @media (max-width: 767px) {
      width: 95%;
      // min-width: 70%;
      max-height: 100%;
    }
    .modal-content {
      height: 100%;
      .modal-header {
        height: 15%;
      }
      .modal-body {
        height: 85%;
        .hold_notes_container {
          overflow-y: auto;
          height: 100%;
        }
      }
    }
  }
}
.hold_notes_create_modal {
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: hidden;
  .modal-dialog {
    max-width: 90%;
    min-width: 70%;
    max-height: 90%;
    @media (max-width: 767px) {
      max-width: 100%;
      min-width: 70%;
      max-height: 100%;
    }
    .modal-content {
      height: 100%;
      .modal-header {
        height: 15%;
      }
      .modal-body {
        height: 85%;
        .hold_notes_container {
          overflow-y: auto;
          height: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          /* align-items: center; */
          row-gap: 10px;
          .table_container {
            width: 100%;
            overflow-y: scroll;
            .table {
              font-size: 12px;
              thead {
                th {
                  white-space: nowrap;
                  font-weight: bolder;
                }
              }
            }
          }
          hr {
            width: 100%;
            margin: 10px 0px !important;
            border-top: 2px solid #c7c7c7 !important;
          }
          .hold_note_field_wrapper {
            @media (max-width: 767px) {
              width: 100% !important;
            }
            width: 45%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            label {
              width: 40%;
              @media (max-width: 767px) {
                width: 35% !important;
              }
              span {
                color: red !important;
              }
            }
            input[type="date"] {
              width: 25%;
              height: 33px;
              padding: 0px 6px;
            }

            .Select {
              width: 45%;
              @media (max-width: 767px) {
                width: 75% !important;
              }
            }
            .Select {
              font-size: 13px !important;
              height: 33px;
              font-size: 13px !important;
              // width: 85%;
              // @media (max-width: 767px) {
              //   width: 100%;
              // }
              // border: 1px solid $Border_Color;
              // background-color: #fff;
              // outline: none;
              // margin: 4px;
              .css-yk16xz-control,
              .css-1pahdxg-control {
                height: 33px;
                border: 1px solid;
                border-radius: 0;
                min-height: 33px;
                .css-1hwfws3,
                .css-1wy0on6 {
                  height: 33px;
                  .css-1uccc91-singleValue {
                    top: 45%;
                  }
                  .css-1wa3eu0-placeholder {
                    // top: 65%;
                  }
                }
                .css-1hwfws3 {
                  -webkit-box-align: center;
                  align-items: center;
                  display: flex;
                  flex: 1 1 0%;
                  flex-wrap: nowrap;
                  padding: 2px 8px;
                  position: relative;
                  overflow-x: hidden;
                  overflow-y: hidden;
                  box-sizing: border-box;
                  .css-1rhbuit-multiValue {
                    display: none;
                  }
                  .css-1rhbuit-multiValue:nth-last-child(2) {
                    background-color: rgb(230, 230, 230);
                    border-radius: 2px;
                    display: flex;
                    margin: 2px;
                    min-width: auto;
                    box-sizing: border-box;
                  }
                }
              }
              .css-26l3qy-menu {
                // height: 200px;
                .css-11unzgr {
                  max-height: 150px;
                  overflow-y: auto;
                  padding-bottom: 4px;
                  padding-top: 4px;
                  position: relative;
                  box-sizing: border-box;
                }
              }
            }
            textarea,
            input {
              border: 1px solid;
              @media (max-width: 767px) {
                width: 70% !important;
              }
            }
          }
          .basket__input.customer__input.form-group.has-feedback {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 0px;
          }
          .customer__input {
            .input__dropdown__container {
              width: 70%;
              display: flex;
              flex-direction: column;
              input {
                width: 100%;
                border-radius: 0px;
                @media (max-width: 767px) {
                  width: 100% !important;
                }
              }
              span.form-control-feedback {
                display: none;
              }
              .downshift-dropdown {
                list-style: none;
                margin: 0;
                padding: 0;
                border: 0.5px solid #bbbbbb;
                position: absolute;
                top: 100%;
                left: 27%;
                max-height: 250px;
                overflow: auto;
                z-index: 9999;
                width: 70%;
                li {
                  border-bottom: 0.5px solid #bbbbbb;
                  padding: 5px;
                }
              }
            }
          }
          .hold_note_field_wrapper:nth-child(odd) {
            @media (max-width: 767px) {
              width: 100% !important;
            }
            width: 35% !important;

            label {
              width: 28% !important;
            }
            input[type="date"] {
              width: 35%;
            }
            .Select {
              width: 70% !important;
            }
            .basket__input.customer__input.form-group.has-feedback {
              display: flex;
              align-items: center;
              width: 100%;
              margin-bottom: 0px;
            }
            .customer__input {
              .input__dropdown__container {
                width: 70%;
                display: flex;
                flex-direction: column;
                input {
                  width: 100%;
                  border-radius: 0px;
                  @media (max-width: 767px) {
                    width: 100% !important;
                  }
                }
                span.form-control-feedback {
                  display: none;
                }
                .downshift-dropdown {
                  list-style: none;
                  margin: 0;
                  padding: 0;
                  border: 0.5px solid #bbbbbb;
                  position: absolute;
                  top: 100%;
                  left: 27%;
                  max-height: 250px;
                  overflow: auto;
                  z-index: 9999;
                  width: 70%;
                  li {
                    border-bottom: 0.5px solid #bbbbbb;
                    padding: 5px;
                  }
                }
              }
            }
          }
          .hold_note_field_wrapper:nth-child(even) {
            @media (max-width: 767px) {
              width: 100% !important;
            }
            width: 65%;
            flex-wrap: wrap;

            label {
              width: 25% !important;
              @media (max-width: 767px) {
                width: 28% !important;
              }
            }
            .Select {
              width: 38% !important;
              @media (max-width: 767px) {
                width: 70% !important;
              }
            }
            .options_selected {
              width: 100%;
              height: 33px;
              // padding: 6px 0px 0px 0px;
              display: flex;
              flex-direction: row;
              gap: 8px;
              overflow-x: auto;
              overflow-y: hidden;
              align-items: center;
              span {
                background-color: #e6e6e6;
                border-radius: 2px;
                padding: 3px 10px 3px 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
                font-size: 12px;
              }
              i {
                font-style: initial;
                margin: 0px 0px 0px 6px;
                cursor: pointer;
              }
            }
          }
          // .hold_note_field_wrapper:nth-last-child(4),
          .hold_note_field_wrapper:nth-last-child(3) {
            width: 100%;
            label {
              width: 10% !important;
              @media (max-width: 767px) {
                width: 28% !important;
              }
            }

            textarea {
              width: 67%;
              height: 100px;
            }
            .Select {
              width: 21%;
            }
          }
          .hold_note_field_wrapper:nth-last-child(2) {
            width: 45% !important;
            gap: 5px;
            padding-left: 10%;
            @media (max-width: 767px) {
              // width: 5% !important ;
              padding-left: 28% !important ;
            }
            label {
              margin: 0 0 2px;
            }
            input {
              // margin: 0;
              @media (max-width: 767px) {
                width: 5% !important ;
                // padding-left: 26% !important ;
              }
            }
          }

          .hold_notes_action_buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: 10px;
          }
        }
      }
    }
  }
}
