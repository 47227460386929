/*!
 * https://getbootstrap.com/docs/4.1/utilities/flex/
 * Bootstrap v4.1.3 flex utilities (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(~react-image-gallery/styles/css/image-gallery.css);
.spinner {
  position: absolute;
  width: 18px;
  height: 18px;
  border: 2px solid rgb(83, 83, 83);
  border-top-color: rgb(245, 231, 231);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  right: 25px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

body::-webkit-scrollbar-track {
  width: 0.5em; }

body {
  font-family: "Proxima Nova Regular"; }

@media (max-width: 767px) {
  .modal {
    display: flex !important;
    align-items: center; } }

._loading_overlay_overlay {
  z-index: 111111 !important;
  height: 100vh !important; }
  ._loading_overlay_overlay .css-42igfv {
    position: fixed !important;
    left: 50%;
    top: 45%; }
    @media (max-width: 767px) {
      ._loading_overlay_overlay .css-42igfv {
        left: 40%;
        top: 50%; } }

.es__d-flex {
  display: flex; }

.es__flex-xs-column {
  flex-direction: row; }
  @media (max-width: 767px) {
    .es__flex-xs-column {
      flex-direction: column; } }

.Toastify__toast-container {
  z-index: 10021 !important; }

.css-1mnns6r {
  position: relative; }

.Toastify__toast {
  min-height: 54px !important; }

.Toastify__toast--default {
  background: #cecece !important;
  color: #3c3c3c !important; }

@media (max-width: 767px) {
  .Toastify__toast-container {
    width: fit-content !important;
    padding: 0 !important;
    right: 10px !important;
    left: auto !important;
    top: 10px; }
    .Toastify__toast-container .Toastify__toast-body {
      padding: 0 20px !important; } }

.original .dia-image-icon {
  min-width: 60px;
  min-height: 90px;
  max-height: 90px;
  margin-top: 10px;
  margin-bottom: 20px; }

.original .product-image-icon {
  max-width: 100%;
  max-height: 100px;
  margin-top: 12px; }

.original .text-capitalize {
  text-transform: capitalize; }

.original .pos-relative {
  position: relative; }

.original .iframeModal {
  z-index: 10000;
  cursor: pointer; }
  .original .iframeModal .modal-dialog {
    width: 95vw; }
    @media (max-width: 767px) {
      .original .iframeModal .modal-dialog {
        width: 100%;
        max-height: 90%; } }
  .original .iframeModal .modal-body {
    padding: 0; }
  .original .iframeModal iframe {
    width: 100%;
    min-height: 85vh; }
    @media (max-width: 767px) {
      .original .iframeModal iframe {
        min-height: 75vh; } }

.original .iframe-link {
  cursor: pointer; }

.original .image-modal {
  z-index: 10000;
  cursor: pointer; }
  @media (max-width: 767px) {
    .original .image-modal .modal-dialog {
      min-width: 95%;
      max-height: 90%; } }
  .original .image-modal .modal-dialog .modal-content {
    border-radius: 0;
    border: 1px solid grey; }
    .original .image-modal .modal-dialog .modal-content .modal-body {
      min-height: 40vh; }
      .original .image-modal .modal-dialog .modal-content .modal-body .image-gallery-image {
        max-height: 68vh;
        min-height: 68vh; }
      .original .image-modal .modal-dialog .modal-content .modal-body .image-gallery-thumbnails-container {
        float: left; }
        .original .image-modal .modal-dialog .modal-content .modal-body .image-gallery-thumbnails-container .image-gallery-thumbnail {
          width: 80px; }
  .original .image-modal .modal-header {
    border-bottom: 0;
    padding: 15px 15px 0px 15px; }
    .original .image-modal .modal-header .close:focus {
      outline: none; }
  .original .image-modal .image-modal-con {
    text-align: center; }
    .original .image-modal .image-modal-con img {
      max-width: 60%; }

.original .full-popup-modal {
  background: none !important;
  padding: 0 !important;
  font-family: "Proxima Nova Regular"; }
  .original .full-popup-modal .customer-name {
    color: red !important; }
  .original .full-popup-modal .product-ring-detail {
    min-width: 430px;
    margin-right: 8px; }
    .original .full-popup-modal .product-ring-detail .product-info_box {
      padding-left: 5px !important; }
  .original .full-popup-modal .product-stock_details {
    width: 28%;
    min-width: 280px;
    margin-right: 8px; }
    .original .full-popup-modal .product-stock_details .product-info_box {
      padding-left: 5px !important; }
    .original .full-popup-modal .product-stock_details .product-info_box {
      min-height: 130px; }
    .original .full-popup-modal .product-stock_details .product-info_background {
      padding: 0px 10px 0px 10px;
      line-height: 18px; }
  .original .full-popup-modal .edit-btn {
    float: right;
    margin-right: 16px; }
  .original .full-popup-modal .modal-dialog {
    max-width: 50%; }
    .original .full-popup-modal .modal-dialog .product-d-flex-container {
      margin-top: 10px; }
      .original .full-popup-modal .modal-dialog .product-d-flex-container div {
        padding-left: 0; }
    .original .full-popup-modal .modal-dialog .modal-content {
      border-radius: 0;
      border: 2px solid #8a8a8a;
      background-color: ghostwhite; }
      .original .full-popup-modal .modal-dialog .modal-content img {
        width: 100%;
        height: auto; }
      .original .full-popup-modal .modal-dialog .modal-content .product-img {
        border: 1px solid #e3e3e3;
        margin-bottom: 15px;
        background-color: #e3e3e3; }
      .original .full-popup-modal .modal-dialog .modal-content h3 {
        text-align: center;
        color: red; }
      .original .full-popup-modal .modal-dialog .modal-content .cust-name {
        color: red; }
      .original .full-popup-modal .modal-dialog .modal-content .modal-header-con {
        text-align: center;
        border-bottom: 0; }
        .original .full-popup-modal .modal-dialog .modal-content .modal-header-con .modal-title {
          color: #3d4148;
          margin-left: 66px; }

.original .stock-popup-modal {
  font-family: "Proxima Nova Regular";
  background: none !important;
  padding: 0 !important;
  z-index: 9999; }
  .original .stock-popup-modal .product-details_line-break {
    font-size: 16px; }
  .original .stock-popup-modal .product-details-view-label {
    font-size: 14px !important;
    line-height: 24px !important;
    color: #58585a !important; }
  .original .stock-popup-modal .product-details-label {
    color: #58585a !important; }
  .original .stock-popup-modal .highlight-text {
    color: green !important; }
  .original .stock-popup-modal .border-bottom {
    border-bottom: 1px dotted black;
    margin-bottom: 13px; }
  .original .stock-popup-modal .product-details_lcol {
    margin-right: 50px; }
    @media (max-width: 767px) {
      .original .stock-popup-modal .product-details_lcol {
        margin-right: 0px; } }
  @media (max-width: 767px) {
    .original .stock-popup-modal .product-details_mcol {
      margin-left: 8px; } }
  .original .stock-popup-modal .product-info_background {
    padding-top: 15px;
    padding-left: 15px; }
    .original .stock-popup-modal .product-info_background section.brought_in_for {
      justify-content: flex-end !important; }
      .original .stock-popup-modal .product-info_background section.brought_in_for > div {
        width: 44%;
        flex: inherit; }
  .original .stock-popup-modal .see-more {
    margin-top: 5px;
    text-align: center; }
    .original .stock-popup-modal .see-more .see-more-btn {
      background: #8a8a8a;
      background-image: none;
      text-shadow: none;
      color: #fff;
      width: 50%;
      border-radius: 0; }
  .original .stock-popup-modal .modal-dialog {
    max-width: 50%; }
    @media (max-width: 767px) {
      .original .stock-popup-modal .modal-dialog {
        min-width: 95%;
        max-height: 90%; } }
    .original .stock-popup-modal .modal-dialog .modal-content {
      border-radius: 0;
      border: 2px solid #8a8a8a; }
      .original .stock-popup-modal .modal-dialog .modal-content .modal-header-con {
        text-align: center;
        border-bottom: 0; }
        .original .stock-popup-modal .modal-dialog .modal-content .modal-header-con .modal-title {
          color: #3d4148; }

.original .product {
  width: 100%;
  display: flex;
  margin-bottom: 4px;
  border: 1px solid #d2d4d7;
  background: #f5f7f8;
  padding: 12px 0 8px;
  font-size: 0.75em; }
  @media (max-width: 767px) {
    .original .product {
      font-size: 0.65em; } }
  .original .product .index-share {
    min-height: 100%;
    width: 2%;
    margin: 0 5px; }
    @media (max-width: 767px) {
      .original .product .index-share {
        width: 4%; } }
    .original .product .index-share .product-index_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 100%; }
      @media (max-width: 767px) {
        .original .product .index-share .product-index_container {
          width: auto; } }
      .original .product .index-share .product-index_container .mobile__basket__add-section {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px; }
        .original .product .index-share .product-index_container .mobile__basket__add-section .es__basket-icon,
        .original .product .index-share .product-index_container .mobile__basket__add-section .mail__share-icon,
        .original .product .index-share .product-index_container .mobile__basket__add-section .print__share-icon {
          width: 100%; }
    .original .product .index-share .serial-hovered {
      display: none !important; }
      @media (max-width: 767px) {
        .original .product .index-share .serial-hovered {
          display: flex; } }
    .original .product .index-share .es__product__hovered {
      width: 100%;
      padding: 2px !important;
      margin: 0;
      display: none;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: space-around;
      cursor: pointer; }
      .original .product .index-share .es__product__hovered .es__basket-icon {
        width: 18px; }
      .original .product .index-share .es__product__hovered .share-icons {
        width: 15px; }
    .original .product .index-share .share-hovered {
      display: flex; }
      @media (max-width: 767px) {
        .original .product .index-share .share-hovered {
          display: none; } }
  .original .product .product-details_lcol {
    text-align: left; }
  .original .product .product-details-label {
    margin: 3px 0 8px 0;
    color: #3d4148; }
    .original .product .product-details-label a {
      color: #337ab7; }
    .original .product .product-details-label a:hover {
      color: #23527c; }
    .original .product .product-details-label span {
      margin-top: 2px;
      line-height: 10px; }
  .original .product .product-customer-details-label {
    margin: 3px 0 0 0;
    color: #aa0707;
    line-height: 7px; }
  .original .product .product-info_box_heading {
    padding: 5px;
    margin: 0;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    text-align: center; }
    .original .product .product-info_box_heading span {
      background: #f5f7f8;
      margin-left: 8px; }
  .original .product .stock-modal-info_box_heading span {
    background: #fff !important;
    font-size: 16px; }
  .original .product .es__isfl {
    position: relative;
    height: 17px;
    margin-left: 0 !important;
    padding: 0 !important;
    bottom: 3px;
    content: url("https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/FL-DarkGrey.svg"); }
  .original .product .es__istiara {
    position: relative;
    width: 20px;
    margin: 0px 2px !important;
    padding: 0 !important;
    bottom: 2px;
    content: url(https://svgshare.com/i/G9L.svg); }
  .original .product .product-stylenumber_heading {
    padding: 5px;
    margin: 0;
    position: absolute;
    top: -10px;
    display: flex;
    left: 0;
    right: 0;
    text-align: left; }
    .original .product .product-stylenumber_heading span {
      background: #f5f7f8;
      padding: 1px 2px;
      margin-left: 8px; }
    .original .product .product-stylenumber_heading img {
      background-color: white;
      cursor: pointer; }
  .original .product .loose-results {
    line-height: 11px !important; }
  .original .product .additional-height {
    line-height: 15px !important; }
  .original .product .product-info_background {
    line-height: 11px;
    padding: 4px 10px 0px 10px; }
    .original .product .product-info_background .product-description {
      font-size: 12px;
      font-style: italic;
      margin-top: 2px;
      margin-bottom: 5px;
      display: flex;
      column-gap: 8px; }
      .original .product .product-info_background .product-description span:nth-child(2) {
        color: #337ab7;
        font-size: 12px;
        cursor: pointer;
        font-style: normal; }
  .original .product .product-image_background {
    padding-top: 1px; }
  .original .product .product-d-flex-container {
    display: flex !important;
    width: 88%; }
    @media (max-width: 767px) {
      .original .product .product-d-flex-container {
        width: 65%; } }
    .original .product .product-d-flex-container > div {
      padding-left: 8px; }
    @media (max-width: 767px) {
      .original .product .product-d-flex-container .mobile-view {
        display: none; } }
    .original .product .product-d-flex-container .holding-details {
      width: 25%;
      padding-right: 8px; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .original .product .product-d-flex-container .holding-details {
          width: 20%; } }
      .original .product .product-d-flex-container .holding-details .product-info_box {
        display: flex;
        flex-direction: column;
        gap: 0px;
        padding: 10px 2px 2px 2px; }
        .original .product .product-d-flex-container .holding-details .product-info_box .hold_notes_actions {
          position: relative;
          top: -10px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          /* padding: 0 10px; */
          justify-content: end;
          gap: 0; }
          .original .product .product-d-flex-container .holding-details .product-info_box .hold_notes_actions span {
            cursor: pointer;
            color: cornflowerblue;
            padding: 0px 8px;
            font-size: 17px;
            display: inline-block;
            box-sizing: border-box; }
            .original .product .product-d-flex-container .holding-details .product-info_box .hold_notes_actions span i {
              font-size: 15px;
              cursor: pointer; }
        .original .product .product-d-flex-container .holding-details .product-info_box .hold_notes_actions + .product-info_background {
          position: relative;
          top: -10px; }
      .original .product .product-d-flex-container .holding-details .entered-by,
      .original .product .product-d-flex-container .holding-details .customer-name {
        word-break: break-word; }
      .original .product .product-d-flex-container .holding-details .holding-description .holding-con {
        word-break: break-all; }
      .original .product .product-d-flex-container .holding-details .is_hold_sold {
        color: red; }
      .original .product .product-d-flex-container .holding-details .product-info_background {
        padding: 0px 10px 0px 10px;
        line-height: 14px;
        display: flex;
        flex-direction: column;
        gap: 5px; }
        @media (min-width: 768px) and (max-width: 1024px) {
          .original .product .product-d-flex-container .holding-details .product-info_background .responsive-hold-notes {
            display: table-caption; } }
        .original .product .product-d-flex-container .holding-details .product-info_background .product-details-label {
          margin: 0; }
    .original .product .product-d-flex-container .product-details {
      width: 50%; }
      @media (max-width: 767px) {
        .original .product .product-d-flex-container .product-details {
          width: 100%;
          padding-right: 7px; } }
      .original .product .product-d-flex-container .product-details .product-info_background {
        padding: 0px 10px 0px 10px; }
        .original .product .product-d-flex-container .product-details .product-info_background .flex-con {
          display: flex; }
        .original .product .product-d-flex-container .product-details .product-info_background .product-description-con {
          word-break: break-word; }
        .original .product .product-d-flex-container .product-details .product-info_background .product-description_info {
          word-break: break-word;
          cursor: auto; }
        .original .product .product-d-flex-container .product-details .product-info_background .product-details_info {
          font-family: "Proxima Nova Semibold"; }
        .original .product .product-d-flex-container .product-details .product-info_background .pdf-icon {
          height: 12px;
          width: auto;
          margin-left: 5px;
          margin-top: -3px; }
        .original .product .product-d-flex-container .product-details .product-info_background .cursor-pointer {
          cursor: pointer; }
        .original .product .product-d-flex-container .product-details .product-info_background .cursor-none {
          cursor: auto; }
    .original .product .product-d-flex-container .product-status_details,
    .original .product .product-d-flex-container .product-sold_details,
    .original .product .product-d-flex-container .product-pricing_details {
      width: 14%; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .original .product .product-d-flex-container .product-status_details,
        .original .product .product-d-flex-container .product-sold_details,
        .original .product .product-d-flex-container .product-pricing_details {
          width: 17%; } }
      .original .product .product-d-flex-container .product-status_details .product-status-con .product-details-label div,
      .original .product .product-d-flex-container .product-sold_details .product-status-con .product-details-label div,
      .original .product .product-d-flex-container .product-pricing_details .product-status-con .product-details-label div {
        line-height: 11px; }
      .original .product .product-d-flex-container .product-status_details .product-status-con .status,
      .original .product .product-d-flex-container .product-status_details .product-status-con .location,
      .original .product .product-d-flex-container .product-sold_details .product-status-con .status,
      .original .product .product-d-flex-container .product-sold_details .product-status-con .location,
      .original .product .product-d-flex-container .product-pricing_details .product-status-con .status,
      .original .product .product-d-flex-container .product-pricing_details .product-status-con .location {
        word-break: break-all; }
      .original .product .product-d-flex-container .product-status_details .product-info_background,
      .original .product .product-d-flex-container .product-sold_details .product-info_background,
      .original .product .product-d-flex-container .product-pricing_details .product-info_background {
        padding: 3px 10px 0px 10px; }
      .original .product .product-d-flex-container .product-status_details .product-info_box,
      .original .product .product-d-flex-container .product-sold_details .product-info_box,
      .original .product .product-d-flex-container .product-pricing_details .product-info_box {
        padding-left: 8px; }
      .original .product .product-d-flex-container .product-status_details section,
      .original .product .product-d-flex-container .product-sold_details section,
      .original .product .product-d-flex-container .product-pricing_details section {
        line-height: 7px !important; }
        .original .product .product-d-flex-container .product-status_details section .product-details_lcol,
        .original .product .product-d-flex-container .product-sold_details section .product-details_lcol,
        .original .product .product-d-flex-container .product-pricing_details section .product-details_lcol {
          line-height: 7px; }
        .original .product .product-d-flex-container .product-status_details section .product-details_mcol .product-details-label,
        .original .product .product-d-flex-container .product-sold_details section .product-details_mcol .product-details-label,
        .original .product .product-d-flex-container .product-pricing_details section .product-details_mcol .product-details-label {
          position: relative;
          top: -2px; }
      .original .product .product-d-flex-container .product-status_details section:last-child,
      .original .product .product-d-flex-container .product-sold_details section:last-child,
      .original .product .product-d-flex-container .product-pricing_details section:last-child {
        padding-top: 0px; }
        .original .product .product-d-flex-container .product-status_details section:last-child .product-details-label,
        .original .product .product-d-flex-container .product-sold_details section:last-child .product-details-label,
        .original .product .product-d-flex-container .product-pricing_details section:last-child .product-details-label {
          margin-top: 0px; }
  .original .product .product-info_box {
    padding: 10px 2px 2px 2px;
    border: 1px solid #e3e3e3;
    height: 100%;
    background: #fff; }
  .original .product .product-image_box {
    border: 1px solid #e3e3e3;
    height: 100%;
    background: #fff; }
    .original .product .product-image_box .web-image-icon {
      width: 23px;
      float: right;
      cursor: pointer; }
  .original .product .product-image_container {
    background: #fff;
    width: 9%; }
    @media (max-width: 767px) {
      .original .product .product-image_container {
        width: 30%; } }
    .original .product .product-image_container .not-clickable {
      cursor: default; }
    .original .product .product-image_container .product-image {
      top: 14%;
      left: 0;
      right: 0;
      margin-top: 6px !important;
      margin-left: 4px !important;
      margin-right: 4px;
      margin-bottom: 6px;
      text-align: center; }
      .original .product .product-image_container .product-image .result-images {
        max-width: 100px !important;
        max-height: 100px !important; }
  .original .product .product-details section,
  .original .product .holding-details section,
  .original .product .product-ring-detail section,
  .original .product .product-sold_details section,
  .original .product .product-stock_details section {
    display: flex;
    justify-content: space-around; }
    .original .product .product-details section > div,
    .original .product .holding-details section > div,
    .original .product .product-ring-detail section > div,
    .original .product .product-sold_details section > div,
    .original .product .product-stock_details section > div {
      flex: 1 1 0; }
  .original .product .product-dark_title_color {
    color: #3d4148;
    font-family: "Proxima Nova Semibold";
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase; }
  .original .product .product-light_title_color {
    color: #8a8a8a;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase; }
  .original .product .product-details-info_label {
    font-size: 9px;
    color: #8a8a8a;
    opacity: 0.5;
    margin-left: 5px; }
  .original .product .product-details-view-label {
    font-size: 10px;
    color: #8a8a8a;
    opacity: 0.5 !important; }
  .original .product .product-details_line-break {
    display: block; }
  .original .product .product-status {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    left: 0;
    padding: 2px 5px; }
    .original .product .product-status.mounted {
      background: #e3e3e3;
      color: #3d4148;
      text-transform: uppercase; }
    .original .product .product-status.sold {
      background: #b6d2f2;
      color: #3d4148;
      text-transform: uppercase;
      cursor: pointer; }
    .original .product .product-status.adjusted {
      background: #b6d2f2;
      color: #3d4148;
      text-transform: uppercase;
      cursor: pointer; }
    .original .product .product-status.kwiat {
      background: #b6d2f2;
      color: #aa0707;
      text-transform: uppercase; }
    .original .product .product-status.RTV {
      background: #f1cece;
      color: #3d4148;
      text-transform: uppercase; }
    .original .product .product-status.in-production {
      background: #e3e3e3;
      color: #aa0707;
      text-transform: uppercase; }
    .original .product .product-status.semimount {
      background: #e3e3e3;
      color: #aa0707;
      text-transform: uppercase; }
    .original .product .product-status.ponumber {
      background: #b6d2f2;
      color: #3d4148;
      text-transform: uppercase; }
    .original .product .product-status.is-showcase {
      background: #ceffce;
      color: #3d4148;
      text-transform: uppercase; }
  .original .product .es__mobile-product {
    display: none;
    width: 100%; }
    @media (max-width: 767px) {
      .original .product .es__mobile-product {
        display: block;
        text-align: right; } }
    .original .product .es__mobile-product table {
      width: 100%; }
      .original .product .es__mobile-product table tbody {
        line-height: 21px; }
        .original .product .es__mobile-product table tbody td {
          padding-left: 3px;
          font-weight: 700;
          width: 50%;
          text-align: left; }
          .original .product .es__mobile-product table tbody td .es__mobile-product-details-table__label {
            font-size: 0.75em;
            color: #a0a0a0;
            margin-left: 6px;
            font-weight: 100; }
          .original .product .es__mobile-product table tbody td .pdf-icon {
            height: 13px;
            margin: -1px 0px 0px 7px; }
          .original .product .es__mobile-product table tbody td button {
            width: 22px;
            padding: 0;
            background-color: #f9f9f9;
            margin-bottom: 3px;
            border-radius: 0px;
            float: right;
            margin-right: 6px;
            border: #d6e0e8 1px solid;
            box-shadow: none;
            background-image: none; }
        .original .product .es__mobile-product table tbody .gia-pdf-data > div {
          display: inline-block; }

.original .page {
  /*padding: 1.5em;*/
  background: #ffffff; }

.original #container {
  border: 10px solid;
  padding: 5px; }

.original .img-title {
  margin-top: 22px;
  margin-left: 5px;
  height: 69px;
  width: 90px;
  border: 2px solid grey; }
  .original .img-title h1 {
    width: 30px;
    font-size: 8px;
    margin-top: -10px;
    margin-left: 35px;
    background: white; }

.original .image-box {
  width: 100%;
  height: 88%;
  margin: 10px 0px 0px 20px;
  border: 1px solid lightgray;
  border-top: 0px; }
  .original .image-box .background {
    display: table;
    white-space: nowrap; }
    .original .image-box .background > span {
      margin-top: 2px;
      display: inline-block;
      color: #b5acac; }
    .original .image-box .background:before, .original .image-box .background:after {
      border-top: 1px solid lightgray;
      content: "";
      display: table-cell;
      position: relative;
      top: 0.5em;
      width: 45%; }
    .original .image-box .background:before {
      right: 0.5%;
      top: 11px; }
    .original .image-box .background:after {
      left: 0.5%;
      top: 11px; }
  .original .image-box p {
    margin: 0 auto;
    position: relative;
    top: -12px;
    font-size: 12px;
    font-weight: bold; }
    .original .image-box p span {
      font-weight: normal; }

.original #title {
  float: left;
  padding: 0 5px;
  margin: -20px 0 0 30px;
  background: #fff; }

.original .dropdown-sort > div > ul > ul > li:hover {
  background-color: #efefef; }

.original .dropdown-sort {
  float: right;
  margin-right: 30px; }

.original .row {
  overflow: visible !important;
  margin-left: 0px !important;
  margin-right: 0px !important; }

.original .title h3 {
  margin-top: 0;
  margin-bottom: 30px;
  text-transform: uppercase; }

.original .es-scroll-button .ScrollUpButton__Container {
  border: 5px solid lightgrey !important;
  margin-right: 12px; }
  .original .es-scroll-button .ScrollUpButton__Container > svg {
    fill: lightgrey; }

.original .es__base-component {
  font-family: "Proxima Nova Regular" !important; }

.original .es-product-loader {
  text-align: center;
  padding: 5em;
  font-size: 22px; }

.original .is-fl .check-filters__toggle {
  display: flex; }
  .original .is-fl .check-filters__toggle h2 {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 14px;
    color: #58585a;
    margin-bottom: 0px; }
  .original .is-fl .check-filters__toggle .check-filters__toggle--check-select a {
    font-size: 14px;
    background: #ffff;
    border-color: #b3afaf;
    min-height: 90%;
    width: 14px;
    margin-top: 9px;
    padding: 0; }

.original .include-sold > label,
.original .include-virtual > label,
.original .is-kwiat > label,
.original .is-tiara > label,
.original .is-flround > label,
.original .is-fl > label,
.original .is-rtv > label {
  color: #58585a; }

.original .TabContentLoader {
  text-align: center;
  padding: 5em;
  font-size: 22px; }

.original .dashboard .carat-range-input {
  display: flex; }
  .original .dashboard .carat-range-input div > div > input {
    background: #fff;
    padding: 5px; }
  .original .dashboard .carat-range-input .carat-range-input__carat-slider {
    display: flex;
    align-items: center;
    vertical-align: middle;
    width: 30%; }
    .original .dashboard .carat-range-input .carat-range-input__carat-slider h2 {
      font-size: 14px;
      font-family: "Proxima Nova Regular" !important;
      line-height: 30px;
      color: #58585a;
      margin: 0px;
      display: block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .original .dashboard .carat-range-input .carat-range-input__carat-input {
    width: 70%;
    height: 35px; }

.original .dashboard .date-range .date-range-input {
  display: flex; }
  .original .dashboard .date-range .date-range-input h2.css-1pwe92q {
    font-size: 14px;
    font-family: "Proxima Nova Regular" !important;
    line-height: 30px;
    color: #58585a;
    margin: 0px;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 30%; }
  .original .dashboard .date-range .date-range-input .css-ylmnjo {
    width: 70%;
    height: 35px;
    display: flex;
    flex-direction: row; }
    .original .dashboard .date-range .date-range-input .css-ylmnjo div.css-1j6xlwo {
      width: 40%;
      border: 1px solid #ccc;
      background: white !important; }
      .original .dashboard .date-range .date-range-input .css-ylmnjo div.css-1j6xlwo .DayPickerInput {
        height: 34px !important; }
        .original .dashboard .date-range .date-range-input .css-ylmnjo div.css-1j6xlwo .DayPickerInput input {
          padding: 5px;
          height: 32px;
          margin: 1px 0px 1px 0px;
          font-size: 12px;
          color: #636363; }
        .original .dashboard .date-range .date-range-input .css-ylmnjo div.css-1j6xlwo .DayPickerInput .DayPickerInput-OverlayWrapper {
          display: block;
          width: 100%; }
      .original .dashboard .date-range .date-range-input .css-ylmnjo div.css-1j6xlwo svg {
        height: 7px;
        flex-basis: 20%; }
    .original .dashboard .date-range .date-range-input .css-ylmnjo .css-w7gaf3 {
      flex-basis: 20%; }

.original .dashboard .collapse-tab {
  cursor: pointer; }

.original .dashboard .nav-tabs {
  border-bottom: 0; }

.original .dashboard .nav-tabs > li {
  border-top: 0.5px solid #d2d4d7;
  border-right: 0.5px solid #d2d4d7;
  border-left: 0.5px solid #d2d4d7;
  display: flex;
  font-size: 16px !important; }

.original .dashboard .active {
  border-right: 1px solid #d2d4d7 !important;
  background-color: #f5f7f8; }

.original .dashboard .notActive:hover {
  background-color: #efeeef !important; }

.original .dashboard .activeTab {
  display: flex;
  padding-top: 11px; }

.original .dashboard .nav-tabs > li > a {
  color: black;
  text-transform: uppercase; }

.original .dashboard .nav-tabs > li.active > a,
.original .dashboard .nav-tabs > li.active > a:focus {
  color: #000;
  background-color: #f5f7f8;
  display: flex;
  border-right: 1px solid #f5f7f8;
  border-top: 1px solid #f5f7f8;
  border-left: 1px solid #f5f7f8;
  margin-right: 0px !important;
  font-family: "Proxima Nova Semibold" !important; }

.original .dashboard .nav-tabs > li > a {
  margin-right: 0px !important;
  border-bottom: none; }

.original .dashboard .tab-content,
.original .dashboard .body-con {
  padding: 0px 3px; }
  .original .dashboard .tab-content h4,
  .original .dashboard .body-con h4 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px; }
  .original .dashboard .tab-content .es__filters-list-column,
  .original .dashboard .body-con .es__filters-list-column {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%; }
    @media (max-width: 767px) {
      .original .dashboard .tab-content .es__filters-list-column,
      .original .dashboard .body-con .es__filters-list-column {
        width: 100%; } }
  .original .dashboard .tab-content .color-input-dropdowns,
  .original .dashboard .body-con .color-input-dropdowns {
    display: flex; }
    .original .dashboard .tab-content .color-input-dropdowns > h2,
    .original .dashboard .body-con .color-input-dropdowns > h2 {
      font-size: 14px;
      color: #58585a;
      width: 30%;
      line-height: 20px;
      margin: 0;
      display: block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .original .dashboard .tab-content .color-input-dropdowns > span,
    .original .dashboard .body-con .color-input-dropdowns > span {
      font-size: 14px;
      color: #58585a;
      width: 45px;
      display: flex;
      margin: 2px 0 0;
      justify-content: center;
      flex: 1; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .original .dashboard .tab-content .color-input-dropdowns > span,
        .original .dashboard .body-con .color-input-dropdowns > span {
          width: 17px; } }
    .large-dropdown {
      width: 32% !important;
    }
    .original .dashboard .tab-content .color-input-dropdowns .start-range,
    .original .dashboard .tab-content .color-input-dropdowns .end-range,
    .original .dashboard .body-con .color-input-dropdowns .start-range,
    .original .dashboard .body-con .color-input-dropdowns .end-range {
      width: 23%;
      margin-top: 0px; }
      @media (max-width: 767px) {
        .original .dashboard .tab-content .color-input-dropdowns .start-range,
        .original .dashboard .tab-content .color-input-dropdowns .end-range,
        .original .dashboard .body-con .color-input-dropdowns .start-range,
        .original .dashboard .body-con .color-input-dropdowns .end-range {
          width: 28%; } }
      .original .dashboard .tab-content .color-input-dropdowns .start-range > div > button,
      .original .dashboard .tab-content .color-input-dropdowns .end-range > div > button,
      .original .dashboard .body-con .color-input-dropdowns .start-range > div > button,
      .original .dashboard .body-con .color-input-dropdowns .end-range > div > button {
        font-size: 12px;
        color: #58585a;
        min-height: 30px;
        padding: 5px; }
        .original .dashboard .tab-content .color-input-dropdowns .start-range > div > button > div,
        .original .dashboard .tab-content .color-input-dropdowns .end-range > div > button > div,
        .original .dashboard .body-con .color-input-dropdowns .start-range > div > button > div,
        .original .dashboard .body-con .color-input-dropdowns .end-range > div > button > div {
          width: 100%;
          text-overflow: unset; }
      .original .dashboard .tab-content .color-input-dropdowns .start-range > div > ul,
      .original .dashboard .tab-content .color-input-dropdowns .end-range > div > ul,
      .original .dashboard .body-con .color-input-dropdowns .start-range > div > ul,
      .original .dashboard .body-con .color-input-dropdowns .end-range > div > ul {
        top: 30px;
        font-size: 11px;
        max-height: 220px !important; }
        .original .dashboard .tab-content .color-input-dropdowns .start-range > div > ul li,
        .original .dashboard .tab-content .color-input-dropdowns .end-range > div > ul li,
        .original .dashboard .body-con .color-input-dropdowns .start-range > div > ul li,
        .original .dashboard .body-con .color-input-dropdowns .end-range > div > ul li {
          padding: 4px 6px !important; }
  .original .dashboard .tab-content .diamond-con-range-input,
  .original .dashboard .body-con .diamond-con-range-input {
    width: 100%;
    display: inline-block; }
    .original .dashboard .tab-content .diamond-con-range-input div > div > input,
    .original .dashboard .body-con .diamond-con-range-input div > div > input {
      background: #fff; }
    .original .dashboard .tab-content .diamond-con-range-input .diamond-slider-container,
    .original .dashboard .body-con .diamond-con-range-input .diamond-slider-container {
      display: inline-block;
      width: 30%; }
      .original .dashboard .tab-content .diamond-con-range-input .diamond-slider-container h2,
      .original .dashboard .body-con .diamond-con-range-input .diamond-slider-container h2 {
        font-size: 14px;
        font-family: "Proxima Nova Regular" !important;
        color: #58585a;
        margin: -5px 0px;
        position: relative;
        top: -4px;
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container,
    .original .dashboard .body-con .diamond-con-range-input .diamond-input-container {
      width: 70%;
      height: 28px;
      display: inline-block; }
      .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div,
      .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div {
        display: inline-block;
        width: 34.5%;
        text-align: center; }
        @media (max-width: 767px) {
          .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div,
          .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div {
            width: 20%; } }
        @media (min-width: 768px) and (max-width: 1024px) {
          .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div,
          .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div {
            width: 34.5%; } }
      .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div:first-child,
      .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div:first-child {
        width: 32.6%; }
        @media (max-width: 767px) {
          .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div:first-child,
          .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div:first-child {
            width: 40%; } }
        .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div:first-child input,
        .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div:first-child input {
          padding: 4px;
          height: 30px; }
      .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div:last-child,
      .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div:last-child {
        width: 32.6%; }
        @media (max-width: 767px) {
          .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div:last-child,
          .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div:last-child {
            width: 40%; } }
        .original .dashboard .tab-content .diamond-con-range-input .diamond-input-container div:last-child input,
        .original .dashboard .body-con .diamond-con-range-input .diamond-input-container div:last-child input {
          padding: 5px;
          height: 30px; }
  .original .dashboard .tab-content .diamond-range,
  .original .dashboard .body-con .diamond-range {
    margin-bottom: 8px; }
    .original .dashboard .tab-content .diamond-range .e1i1ftc40,
    .original .dashboard .body-con .diamond-range .e1i1ftc40 {
      display: none; }
    .original .dashboard .tab-content .diamond-range .diamond-range-input,
    .original .dashboard .body-con .diamond-range .diamond-range-input {
      display: none; }
      .original .dashboard .tab-content .diamond-range .diamond-range-input > div,
      .original .dashboard .body-con .diamond-range .diamond-range-input > div {
        width: 30%; }
        .original .dashboard .tab-content .diamond-range .diamond-range-input > div > div > input,
        .original .dashboard .body-con .diamond-range .diamond-range-input > div > div > input {
          background: #fff; }
          @media screen and (-webkit-min-device-pixel-ratio: 0) {
            .original .dashboard .tab-content .diamond-range .diamond-range-input > div > div > input,
            .original .dashboard .body-con .diamond-range .diamond-range-input > div > div > input {
              height: 30px; } }
        .original .dashboard .tab-content .diamond-range .diamond-range-input > div > h2,
        .original .dashboard .body-con .diamond-range .diamond-range-input > div > h2 {
          font-size: 14px;
          color: #58585a;
          line-height: 26px;
          margin: 0px; }
      .original .dashboard .tab-content .diamond-range .diamond-range-input span,
      .original .dashboard .body-con .diamond-range .diamond-range-input span {
        margin: 2px 0 0;
        text-align: center;
        display: inline-block;
        width: 45px; }
      .original .dashboard .tab-content .diamond-range .diamond-range-input .diamond-input-container,
      .original .dashboard .body-con .diamond-range .diamond-range-input .diamond-input-container {
        width: 73%;
        height: 30px; }
        @media screen and (min-width: 1330px) and (max-width: 1430px) {
          .original .dashboard .tab-content .diamond-range .diamond-range-input .diamond-input-container,
          .original .dashboard .body-con .diamond-range .diamond-range-input .diamond-input-container {
            width: 73%; } }
        @media screen and (min-width: 1100px) and (max-width: 1320px) {
          .original .dashboard .tab-content .diamond-range .diamond-range-input .diamond-input-container,
          .original .dashboard .body-con .diamond-range .diamond-range-input .diamond-input-container {
            width: 73%; } }
  .original .dashboard .tab-content .carat-weight,
  .original .dashboard .body-con .carat-weight {
    margin-bottom: 8px; }
    .original .dashboard .tab-content .carat-weight .carat-weight-input,
    .original .dashboard .body-con .carat-weight .carat-weight-input {
      display: flex; }
      .original .dashboard .tab-content .carat-weight .carat-weight-input > div,
      .original .dashboard .body-con .carat-weight .carat-weight-input > div {
        width: 30%; }
        .original .dashboard .tab-content .carat-weight .carat-weight-input > div > div > input,
        .original .dashboard .body-con .carat-weight .carat-weight-input > div > div > input {
          background: #fff;
          padding: 5px; }
        .original .dashboard .tab-content .carat-weight .carat-weight-input > div > h2,
        .original .dashboard .body-con .carat-weight .carat-weight-input > div > h2 {
          font-size: 14px;
          color: #58585a;
          line-height: 20px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .original .dashboard .tab-content .carat-weight .carat-weight-input .carat-weight-container,
      .original .dashboard .body-con .carat-weight .carat-weight-input .carat-weight-container {
        width: 70%;
        height: 30px; }
  .original .dashboard .tab-content .price-range,
  .original .dashboard .body-con .price-range {
    margin-bottom: 8px; }
    .original .dashboard .tab-content .price-range .price-range__input,
    .original .dashboard .body-con .price-range .price-range__input {
      display: flex; }
      .original .dashboard .tab-content .price-range .price-range__input > div,
      .original .dashboard .body-con .price-range .price-range__input > div {
        width: 30%; }
        .original .dashboard .tab-content .price-range .price-range__input > div > div > input,
        .original .dashboard .body-con .price-range .price-range__input > div > div > input {
          background: #fff;
          padding: 5px; }
          @media screen and (-webkit-min-device-pixel-ratio: 0) {
            .original .dashboard .tab-content .price-range .price-range__input > div > div > input,
            .original .dashboard .body-con .price-range .price-range__input > div > div > input {
              height: 30px; } }
        .original .dashboard .tab-content .price-range .price-range__input > div > h2,
        .original .dashboard .body-con .price-range .price-range__input > div > h2 {
          font-size: 14px;
          color: #58585a;
          line-height: 20px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .original .dashboard .tab-content .price-range .price-range__input .price-range__input--price-input,
      .original .dashboard .body-con .price-range .price-range__input .price-range__input--price-input {
        width: 70%;
        height: 30px; }
  .original .dashboard .tab-content .toggle,
  .original .dashboard .body-con .toggle {
    display: flex; }
    .original .dashboard .tab-content .toggle > h2,
    .original .dashboard .body-con .toggle > h2 {
      margin-top: 0;
      margin-left: 7px;
      font-size: 14px;
      color: #58585a;
      margin-bottom: 0px;
      line-height: 25px; }
  .original .dashboard .tab-content .checkSelect a,
  .original .dashboard .body-con .checkSelect a {
    margin: 0;
    font-size: 16px;
    background: #ffff;
    border-color: #b3afaf;
    min-height: 90%;
    width: 14px;
    margin-top: 0;
    padding: 0; }
  .original .dashboard .tab-content .diamond-keySearch > h4,
  .original .dashboard .body-con .diamond-keySearch > h4 {
    margin: 0;
    line-height: 40px;
    margin-bottom: 13px; }
  .original .dashboard .tab-content .keyword-search > h4,
  .original .dashboard .body-con .keyword-search > h4 {
    margin: 0;
    line-height: 40px;
    margin-bottom: 13px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .original .dashboard .tab-content .col-sm-4,
    .original .dashboard .body-con .col-sm-4 {
      display: contents; } }
  .original .dashboard .tab-content .form-field-wrap,
  .original .dashboard .body-con .form-field-wrap {
    margin-bottom: 8px; }
    .original .dashboard .tab-content .form-field-wrap .form-field,
    .original .dashboard .body-con .form-field-wrap .form-field {
      display: flex; }
      .original .dashboard .tab-content .form-field-wrap .form-field > h2,
      .original .dashboard .body-con .form-field-wrap .form-field > h2 {
        width: 30%;
        font-size: 14px;
        color: #58585a;
        line-height: 30px;
        margin: 0px;
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .original .dashboard .tab-content .form-field-wrap .form-field > div,
      .original .dashboard .body-con .form-field-wrap .form-field > div {
        width: 70%; }
        .original .dashboard .tab-content .form-field-wrap .form-field > div > h2,
        .original .dashboard .body-con .form-field-wrap .form-field > div > h2 {
          font-size: 14px; }
        .original .dashboard .tab-content .form-field-wrap .form-field > div > input,
        .original .dashboard .body-con .form-field-wrap .form-field > div > input {
          font-size: 13px;
          height: 30px;
          background-color: #fff;
          padding-right: 5px; }
        .original .dashboard .tab-content .form-field-wrap .form-field > div > button,
        .original .dashboard .body-con .form-field-wrap .form-field > div > button {
          font-size: 13px;
          color: #58585a;
          min-height: 30px;
          padding: 5px; }
          .original .dashboard .tab-content .form-field-wrap .form-field > div > button > div,
          .original .dashboard .body-con .form-field-wrap .form-field > div > button > div {
            padding: 4px;
            width: 100%; }
        .original .dashboard .tab-content .form-field-wrap .form-field > div > ul,
        .original .dashboard .body-con .form-field-wrap .form-field > div > ul {
          top: 30px;
          max-height: 250px; }
          .original .dashboard .tab-content .form-field-wrap .form-field > div > ul input,
          .original .dashboard .body-con .form-field-wrap .form-field > div > ul input {
            font-size: 13px; }
          .original .dashboard .tab-content .form-field-wrap .form-field > div > ul > li,
          .original .dashboard .body-con .form-field-wrap .form-field > div > ul > li {
            font-size: 12px;
            line-height: 14px;
            padding: 4px 10px !important; }
  .original .dashboard .tab-content .select,
  .original .dashboard .body-con .select {
    padding-top: 5px; }
  .original .dashboard .tab-content .memo-out > .form-field > h2,
  .original .dashboard .body-con .memo-out > .form-field > h2 {
    font-size: 14px;
    color: #58585a;
    line-height: 29px;
    margin: 0px; }
  .original .dashboard .tab-content .flex-lr-placer,
  .original .dashboard .body-con .flex-lr-placer {
    display: flex;
    align-items: baseline; }
    .original .dashboard .tab-content .flex-lr-placer h4,
    .original .dashboard .body-con .flex-lr-placer h4 {
      flex: 1;
      margin-top: 0; }

.original .dashboard .selectedFilters {
  margin: 8px 0; }
  .original .dashboard .selectedFilters > a {
    background: #fff;
    font-size: 12px; }

.original .dashboard .es__results {
  margin: -20px 0;
  padding: 0px;
  background: #fff; }
  .original .dashboard .es__results > div > p {
    font-size: 14px; }
  .original .dashboard .es__results .gemstone-diamond-product {
    padding: 15px;
    padding-bottom: 5px;
    background: #f5f7f8;
    margin-bottom: 15px; }
    .original .dashboard .es__results .gemstone-diamond-product .productDetails {
      display: flex; }
    .original .dashboard .es__results .gemstone-diamond-product .detail-view .row {
      display: flex; }
    .original .dashboard .es__results .gemstone-diamond-product .product-title {
      padding: 0px;
      line-height: 12px;
      margin-bottom: 15px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-title a {
        color: #000; }
      .original .dashboard .es__results .gemstone-diamond-product .product-title p {
        margin: 0px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase; }
      .original .dashboard .es__results .gemstone-diamond-product .product-title span {
        font-size: 11px; }
    .original .dashboard .es__results .gemstone-diamond-product .unknown {
      color: red; }
    .original .dashboard .es__results .gemstone-diamond-product .product-img {
      max-width: 100%; }
      .original .dashboard .es__results .gemstone-diamond-product .product-img img {
        max-width: 100%; }
    .original .dashboard .es__results .gemstone-diamond-product .serialNumber {
      font-size: 12px;
      padding-top: 10px; }
      .original .dashboard .es__results .gemstone-diamond-product .serialNumber > p {
        margin-bottom: 0px !important; }
      .original .dashboard .es__results .gemstone-diamond-product .serialNumber > span {
        font-size: 10px; }
    .original .dashboard .es__results .gemstone-diamond-product .product-left-description,
    .original .dashboard .es__results .gemstone-diamond-product .product-center-description,
    .original .dashboard .es__results .gemstone-diamond-product .product-right-description {
      margin-top: 0px;
      display: flex; }
    .original .dashboard .es__results .gemstone-diamond-product .product-details {
      padding: 0px 10px;
      font-size: 12px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description {
        padding-top: 12px;
        display: flex;
        justify-content: space-between; }
        .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .left-col,
        .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .right-col {
          padding: 15px;
          padding-bottom: 0px; }
          .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .left-col span,
          .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .right-col span {
            font-size: 10px; }
        .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .left-col {
          width: 75%; }
          .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .left-col .gia-number {
            padding-top: 10px; }
          .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .left-col p {
            margin-bottom: 20px;
            font-weight: bold; }
            .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .left-col p span {
              font-weight: normal; }
        .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .right-col {
          padding-right: 0px; }
          .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .right-col p {
            margin-bottom: 12px;
            font-weight: bold; }
            .original .dashboard .es__results .gemstone-diamond-product .product-details .product-description .right-col p span {
              font-weight: normal; }
    .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature {
      width: 40%;
      margin-left: 1%; }
    .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature,
    .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature,
    .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature {
      width: 100%;
      height: 88%;
      margin: 20px 0px 0px 30px;
      border: 1px solid lightgray;
      border-top: 0px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .background,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .background,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .background {
        display: table;
        white-space: nowrap; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .background > span,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .background > span,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .background > span {
          margin-top: 2px;
          display: inline-block;
          color: #b5acac; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .background:before, .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .background:after,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .background:before,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .background:after,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .background:before,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .background:after {
          border-top: 1px solid lightgray;
          content: "";
          display: table-cell;
          position: relative;
          top: 0.5em;
          width: 45%; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .background:before,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .background:before,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .background:before {
          right: 0.5%;
          top: 11px; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .background:after,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .background:after,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .background:after {
          left: 0.5%;
          top: 11px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature p,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature p,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature p {
        margin: 0 auto;
        position: relative;
        top: -12px;
        font-size: 12px;
        font-weight: 600; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature p span,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature p span,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature p span {
          font-weight: normal; }
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-ring-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-sold-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-stock-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-ring-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-sold-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-stock-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-ring-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-sold-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-stock-detail {
        display: flex;
        justify-content: space-between;
        padding: 6px;
        padding-bottom: 0px;
        margin-top: 0px; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-ring-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-sold-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-stock-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-ring-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-sold-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-stock-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-ring-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-sold-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-stock-detail p {
          margin-bottom: 10px; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-ring-detail span,
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-sold-detail span,
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-stock-detail span,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-ring-detail span,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-sold-detail span,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-stock-detail span,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-ring-detail span,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-sold-detail span,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-stock-detail span {
          font-size: 10px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-ring-detail > div,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-ring-detail > div,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-ring-detail > div {
        padding: 0px 15px 0px 20px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-ring-detail > p,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-ring-detail > p,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-ring-detail > p {
        margin-bottom: 13px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-stock-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-stock-detail,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-stock-detail {
        line-height: 25px; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-stock-detail > div,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-stock-detail > div,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-stock-detail > div {
          padding: 0px 26px 0px 26px; }
        .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-stock-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-stock-detail p,
        .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-stock-detail p {
          margin-bottom: 11px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-sold-detail > div,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-sold-detail > div,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-sold-detail > div {
        padding-left: 7px; }
      .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature .product-sold-detail p,
      .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature .product-sold-detail p,
      .original .dashboard .es__results .gemstone-diamond-product .product-stock-feature .product-sold-detail p {
        margin-bottom: 18px;
        padding-left: 10px; }
    .original .dashboard .es__results .gemstone-diamond-product .product-ring-feature {
      margin-left: 0px; }
    .original .dashboard .es__results .gemstone-diamond-product .product-sold-feature {
      width: 45%;
      margin-left: 1%; }
  .original .dashboard .es__results .css-42igfv {
    margin: 10% 50%; }
  .original .dashboard .es__results .jewelry-product {
    padding: 15px;
    padding-bottom: 5px;
    background: #f5f7f8;
    margin-bottom: 15px; }
    .original .dashboard .es__results .jewelry-product .row {
      display: flex; }
    .original .dashboard .es__results .jewelry-product .detail-view .row {
      display: flex; }
    .original .dashboard .es__results .jewelry-product .product-title {
      padding: 0px;
      padding-bottom: 14px;
      line-height: 12px; }
      .original .dashboard .es__results .jewelry-product .product-title a {
        color: #000; }
      .original .dashboard .es__results .jewelry-product .product-title p {
        margin: 0px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase; }
      .original .dashboard .es__results .jewelry-product .product-title span {
        font-size: 11px; }
    .original .dashboard .es__results .jewelry-product .unknown {
      color: red; }
    .original .dashboard .es__results .jewelry-product .product-img {
      max-width: 100%; }
      .original .dashboard .es__results .jewelry-product .product-img img {
        max-width: 100%; }
    .original .dashboard .es__results .jewelry-product .serialNumber {
      font-size: 12px;
      padding-top: 10px; }
      .original .dashboard .es__results .jewelry-product .serialNumber > p {
        margin-bottom: 0px !important; }
      .original .dashboard .es__results .jewelry-product .serialNumber > span {
        font-size: 10px; }
    .original .dashboard .es__results .jewelry-product .product-left-description {
      margin-top: 0px !important; }
    .original .dashboard .es__results .jewelry-product .product-left-description,
    .original .dashboard .es__results .jewelry-product .product-center-description,
    .original .dashboard .es__results .jewelry-product .product-right-description {
      margin-top: 0px;
      display: flex; }
    .original .dashboard .es__results .jewelry-product .product-details {
      padding: 0px 10px;
      font-size: 12px; }
      .original .dashboard .es__results .jewelry-product .product-details .product-description {
        padding-top: 10px;
        display: flex;
        justify-content: space-between; }
        .original .dashboard .es__results .jewelry-product .product-details .product-description .left-col,
        .original .dashboard .es__results .jewelry-product .product-details .product-description .right-col {
          padding: 15px;
          padding-bottom: 0px; }
          .original .dashboard .es__results .jewelry-product .product-details .product-description .left-col span,
          .original .dashboard .es__results .jewelry-product .product-details .product-description .right-col span {
            font-size: 10px; }
        .original .dashboard .es__results .jewelry-product .product-details .product-description .left-col {
          width: 50%; }
          .original .dashboard .es__results .jewelry-product .product-details .product-description .left-col .gia-number {
            padding-top: 10px; }
          .original .dashboard .es__results .jewelry-product .product-details .product-description .left-col p {
            margin-bottom: 20px;
            font-weight: 600; }
            .original .dashboard .es__results .jewelry-product .product-details .product-description .left-col p span {
              font-weight: normal; }
        .original .dashboard .es__results .jewelry-product .product-details .product-description .right-col {
          padding-right: 0px;
          padding-top: 25px; }
          .original .dashboard .es__results .jewelry-product .product-details .product-description .right-col p {
            margin-bottom: 16px;
            font-weight: 600; }
            .original .dashboard .es__results .jewelry-product .product-details .product-description .right-col p span {
              font-weight: normal; }
    .original .dashboard .es__results .jewelry-product .product-sold-feature {
      width: 40%;
      margin-left: 1%; }
    .original .dashboard .es__results .jewelry-product .product-sold-feature,
    .original .dashboard .es__results .jewelry-product .product-ring-feature,
    .original .dashboard .es__results .jewelry-product .product-stock-feature {
      width: 100%;
      height: 88%;
      margin: 20px 0px 0px 30px;
      border: 1px solid lightgray;
      border-top: 0px; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature .background,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .background,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .background {
        display: table;
        white-space: nowrap; }
        .original .dashboard .es__results .jewelry-product .product-sold-feature .background > span,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .background > span,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .background > span {
          margin-top: 2px;
          display: inline-block;
          color: #b5acac; }
        .original .dashboard .es__results .jewelry-product .product-sold-feature .background:before, .original .dashboard .es__results .jewelry-product .product-sold-feature .background:after,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .background:before,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .background:after,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .background:before,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .background:after {
          border-top: 1px solid lightgray;
          content: "";
          display: table-cell;
          position: relative;
          top: 0.5em;
          width: 45%; }
        .original .dashboard .es__results .jewelry-product .product-sold-feature .background:before,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .background:before,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .background:before {
          right: 0.5%;
          top: 11px; }
        .original .dashboard .es__results .jewelry-product .product-sold-feature .background:after,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .background:after,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .background:after {
          left: 0.5%;
          top: 11px; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature p,
      .original .dashboard .es__results .jewelry-product .product-ring-feature p,
      .original .dashboard .es__results .jewelry-product .product-stock-feature p {
        margin: 0 auto;
        position: relative;
        top: -12px;
        font-size: 12px;
        font-weight: 600; }
        .original .dashboard .es__results .jewelry-product .product-sold-feature p span,
        .original .dashboard .es__results .jewelry-product .product-ring-feature p span,
        .original .dashboard .es__results .jewelry-product .product-stock-feature p span {
          font-weight: normal; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-ring-detail,
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-sold-detail,
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-stock-detail,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-ring-detail,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-sold-detail,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-stock-detail,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-ring-detail,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-sold-detail,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-stock-detail {
        display: flex;
        justify-content: space-between;
        padding: 6px;
        padding-bottom: 0px;
        margin-top: 0px; }
        .original .dashboard .es__results .jewelry-product .product-sold-feature .product-ring-detail p,
        .original .dashboard .es__results .jewelry-product .product-sold-feature .product-sold-detail p,
        .original .dashboard .es__results .jewelry-product .product-sold-feature .product-stock-detail p,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .product-ring-detail p,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .product-sold-detail p,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .product-stock-detail p,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .product-ring-detail p,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .product-sold-detail p,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .product-stock-detail p {
          margin-bottom: 10px; }
        .original .dashboard .es__results .jewelry-product .product-sold-feature .product-ring-detail span,
        .original .dashboard .es__results .jewelry-product .product-sold-feature .product-sold-detail span,
        .original .dashboard .es__results .jewelry-product .product-sold-feature .product-stock-detail span,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .product-ring-detail span,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .product-sold-detail span,
        .original .dashboard .es__results .jewelry-product .product-ring-feature .product-stock-detail span,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .product-ring-detail span,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .product-sold-detail span,
        .original .dashboard .es__results .jewelry-product .product-stock-feature .product-stock-detail span {
          font-size: 10px; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-ring-detail > div,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-ring-detail > div,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-ring-detail > div {
        padding: 0px 15px 0px 20px; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-ring-detail p,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-ring-detail p,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-ring-detail p {
        margin-bottom: 13px; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-stock-detail > div,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-stock-detail > div,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-stock-detail > div {
        padding: 0px 26px 0px 26px; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-stock-detail p,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-stock-detail p,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-stock-detail p {
        margin-bottom: 11px; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-sold-detail > div,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-sold-detail > div,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-sold-detail > div {
        padding-left: 7px; }
      .original .dashboard .es__results .jewelry-product .product-sold-feature .product-sold-detail p,
      .original .dashboard .es__results .jewelry-product .product-ring-feature .product-sold-detail p,
      .original .dashboard .es__results .jewelry-product .product-stock-feature .product-sold-detail p {
        margin-bottom: 18px;
        padding-left: 10px; }
    .original .dashboard .es__results .jewelry-product .product-ring-feature {
      margin-left: 0px; }
    .original .dashboard .es__results .jewelry-product .product-sold-feature {
      width: 45%;
      margin-left: 1%; }

.original .main-content {
  background: white;
  padding: 10px 3px 0px 3px;
  display: flex; }
  .original .main-content .header-con {
    width: 100%; }
    .original .main-content .header-con header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative; }
      .original .main-content .header-con header .es__basket-icon {
        margin-right: 4px;
        position: fixed;
        right: 11px;
        z-index: 999;
        cursor: pointer; }
        .original .main-content .header-con header .es__basket-icon .items__count {
          position: absolute;
          right: -7px;
          top: -8px;
          padding: 0px 7px;
          background-color: #444444;
          color: #fff;
          border-radius: 10px; }
        .original .main-content .header-con header .es__basket-icon img {
          width: 35px; }
    .original .main-content .header-con button {
      background: none;
      border: none;
      box-shadow: none; }
    .original .main-content .header-con button:focus,
    .original .main-content .header-con button:hover,
    .original .main-content .header-con button:visited {
      background: none;
      border: none;
      box-shadow: none; }
    @media (max-width: 320px) {
      .original .main-content .header-con {
        width: 100%; }
        .original .main-content .header-con .nav-tabs li:first-child {
          width: 44%; }
          .original .main-content .header-con .nav-tabs li:first-child a {
            padding: 10px 0 !important; }
          .original .main-content .header-con .nav-tabs li:first-child .activeTab {
            width: 100%;
            padding-top: 7px; }
            .original .main-content .header-con .nav-tabs li:first-child .activeTab .tab-con {
              width: 50%;
              padding: 6px 3px; }
        .original .main-content .header-con .nav-tabs li {
          font-size: 1em !important;
          width: 28%; }
          .original .main-content .header-con .nav-tabs li a {
            padding: 10px 2px !important; } }
    @media (min-width: 321px) and (max-width: 767px) {
      .original .main-content .header-con {
        width: 100%; }
        .original .main-content .header-con .nav-tabs li:first-child {
          width: 44%; }
        .original .main-content .header-con .nav-tabs li {
          width: 28%;
          font-size: 1em !important; }
          .original .main-content .header-con .nav-tabs li .activeTab {
            width: 100%;
            padding-top: 7px; }
            .original .main-content .header-con .nav-tabs li .activeTab .tab-con {
              width: 50%;
              font-size: 11px;
              padding: 7px 0 0 0; }
              .original .main-content .header-con .nav-tabs li .activeTab .tab-con a {
                padding: 10px 4px; }
          .original .main-content .header-con .nav-tabs li a {
            padding: 10px 7px; } }
  .original .main-content .tab-con {
    text-transform: capitalize;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 2px;
    font-size: 14px; }
    .original .main-content .tab-con a {
      color: black !important; }

.original #test {
  text-transform: uppercase;
  padding-left: 0px; }

.original .selected {
  font-weight: bold;
  text-transform: capitalize;
  font-size: 16px;
  text-decoration: underline; }

.original .dropdown-menu {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }
  .original .dropdown-menu > ul {
    margin: 0px; }
  .original .dropdown-menu li {
    cursor: pointer;
    width: 100%; }

.original .navbar-nav > li > a {
  padding: 5px 0 0 10px;
  line-height: 25px;
  font-family: "Proxima Nova Regular";
  color: #424242; }

.original div.dropdown-sort > div > ul {
  left: -90px !important; }

.original #sort {
  font-size: 14px !important;
  margin-right: 10px;
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.original ul.nav-sort > li {
  padding: 5px 0 0 10px;
  line-height: 25px;
  font-family: "Proxima Nova Regular";
  color: #424242; }

.original .es__mobile-popup-modal .modal-dialog {
  width: 100%;
  max-height: 90%; }

.original .es__mobile-popup-modal .modal-header {
  background: #f5f7f8;
  border: none;
  position: relative;
  text-align: center;
  padding: 15px 0 0 0; }
  .original .es__mobile-popup-modal .modal-header > button {
    position: absolute;
    right: 16px; }
  .original .es__mobile-popup-modal .modal-header .es__product-dark-title {
    color: #3d4148;
    font-family: "Proxima Nova Semibold";
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase; }

.original .es__mobile-popup-modal .modal-body {
  background: #f5f7f8;
  padding: 10px 15px 15px 15px; }
  .original .es__mobile-popup-modal .modal-body .es__product-modal-section1 {
    display: flex; }
    .original .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-image-title {
      background: #fff;
      border: 1px solid #e3e3e3;
      background: #fff;
      flex-basis: 46%; }
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-image-title .es__product-image-container {
        margin: 10px 4px 4px 4px;
        height: auto; }
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-image-title .es__product-image-container .es__product-image {
          width: 100px;
          height: 90px; }
    .original .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-details {
      width: 100%;
      margin-left: 10px; }
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-details > div {
        padding-left: 8px;
        width: 24%; }
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-details .es__product-description {
        width: auto;
        display: grid; }
  .original .es__mobile-popup-modal .modal-body .es__product-modal-section2 {
    margin-top: 5px; }
    .original .es__mobile-popup-modal .modal-body .es__product-modal-section2 table {
      width: 95%;
      margin: 0px 9px; }
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section2 table tr {
        height: 27px; }
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section2 table td {
        width: 50%;
        font-size: 0.75em;
        color: #3d4148; }
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section2 table td .es__mobile-product-details-table__label {
          font-size: 0.71em;
          color: #8a8a8a;
          margin-left: 6px; }
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section2 table td hr {
          border-top: 1px solid #e3e3e3;
          margin-top: 10px;
          margin-bottom: 10px; }
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section2 table td .pdf-icon {
          height: 12px;
          width: auto;
          margin-left: 5px;
          margin-top: -3px; }
  .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 15px; }
    .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section,
    .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section,
    .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes {
      width: 49%; }
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section .hold_notes_actions,
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section .hold_notes_actions,
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes .hold_notes_actions {
        position: relative;
        top: -10px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        /* padding: 0 10px; */
        justify-content: end;
        gap: 0; }
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section .hold_notes_actions span,
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section .hold_notes_actions span,
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes .hold_notes_actions span {
          cursor: pointer;
          color: cornflowerblue;
          padding: 0px 8px;
          font-size: 17px;
          display: inline-block;
          box-sizing: border-box; }
          .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section .hold_notes_actions span i,
          .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section .hold_notes_actions span i,
          .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes .hold_notes_actions span i {
            font-size: 15px;
            cursor: pointer; }
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section table,
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section table,
      .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes table {
        width: 100%; }
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section table tr,
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section table tr,
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes table tr {
          height: 18px; }
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section table .es__product-details__label-row td,
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section table .es__product-details__label-row td,
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes table .es__product-details__label-row td {
          font-size: 0.6em;
          color: #a0a0a0;
          vertical-align: top;
          padding-left: 10px; }
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section table .es__product-details__data-row td,
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section table .es__product-details__data-row td,
        .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes table .es__product-details__data-row td {
          font-size: 0.75em;
          color: #3d4148;
          vertical-align: bottom;
          padding-left: 10px; }
    .original .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-hold-notes {
      width: 100% !important; }
  .original .es__mobile-popup-modal .modal-body .es__product-modal-section4 {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 8px 0 !important;
    max-height: 38px; }
    .original .es__mobile-popup-modal .modal-body .es__product-modal-section4 .share-icons {
      width: 20px; }
  .original .es__mobile-popup-modal .modal-body .es__bordered-box {
    padding: 10px 2px 2px 2px;
    border: 1px solid #e3e3e3;
    background: #fff; }
  .original .es__mobile-popup-modal .modal-body .es__product-dark-title {
    color: #3d4148;
    font-family: "Proxima Nova Semibold";
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase; }
  .original .es__mobile-popup-modal .modal-body .es__product-details-heading {
    padding: 5px;
    margin: 0;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    text-align: center; }
    @media (max-width: 767px) {
      .original .es__mobile-popup-modal .modal-body .es__product-details-heading {
        top: -12px; } }
    .original .es__mobile-popup-modal .modal-body .es__product-details-heading span {
      background: #f5f7f8;
      padding: 0 5px; }

.original .nextResultLoader {
  font-size: 20px;
  padding: 50px;
  text-align: center; }

.original .visiblityHidden {
  visibility: hidden; }

.original .include-sold-range,
.original .include-virtual-range,
.original .is-rtv-range,
.original .is-kwiat-range,
.original .is-tiara-range,
.original .is-flround-range,
.original .is-fl-range,
.original .in-production-range {
  display: none; }

.original .include-mount-range {
  display: none; }

.original .show-code {
  cursor: pointer;
  font-size: 14px;
  color: #58585a;
  display: flex;
  text-align: center;
  line-height: 25px;
  column-gap: 5px; }
  .original .show-code input[type="checkbox"] {
    margin: 7px 0px;
    height: 14px;
    width: 14px; }
  .original .show-code label {
    margin: 0; }

.original .code-component {
  margin-top: 38px; }

.original .include-sold,
.original .include-virtual,
.original .include-mounted,
.original .is-rtv,
.original .is-fl,
.original .in-production {
  margin-bottom: 0px !important; }

.original .searchContainer {
  background: #f5f7f8;
  padding: 10px 5px;
  border-bottom: 1px solid #d2d4d7;
  border-right: 1px solid #d2d4d7;
  border-left: 1px solid #d2d4d7;
  border-top: 1px solid #d2d4d7; }

.original .css-u5onnz li {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important; }

.original .css-ixn26d {
  margin-left: 30px; }

.original .css-dnqvel {
  font-size: 11px !important; }

.original .product-description_accordion-test {
  border: 1px solid #58585a;
  padding: 1px;
  color: #58585a;
  background-color: white; }

.original .see-more-btn {
  margin-top: 7px;
  font-size: 12px; }

.original .middle-label-margin {
  margin-top: 17px !important; }

.original .unknown-margin {
  margin-top: 13px !important; }

.original .unknown-status-margin {
  margin-top: 21px !important; }

.original .unknown-status-margin-diamond {
  margin-top: 18px !important; }

.original .unknown-status-margin-jewelry {
  margin-top: 15px !important; }

.original .unknown-status-margin-gemstone {
  margin-top: 16px !important; }

.original .unknown-price-margin {
  margin-top: 16px !important; }

.original .unknown-jewelry-margin {
  margin-top: 20px !important; }

.original .known-status-margin-diamond {
  margin-top: 5px; }

.original .unknown-middle-margin {
  margin-top: 23px !important; }

.original .known-status-margin {
  margin-top: 19px !important; }

.original .checkFilters {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.original .toggle-filters-listCol {
  padding: 0; }

.original .clickable {
  cursor: pointer; }

.original .banner {
  opacity: 1;
  max-width: 1275px;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 767px) {
    .original .banner > img {
      max-width: 100%; } }

.original .csv-btn {
  color: #58585a;
  border: none;
  background: transparent;
  margin-bottom: 10px;
  font-size: 14px;
  outline: none;
  text-decoration: underline; }

.original .search_result_add_basket {
  background: #424242;
  border: none;
  color: #fff;
  outline: none;
  padding: 1px 6px;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 10px;
  position: relative;
  left: 92%; }
  @media (max-width: 767px) {
    .original .search_result_add_basket {
      left: 70% !important; } }

.original .search-btn__button {
  /* default for <button>, but useful for <a> */
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;
  /* invisible border (will be colored on hover/focus) */
  border: solid 1px transparent;
  border-radius: 0px;
  /* size comes from text & padding (no width/height) */
  padding: 0.5em 3.5em;
  /* make sure colors have enough contrast! */
  color: #ffffff;
  background-color: #58585a; }
  @media (max-width: 767px) {
    .original .search-btn__button {
      width: 100%;
      margin: 9px 0; } }

.original .search-btn__button.focus,
.original .search-btn__button:focus,
.original .search-btn__button:hover {
  color: #fff !important; }

.original .clear-filter-btn,
.original .clear-all-btn {
  font-size: 14px;
  margin: 4px;
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: transparent; }
  .original .clear-filter-btn .clear--icon,
  .original .clear-all-btn .clear--icon {
    margin-left: 6px;
    opacity: 0.7; }

.original .css-16nr2ca {
  top: 7px !important; }

.original .css-2r2nqm {
  top: 6px !important; }

.original .search-btn {
  text-align: center; }

@media (max-width: 767px) {
  .original .mobile-view-all {
    display: block; } }

@media (max-width: 767px) {
  .original .mobile-view-less {
    display: none; } }

.original .mobile-view-option {
  margin: auto;
  text-align: center; }
  @media (min-width: 767px) {
    .original .mobile-view-option {
      display: none; } }

.original .es__bordered-box {
  padding: 10px 2px 2px 2px;
  border: 1px solid #e3e3e3;
  background: #fff; }

.original .load__more--button {
  margin: 40px 0; }
  .original .load__more--button button {
    border-radius: 5%;
    background: #58585a !important;
    color: #fff !important;
    text-shadow: none !important;
    border-color: #58585a !important; }

.original .toggle__filters ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  .original .toggle__filters ul input[type="checkbox"] {
    margin-right: 7px; }
  .original .toggle__filters ul li {
    display: flex; }
    .original .toggle__filters ul li .toggle--label {
      font-size: 14px;
      color: #58585a;
      line-height: 20px;
      margin: 0px; }
  .original .toggle__filters ul li:first-child {
    display: none; }

.original .hide__multilist {
  display: none; }

.basket-modal {
  z-index: 9999; }
  .basket-modal .modal-dialog {
    width: 95%;
    margin: 15px auto; }
    .basket-modal .modal-dialog .modal-content {
      border: 2px solid #000;
      border-radius: 2px; }
      .basket-modal .modal-dialog .modal-content .css-42igfv {
        margin: 41vh auto; }
      .basket-modal .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        display: flex;
        max-height: 70px;
        justify-content: space-between;
        background-color: #f5f7f8;
        padding: 8px 10px; }
        .basket-modal .modal-dialog .modal-content .modal-header .es__basket-title {
          width: 6%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          justify-content: space-between; }
          .basket-modal .modal-dialog .modal-content .modal-header .es__basket-title h3 {
            font-weight: 700;
            margin: 0;
            width: 100%;
            font-size: 20px; }
          .basket-modal .modal-dialog .modal-content .modal-header .es__basket-title span {
            font-size: 15px; }
        .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group {
          width: 88%;
          background-color: #fff;
          padding: 10px 10px 5px;
          height: fit-content;
          z-index: 999;
          border: 1px solid transparent; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group button {
            font-size: 14px;
            margin: 2px 7px;
            border: 1.5px solid #58585a;
            padding: 5px 10px;
            border-radius: 0px;
            color: #ffffff;
            background-color: #58585a;
            outline: none; }
            .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group button a {
              color: #ffffff; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__save-as {
            background-color: #58585a;
            color: #fff;
            border: 1.5px solid #58585a; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .collapsed {
            background-color: #58585a;
            color: #fff;
            border: 1.5px solid #58585a; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__save {
            margin-left: 0; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__save img,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open img,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__save-as img,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__clone img,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__email img,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__export img,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__print img {
            margin-right: 4px; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped {
            margin: 12px 0 52px; }
            .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .my__basket__container,
            .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .all__basket__container,
            .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .my__basket__container,
            .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .all__basket__container {
              display: flex;
              margin-top: 5px; }
              .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .my__basket__container label,
              .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .all__basket__container label,
              .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .my__basket__container label,
              .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .all__basket__container label {
                width: 20%;
                height: 30px;
                display: flex;
                align-items: center; }
              .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .my__basket__container .open__input,
              .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .all__basket__container .open__input,
              .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .my__basket__container .open__input,
              .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .all__basket__container .open__input {
                display: flex;
                flex-direction: column;
                width: 70%; }
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .my__basket__container .open__input select,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .all__basket__container .open__input select,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .my__basket__container .open__input select,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .all__basket__container .open__input select {
                  height: 33px;
                  border: 1px solid #e3e3e3;
                  background-color: #fff;
                  outline: none; }
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .my__basket__container .open__input .Select,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .all__basket__container .open__input .Select,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .my__basket__container .open__input .Select,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .all__basket__container .open__input .Select {
                  height: 33px;
                  margin: 4px; }
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .my__basket__container .open__input button,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__open__grouped .all__basket__container .open__input button,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .my__basket__container .open__input button,
                .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .basket__saveas__grouped .all__basket__container .open__input button {
                  margin: 12px 0px;
                  width: 20%;
                  background: #000; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__action__group .email_template {
            margin: 0 !important;
            background: inherit !important;
            color: #828282 !important;
            border: none !important;
            text-decoration: underline !important; }
        .basket-modal .modal-dialog .modal-content .modal-header .basket__export__group {
          width: 3%;
          display: flex;
          align-items: center;
          justify-content: space-around; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__export__group .acumatica__export {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            font-weight: bolder;
            line-height: 15px;
            color: #000;
            border: none;
            background: transparent;
            outline: none; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__export__group .export {
            display: flex;
            align-items: center;
            font-size: 30px; }
          .basket-modal .modal-dialog .modal-content .modal-header .basket__export__group .acumatica__export,
          .basket-modal .modal-dialog .modal-content .modal-header .basket__export__group .export {
            margin: 0 10px;
            cursor: pointer; }
        .basket-modal .modal-dialog .modal-content .modal-header .basket__close--btn {
          width: 3%;
          background: #fff;
          border: none;
          border-radius: 2px;
          font-size: 20px;
          color: #58585a;
          outline: none; }
  .basket-modal .modal-body {
    padding: 0 15px 15px; }
    .basket-modal .modal-body label {
      margin: 0; }
    .basket-modal .modal-body .basket__form__section .collapse__form {
      text-align: right;
      padding: 5px 0; }
      .basket-modal .modal-body .basket__form__section .collapse__form button {
        font-size: 17px;
        padding: 4px 10px 0px;
        line-height: 18px;
        font-weight: 700; }
    .basket-modal .modal-body .basket__form__section .basket__details__form {
      display: flex;
      padding: 5px 0 20px 0; }
      .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper {
        width: 53%; }
        .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input {
          display: flex;
          align-items: center;
          margin-bottom: 10px; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input label {
            width: 20%;
            font-size: 15px;
            color: #3d4148;
            letter-spacing: 0.6px; }
            .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input label span {
              color: red;
              font-size: 12px; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input input,
          .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input select {
            width: 80%;
            height: 33px;
            background: #fff;
            border: 1.3px solid #bbbbbb;
            outline: none;
            padding: 0 10px;
            border-radius: 0; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input .form-control-feedback {
            top: 0 !important;
            color: #0014ff; }
            .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input .form-control-feedback img {
              width: 22px; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input ::placeholder {
            color: #9a7e8f; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input .form-control:focus {
            box-shadow: none; }
        .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .customer__input .input__dropdown__container {
          width: 80%;
          display: flex;
          flex-direction: column; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .customer__input .input__dropdown__container input {
            width: 100%; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .customer__input .input__dropdown__container .downshift-dropdown {
            list-style: none;
            margin: 0;
            padding: 0;
            border: 0.5px solid #bbbbbb;
            position: absolute;
            top: 100%;
            left: 20%;
            max-height: 250px;
            overflow: auto;
            z-index: 999;
            width: 80%; }
            .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .customer__input .input__dropdown__container .downshift-dropdown li {
              border-bottom: 0.5px solid #bbbbbb;
              padding: 5px; }
        .basket-modal .modal-body .basket__form__section .basket__details__form .basket__input__wrapper .basket__input:last-child {
          margin-bottom: 0; }
      .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper {
        min-width: 21%;
        max-width: 35%;
        margin-left: 25px; }
        .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .basket__summary {
          background: #f5f7f8;
          height: fit-content;
          padding: 10px 15px;
          border-radius: 1px; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .basket__summary h4 {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
            color: #3d4148;
            padding-left: 10px;
            text-align: center; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .basket__summary .basket__summary__details {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            line-height: 26px;
            color: #828282; }
            .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .basket__summary .basket__summary__details .summary__info {
              display: flex;
              width: 50%; }
              .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .basket__summary .basket__summary__details .summary__info .summary__label {
                width: 65%;
                font-weight: 600; }
              .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .basket__summary .basket__summary__details .summary__info .summary__value {
                width: 35%;
                font-weight: 600;
                color: #000; }
            .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .basket__summary .basket__summary__details .summary__wo-price .summary__value {
              color: red; }
        .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper div.setAssetOrMemo {
          display: flex;
          flex-direction: row;
          margin: 10px 0px 0px 0px;
          text-align: center;
          column-gap: 4px; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper div.setAssetOrMemo .asset-memo__toggle {
            display: flex;
            align-items: center;
            font-size: 13px; }
            .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper div.setAssetOrMemo .asset-memo__toggle input[type="radio"] {
              margin: 2px 6px 0px 10px;
              width: 13px;
              height: 13px; }
              @media (max-width: 767px) {
                .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper div.setAssetOrMemo .asset-memo__toggle input[type="radio"] {
                  width: auto;
                  height: auto; } }
            .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper div.setAssetOrMemo .asset-memo__toggle label {
              margin: 0px;
              cursor: pointer; }
        .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .make__private__checkbox {
          display: flex;
          align-items: center;
          column-gap: 4px; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .make__private__checkbox input[type="checkbox"] {
            width: 18px;
            height: 18px;
            margin: 0 0 0 0px !important; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .make__private__checkbox label {
            cursor: pointer;
            white-space: nowrap; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .make__private__checkbox button {
            width: 24%;
            height: 23px;
            background: #424242;
            border: none;
            color: #fff;
            outline: none;
            margin-left: 10px; }
          .basket-modal .modal-body .basket__form__section .basket__details__form .summary__private__wrapper .make__private__checkbox label:nth-child(5) {
            margin-left: 15px;
            text-decoration: underline;
            color: #3fa0e0;
            font-weight: normal;
            cursor: pointer;
            white-space: nowrap; }
    .basket-modal .modal-body .basket__form__section .basket__quick__add {
      display: flex;
      padding: 20px 0;
      border-top: 1px solid #bbbbbb;
      border-bottom: 1px solid #bbbbbb; }
      .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form {
        display: flex;
        width: 50%;
        justify-content: space-between; }
        .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form select {
          width: 25%;
          height: 33px;
          background: #fff;
          padding: 0 2px;
          outline: none; }
        .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form select:first-child {
          margin-right: 5px !important; }
        .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form .input__button__wrapper {
          width: 100%;
          display: flex;
          margin-left: 5px; }
          .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form .input__button__wrapper .quickadd__input {
            margin: 0;
            width: 75%; }
            .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form .input__button__wrapper .quickadd__input input {
              width: 100%;
              height: 33px;
              border-radius: 0;
              border: 1px solid #767676; }
            .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form .input__button__wrapper .quickadd__input .downshift-dropdown {
              list-style: none;
              margin: 0;
              padding: 0;
              border: 0.5px solid #bbbbbb;
              position: absolute;
              top: 100%;
              left: 0%;
              max-height: 250px;
              overflow: auto;
              z-index: 999;
              width: 100%;
              background-color: #fff; }
              .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form .input__button__wrapper .quickadd__input .downshift-dropdown li {
                border-bottom: 0.5px solid #bbbbbb;
                padding: 5px; }
          .basket-modal .modal-body .basket__form__section .basket__quick__add .quick__add__form .input__button__wrapper button {
            width: 24%;
            height: 33px;
            background: #424242;
            border: none;
            color: #fff;
            outline: none;
            margin-right: 10px; }
      .basket-modal .modal-body .basket__form__section .basket__quick__add .price__checkbox__wrapper {
        display: flex; }
        .basket-modal .modal-body .basket__form__section .basket__quick__add .price__checkbox__wrapper .price__toggle {
          margin-left: 50px;
          display: flex;
          align-items: center; }
          .basket-modal .modal-body .basket__form__section .basket__quick__add .price__checkbox__wrapper .price__toggle input[type="checkbox"] {
            margin: 0 0 0 10px;
            width: 18px;
            height: 18px; }
    .basket-modal .modal-body .basket__items__container .toggle__item__desc {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      margin: 20px 0; }
      .basket-modal .modal-body .basket__items__container .toggle__item__desc .basic-multi-select {
        min-width: 15%; }
        .basket-modal .modal-body .basket__items__container .toggle__item__desc .basic-multi-select .basket__sort__control {
          border-color: #424242; }
      .basket-modal .modal-body .basket__items__container .toggle__item__desc .basket__sort__select {
        margin-right: 5px;
        outline: none;
        height: 30px; }
      .basket-modal .modal-body .basket__items__container .toggle__item__desc button {
        outline: none;
        background: #424242;
        color: #fff;
        height: 38px;
        padding: 0 14px;
        border-radius: 5px; }
      .basket-modal .modal-body .basket__items__container .toggle__item__desc .basket_customer_class {
        font-size: 27px;
        font-weight: 500;
        width: 45%;
        text-align: center; }
      .basket-modal .modal-body .basket__items__container .toggle__item__desc .basket_check_price {
        width: 23%;
        display: flex;
        /* gap: 10px; */
        justify-content: space-between; }
      .basket-modal .modal-body .basket__items__container .toggle__item__desc .select_all_items {
        display: flex;
        column-gap: 6px;
        align-items: center;
        width: 9%; }
        .basket-modal .modal-body .basket__items__container .toggle__item__desc .select_all_items input[type="checkbox"] {
          height: 16px;
          width: 16px;
          margin-top: 0px; }
        .basket-modal .modal-body .basket__items__container .toggle__item__desc .select_all_items label {
          font-weight: normal;
          font-size: 16px;
          cursor: pointer; }
    .basket-modal .modal-body .basket__items__container .basket__items__grouped .less__details__container {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, 140px);
      grid-gap: 2rem 1rem;
      justify-content: space-between;
      width: auto !important;
      height: auto !important;
      margin-top: 15px; }
    .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box {
      width: 100%;
      height: 100%;
      padding: 6px 4px;
      position: relative;
      border: 1px solid #bbb;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box .remove_desktop_product {
        padding-right: 80%;
        cursor: pointer; }
      .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box .item__serial__number {
        position: absolute;
        top: -11px;
        background: #fff;
        padding: 0 5px;
        font-weight: 700; }
      .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box .item__thumbnail__box {
        display: flex;
        align-items: center;
        height: 100px; }
        .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box .item__thumbnail__box img {
          width: 100%;
          max-width: 100%;
          max-height: 100px; }
      .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box .items__details {
        width: 100%;
        font-size: 11px; }
        .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box .items__details .items__style__number {
          display: flex; }
          .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box .items__details .items__style__number div {
            white-space: nowrap; }
        .basket-modal .modal-body .basket__items__container .basket__items__grouped .desktop__items__box .items__details span {
          margin-left: 5px; }
    .basket-modal .modal-body .basket__items__container .basket__items__grouped .less__details__container::after {
      content: "";
      flex: auto; }
    .basket-modal .modal-body .basket__items__list {
      padding: 10px 8px;
      border: 1px solid #e3e3e3;
      background-color: #f5f7f8;
      margin: 20px 0; }
      .basket-modal .modal-body .basket__items__list > input[type="checkbox"] {
        width: 18px;
        height: 18px; }
      .basket-modal .modal-body .basket__items__list .basket__item__header {
        display: flex;
        column-gap: 12px;
        align-items: center; }
        .basket-modal .modal-body .basket__items__list .basket__item__header .drag__class {
          width: 3%;
          text-align: center;
          cursor: all-scroll; }
          .basket-modal .modal-body .basket__items__list .basket__item__header .drag__class img {
            width: 20px; }
        .basket-modal .modal-body .basket__items__list .basket__item__header input[type="checkbox"] {
          margin: 3px 0 0; }
        .basket-modal .modal-body .basket__items__list .basket__item__header .item__serial-style__number {
          width: 27%; }
          .basket-modal .modal-body .basket__items__list .basket__item__header .item__serial-style__number h5 {
            margin: 0;
            font-weight: 600;
            white-space: nowrap; }
            .basket-modal .modal-body .basket__items__list .basket__item__header .item__serial-style__number h5 .serial__style__interval {
              padding: 0 20px; }
        .basket-modal .modal-body .basket__items__list .basket__item__header .item_warehouse_value {
          width: 27%; }
          .basket-modal .modal-body .basket__items__list .basket__item__header .item_warehouse_value h5 {
            margin: 0;
            font-weight: 600;
            white-space: nowrap;
            text-align: center; }
        .basket-modal .modal-body .basket__items__list .basket__item__header .item__internal-note__input {
          width: 40%; }
          .basket-modal .modal-body .basket__items__list .basket__item__header .item__internal-note__input input {
            width: 100%;
            background: #fff;
            height: 33px;
            padding: 3px 10px;
            border: 1px solid #000; }
      .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        gap: 15px; }
        .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info {
          width: 3%;
          background-color: #fff;
          border: 1px solid #e3e3e3;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info .pos-relative {
            position: relative; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info .product-index_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100%; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info .serial-hovered {
            display: none !important; }
            @media (max-width: 767px) {
              .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info .serial-hovered {
                display: flex; } }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info .es__product__hovered {
            width: 100%;
            padding: 2px !important;
            margin: 0;
            display: none;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: space-around;
            cursor: pointer; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info .es__product__hovered img {
              width: 25px;
              cursor: pointer; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info .share-hovered {
            display: flex; }
            @media (max-width: 767px) {
              .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__quantity__info .share-hovered {
                display: none; } }
        .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__thumbnail__box {
          width: 11%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          background-color: #fff;
          border: 1px solid #e3e3e3;
          text-align: center; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__thumbnail__box .product-thumbnail {
            max-width: 100%;
            max-height: 100px;
            /*min-height: 75px;*/ }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__thumbnail__box .dia-image-icon {
            min-width: 100%;
            min-height: 100px;
            max-height: 90px;
            margin-top: 0;
            margin-bottom: 0; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__thumbnail__box .product-image-icon {
            max-width: 100%;
            max-height: 100px;
            /*            min-height: 100px;*/
            margin-top: 0; }
        .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box {
          width: 60%;
          background-color: #fff;
          border: 1px solid #e3e3e3;
          padding: 10px 14px;
          font-size: 12px;
          position: relative; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box .product-dark_title_color {
            color: #3d4148;
            font-family: "Proxima Nova Semibold";
            font-size: 1em;
            font-weight: 700;
            text-transform: uppercase; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box .product-stylenumber_heading {
            padding: 5px;
            margin: 0;
            position: absolute;
            top: -10px;
            display: flex;
            left: 0;
            right: 0;
            text-align: left; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box .product-stylenumber_heading span {
              background: #f5f7f8;
              padding: 1px 2px;
              margin-left: 8px; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box table {
            border-collapse: separate;
            border-spacing: 0 4px; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box table .item__description {
              font-style: italic; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box table .info__label {
              margin-left: 5px;
              color: #8a8a8a; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box table td {
              width: 25%; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box table .lab__report--container {
              display: flex;
              width: 100%; }
              .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__description__box table .lab__report--container img {
                width: 14px;
                height: auto;
                margin-left: 5px; }
        .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details {
          background: white;
          background-color: #fff;
          border: 1px solid #e3e3e3;
          padding: 10px 14px;
          font-size: 12px;
          width: 27%;
          padding-right: 8px; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details section {
            display: flex;
            justify-content: space-around; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details section > div {
              flex: 1 1 0; }
          @media (min-width: 768px) and (max-width: 1024px) {
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details {
              width: 20%; } }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box {
            display: flex;
            flex-direction: column;
            gap: 0px;
            padding: 10px 2px 2px 2px; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .product-light_title_color {
              color: #8a8a8a;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .product-info_box_heading {
              padding: 5px;
              margin: 0;
              position: absolute;
              top: -10px;
              left: 0;
              right: 0;
              text-align: center; }
              .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .product-info_box_heading span {
                background: #f5f7f8;
                margin-left: 8px; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .hold_notes_actions {
              position: relative;
              top: -10px;
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              /* padding: 0 10px; */
              justify-content: end;
              gap: 0; }
              .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .hold_notes_actions span {
                cursor: pointer;
                color: cornflowerblue;
                padding: 0px 8px;
                font-size: 17px;
                display: inline-block;
                box-sizing: border-box; }
                .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .hold_notes_actions span i {
                  font-size: 15px;
                  cursor: pointer; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .hold_notes_actions + .product-info_background {
              position: relative;
              top: -10px; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .entered-by,
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .customer-name {
              word-break: break-word; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .holding-description .holding-con {
              word-break: break-all; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .is_hold_sold {
              color: red; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .product-info_background {
              padding: 0px 0px 0px 0px;
              line-height: 14px;
              display: flex;
              flex-direction: column;
              gap: 5px; }
              @media (min-width: 768px) and (max-width: 1024px) {
                .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .product-info_background .responsive-hold-notes {
                  display: table-caption; } }
              .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .product-info_background .product-details-label {
                margin: 0; }
                .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .holding-details .product-info_box .product-info_background .product-details-label .product-details-view-label {
                  font-size: 10px;
                  color: #8a8a8a;
                  opacity: 0.5 !important; }
        .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box {
          width: 25%;
          background-color: #fff;
          border: 1px solid #e3e3e3;
          padding: 10px 14px;
          font-size: 12px;
          display: flex;
          flex-wrap: wrap;
          column-gap: 9px;
          position: relative; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box .product-light_title_color {
            color: #8a8a8a;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box .product-info_box_heading {
            padding: 5px;
            margin: 0;
            position: absolute;
            top: -10px;
            left: 0;
            right: 0;
            text-align: center; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box .product-info_box_heading span {
              background: #f5f7f8;
              margin-left: 8px; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table tr {
            display: flex; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table tr td:first-child {
              width: 80%; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table p {
            margin: 0; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table .basket__item__edit {
            width: 100%; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table .price__value {
            width: 100%;
            vertical-align: bottom;
            display: flex;
            align-items: center;
            padding-top: 6px; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table .price__value .price__label {
              margin-left: 5px;
              vertical-align: text-top;
              color: #8a8a8a; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table .price__label {
            vertical-align: text-top;
            color: #8a8a8a; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table tr:nth-child(even) td {
            padding-bottom: 5px; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table .edit__price__icon {
            font-size: 20px;
            text-align: right;
            width: 20%; }
            .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box table .edit__price__icon img {
              width: 25px;
              cursor: pointer; }
          .basket-modal .modal-body .basket__items__list .basket__item__box-wrapper .item__price__box select {
            width: 30%;
            height: 33px;
            background: #fff;
            padding: 2px;
            outline: none;
            font-size: 11px; }

.drag__line {
  height: 30px !important;
  background-color: #fff !important;
  border-top: 2px dashed red !important; }

.drag__line::after {
  content: "Drop Here..." !important;
  color: #3d4148 !important; }

.mobile__basket__modal {
  align-items: initial !important; }
  .mobile__basket__modal .modal-dialog {
    width: 100%; }
    .mobile__basket__modal .modal-dialog .modal-content .css-42igfv {
      margin: 41vh auto; }
    .mobile__basket__modal .modal-dialog .modal-content .modal-header {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      background-color: #f5f7f8;
      border-bottom: none; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-header .es__basket-title {
        width: 80%; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-header .es__basket-title h3 {
          margin: 0;
          font-weight: 700;
          text-transform: uppercase; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-header .basket__export__group {
        justify-content: space-between;
        background: #f5f7f8;
        padding: 10px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-header .basket__export__group .acumatica__export {
          display: flex;
          flex-direction: column;
          font-size: 13px;
          font-weight: bolder;
          line-height: 15px;
          color: #000;
          border: none;
          background: transparent;
          outline: none; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-header .basket__close--btn {
        font-size: 30px;
        background: #fff;
        border: none;
        padding: 0 20px; }
    .mobile__basket__modal .modal-dialog .modal-content .modal-header::before,
    .mobile__basket__modal .modal-dialog .modal-content .modal-header::after {
      content: none; }
    .mobile__basket__modal .modal-dialog .modal-content .modal-body {
      padding: 10px; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form {
        border-bottom: 1.3px solid #bbbbbb; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .basket__export__group {
          justify-content: space-between;
          margin-bottom: 10px;
          background: #f5f7f8;
          padding: 10px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .basket__export__group .acumatica__export {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            font-weight: bolder;
            line-height: 15px;
            color: #000;
            border: none;
            background: transparent;
            outline: none; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form label {
          margin-bottom: 0;
          color: #3d4148; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form select,
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form input {
          margin-bottom: 0;
          height: 33px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .basket__input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .basket__input label {
            width: 30%; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .basket__input input,
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .basket__input select {
            width: 70%;
            border-radius: 0;
            border: 1.3px solid #bbbbbb;
            outline: none;
            background: transparent;
            padding: 0 10px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .basket__more__inputs {
          margin-bottom: 10px;
          background: transparent;
          border: none;
          padding: 0;
          outline: none; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .customer__input .input__dropdown__container {
          width: 70%;
          display: flex;
          flex-direction: column; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .customer__input .input__dropdown__container input {
            width: 100%; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .customer__input .input__dropdown__container .downshift-dropdown {
            list-style: none;
            margin: 0;
            padding: 0;
            border: 0.5px solid #bbbbbb;
            position: absolute;
            top: 100%;
            left: 30%;
            max-height: 250px;
            overflow: auto;
            z-index: 999;
            width: 70%; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .customer__input .input__dropdown__container .downshift-dropdown li {
              border-bottom: 0.5px solid #bbbbbb;
              padding: 5px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .customer__input .form-control-feedback {
          top: 0 !important; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .customer__input .form-control-feedback img {
            width: 24px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__details__form .basket__input:last-child {
          margin-bottom: 15px; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped {
        padding: 15px 0;
        border-bottom: 1.3px solid #bbbbbb; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .visible-action__button__wrapper {
          display: flex; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .visible-action__button__wrapper .initial__visible__buttons {
            width: 70%;
            display: flex;
            justify-content: space-between; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .visible-action__button__wrapper .initial__visible__buttons button {
              width: 48%;
              font-size: 14px;
              border: 1.5px solid #58585a;
              padding: 5px 15px;
              border-radius: 0px;
              color: #ffffff;
              background-color: #58585a;
              outline: none; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .visible-action__button__wrapper .initial__visible__buttons img {
              margin-right: 4px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .visible-action__button__wrapper .basket__more__actions {
            width: 30%;
            background: transparent;
            border: none;
            outline: none;
            white-space: nowrap;
            font-size: 13px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .toggled__action__buttons {
          display: flex;
          width: 70%;
          justify-content: space-between;
          margin-top: 10px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .toggled__action__buttons button {
            width: 48%;
            font-size: 14px;
            border: 1.5px solid #58585a;
            padding: 5px 0;
            border-radius: 0px;
            color: #ffffff;
            background-color: #58585a;
            outline: none;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .toggled__action__buttons img {
            margin-right: 4px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section {
          align-items: center;
          margin-top: 10px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .all__basket__container,
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .my__basket__container,
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .all__basket__container,
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .my__basket__container {
            margin-top: 10px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .my__basket__container,
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .all__basket__container,
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .my__basket__container,
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .all__basket__container {
            display: flex; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .my__basket__container label,
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .all__basket__container label,
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .my__basket__container label,
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .all__basket__container label {
              margin-bottom: 0;
              width: 30%; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .my__basket__container .open__input,
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .all__basket__container .open__input,
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .my__basket__container .open__input,
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .all__basket__container .open__input {
              width: 70%;
              display: flex;
              flex-direction: column; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .my__basket__container .open__input select,
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .all__basket__container .open__input select,
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .my__basket__container .open__input select,
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .all__basket__container .open__input select {
                width: 100%;
                height: 33px;
                border-radius: 0;
                background: transparent;
                border: 1.3px solid #cccccc;
                outline: none; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .my__basket__container .open__input button,
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__open__grouped .all__basket__container .open__input button,
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .my__basket__container .open__input button,
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .open__bakset__section .basket__saveas__grouped .all__basket__container .open__input button {
                width: 25%;
                margin-top: 10px;
                height: 33px;
                background: #000;
                color: #fff;
                border: none;
                border-radius: 0;
                outline: none; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .additional_basket_actions {
          display: flex;
          align-items: center;
          column-gap: 4px;
          padding-top: 12px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .additional_basket_actions input[type="checkbox"] {
            width: 18px;
            height: 18px;
            margin: 0 0 0 10px !important; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .additional_basket_actions label {
            cursor: pointer;
            white-space: nowrap; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .additional_basket_actions button {
            width: 24%;
            height: 23px;
            background: #424242;
            border: none;
            color: #fff;
            outline: none;
            margin-left: 10px;
            padding: 0px 6px 4px 6px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped .additional_basket_actions label:nth-child(5) {
            margin-left: 15px;
            text-decoration: underline;
            color: #3fa0e0;
            font-weight: normal;
            cursor: pointer;
            white-space: nowrap; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped div.setAssetOrMemo {
          display: flex;
          flex-direction: row;
          margin: 11px 0px 0px 0px;
          text-align: center;
          column-gap: 4px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped div.setAssetOrMemo label {
            margin: 0; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped div.setAssetOrMemo .asset-memo__toggle {
            display: flex;
            align-items: center;
            font-size: 13px; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped div.setAssetOrMemo .asset-memo__toggle input[type="radio"] {
              margin: 2px 6px 0px 10px;
              width: 13px;
              height: 13px; }
              @media (max-width: 767px) {
                .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped div.setAssetOrMemo .asset-memo__toggle input[type="radio"] {
                  width: auto;
                  height: auto; } }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__actions__grouped div.setAssetOrMemo .asset-memo__toggle label {
              margin: 0px !important;
              cursor: pointer; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-body .quickadd__price-visibility__grouped {
        padding: 15px 0;
        border-bottom: 1.3px solid #bbbbbb; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .quickadd__price-visibility__grouped .price__checkbox__wrapper {
          display: flex;
          align-items: center; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .quickadd__price-visibility__grouped .price__checkbox__wrapper label {
            margin-bottom: 0; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .quickadd__price-visibility__grouped .price__checkbox__wrapper input[type="checkbox"] {
            margin: 0 0 0 6px;
            width: 19px;
            height: 20px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .quickadd__price-visibility__grouped .price__checkbox__wrapper .price__toggle {
            display: flex; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .quickadd__price-visibility__grouped .price__checkbox__wrapper .wholesale__checkbox {
            margin-left: 10px; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__summary {
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__summary .summary__value {
          font-weight: bold;
          margin-left: 4px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__summary .price__count {
          color: #aa0707; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__summary .summary__divider {
          margin: 0 15px; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form {
        display: flex;
        column-gap: 4px;
        border-bottom: 1px solid #bbb; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form select {
          width: 27%;
          padding: 0 6px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form select,
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form input,
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form button {
          height: 40px;
          border-radius: 0;
          border: 1.3px solid #bbbbbb;
          background: transparent;
          outline: none;
          font-size: 13px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form .input__button__wrapper {
          width: 73%;
          display: flex;
          flex-direction: row;
          column-gap: 2px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form .input__button__wrapper input {
            width: 100%; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form .input__button__wrapper button {
            width: 25%;
            background: #58585a;
            color: #fff; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form .input__button__wrapper input[type="text"] {
            padding: 6px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form .input__button__wrapper .downshift-dropdown {
            list-style: none;
            margin: 0;
            padding: 0;
            border: 0.5px solid #bbbbbb;
            position: absolute;
            top: 100%;
            left: 0%;
            max-height: 250px;
            overflow: auto;
            z-index: 999;
            width: 100%;
            background-color: #fff; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .quick__add__form .input__button__wrapper .downshift-dropdown li {
              border-bottom: 0.5px solid #bbbbbb;
              padding: 5px; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile__sort__select {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile__sort__select .basket_customer_class {
          font-size: 27px;
          font-weight: 500;
          min-width: 65%; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile__sort__select .basic-multi-select {
          min-width: 35%; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile__sort__select .basic-multi-select .basket__sort__control {
            border-color: #424242; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile_basket_select_all {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-top: 4px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile_basket_select_all .select_all {
          display: flex;
          align-items: center;
          column-gap: 4px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile_basket_select_all .select_all input[type="checkbox"] {
            margin-left: 4px;
            height: 16px;
            width: 16px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile_basket_select_all .select_all label {
            margin: 0; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .mobile_basket_select_all button {
          margin-top: 1px;
          background: #58585a;
          color: #fff;
          height: 30px;
          border-radius: 0;
          border: 1.3px solid #bbbbbb;
          outline: none;
          font-size: 13px;
          padding: 2px 6px; }
      .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile {
        margin-bottom: 10px;
        border: 1px solid #d2d4d7;
        background: #f5f7f8; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product__warehouse__value {
          padding-top: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product__warehouse__value input[type="checkbox"] {
            margin-left: 10px;
            height: 14px;
            width: 14px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product__warehouse__value label {
            margin-bottom: 0;
            white-space: nowrap;
            width: 80%;
            text-align: center; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product {
          width: 100%;
          font-size: 0.85em;
          display: flex;
          padding: 12px 0 8px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__actions__container {
            width: 10%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__actions__container .drag__class {
              width: 60%; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__actions__container .drag__class .drag__icon {
                width: 100%;
                height: auto; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__actions__container .item__quantity__info {
              width: 70%; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__actions__container .item__quantity__info input {
                width: 100%;
                text-align: center;
                border: 1px solid #616161;
                border-radius: 0; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__actions__container .remove__product {
              width: 60%; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__actions__container .remove__product .remove__icon {
                width: 100%;
                height: auto; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__actions__container .product_no {
              margin-bottom: 8px;
              text-align: center; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__image__box {
            width: 30%;
            border: 1px solid #e3e3e3;
            background: #fff;
            position: relative; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__image__box .product-info_box_heading {
              padding: 5px;
              margin: 0;
              position: absolute;
              top: -10px;
              left: 0;
              right: 0;
              text-align: center;
              color: #3d4148;
              font-family: "Proxima Nova Semibold";
              font-size: 1em;
              font-weight: bold;
              text-transform: uppercase; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__image__box .product-info_box_heading span {
                background: #f5f7f8;
                margin-left: 8px; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__image__box .item__thumbnail__box {
              top: 14%;
              left: 0;
              right: 0;
              margin-top: 6px !important;
              margin-left: 7px !important;
              margin-right: 7px;
              margin-bottom: 6px;
              text-align: center; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__image__box .item__thumbnail__box .product-thumbnail {
                max-width: 100%;
                max-height: 100px;
                margin-top: 5px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container {
            width: 60%;
            padding: 0 10px; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box {
              padding: 10px 2px 2px 2px;
              border: 1px solid #e3e3e3;
              height: 100%;
              background: #fff;
              position: relative; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product-stylenumber_heading {
                padding: 5px;
                margin: 0;
                position: absolute;
                top: -10px;
                display: flex;
                left: 0;
                right: 0;
                text-align: left;
                color: #3d4148;
                font-family: "Proxima Nova Semibold";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase; }
                .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product-stylenumber_heading span {
                  background: #f5f7f8;
                  padding: 1px 2px; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container {
                display: none;
                width: 100%; }
                @media (max-width: 767px) {
                  .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container {
                    display: block;
                    text-align: right; } }
                .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table {
                  width: 100%; }
                  .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody {
                    line-height: 21px; }
                    .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody td {
                      padding-left: 3px;
                      font-weight: 700;
                      width: 50%;
                      text-align: left; }
                      .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody td .es__mobile-product-details-table__label {
                        font-size: 0.75em;
                        color: #a0a0a0;
                        margin-left: 6px;
                        font-weight: 100; }
                      .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody td .pdf-icon {
                        height: 13px;
                        margin: -1px 0px 0px 7px; }
                      .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody td button {
                        width: 22px;
                        padding: 0;
                        background-color: #f9f9f9;
                        margin-bottom: 3px;
                        border-radius: 0px;
                        float: right;
                        margin-right: 6px;
                        border: #d6e0e8 1px solid;
                        box-shadow: none;
                        background-image: none;
                        outline: none; }
                    .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody .gia-pdf-data > div {
                      display: inline-block; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .details__divider {
          border-top: 1px solid #e3e3e3;
          padding-bottom: 8px; }
        .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container {
          width: 90%;
          float: right;
          padding-right: 8px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .basic__details {
            padding: 5px;
            border: 1px solid #e3e3e3;
            background: #fff; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .basic__details table tr,
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .basic__details table td {
              height: 15px; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details {
            background: white;
            background-color: #fff;
            border: 1px solid #e3e3e3;
            padding: 10px 0px 10px 14px;
            font-size: 12px;
            width: 27%;
            padding-right: 8px; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details section {
              display: flex;
              justify-content: space-around; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details section > div {
                flex: 1 1 0; }
            @media (max-width: 767px) {
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details {
                width: auto !important;
                margin-top: 8px; } }
            @media (min-width: 768px) and (max-width: 1024px) {
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details {
                width: 20%; } }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box {
              display: flex;
              flex-direction: column;
              gap: 0px;
              padding: 10px 2px 2px 2px; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .product-light_title_color {
                color: #8a8a8a;
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .product-info_box_heading {
                padding: 5px;
                margin: 0;
                position: absolute;
                top: -10px;
                left: 0;
                right: 0;
                text-align: center; }
                .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .product-info_box_heading span {
                  background: #f5f7f8;
                  margin-left: 8px; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .hold_notes_actions {
                position: relative;
                top: -10px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                /* padding: 0 10px; */
                justify-content: end;
                gap: 0; }
                .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .hold_notes_actions span {
                  cursor: pointer;
                  color: cornflowerblue;
                  padding: 0px 8px;
                  font-size: 17px;
                  display: inline-block;
                  box-sizing: border-box; }
                  .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .hold_notes_actions span i {
                    font-size: 15px;
                    cursor: pointer; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .hold_notes_actions + .product-info_background {
                position: relative;
                top: -10px; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .entered-by,
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .customer-name {
                word-break: break-word; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .holding-description .holding-con {
                word-break: break-all; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .is_hold_sold {
                color: red; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .product-info_background {
                padding: 0px 0px 0px 0px;
                line-height: 14px;
                display: flex;
                flex-direction: column;
                gap: 5px; }
                @media (min-width: 768px) and (max-width: 1024px) {
                  .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .product-info_background .responsive-hold-notes {
                    display: table-caption; } }
                .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .product-info_background .product-details-label {
                  margin: 0; }
                  .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .holding-details .product-info_box .product-info_background .product-details-label .product-details-view-label {
                    font-size: 10px;
                    color: #8a8a8a;
                    opacity: 0.5 !important; }
          .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped {
            margin: 18px 0 25px;
            display: flex;
            justify-content: space-between; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .price__section {
              width: 50%;
              position: relative;
              padding: 5px 5px 5px 10px;
              border: 1px solid #dadada;
              background: #fff;
              margin-right: 4px; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .price__section .box__heading {
                position: absolute;
                width: 100%;
                top: -7px;
                left: 0;
                margin: 0;
                text-align: center;
                font-weight: 700; }
                .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .price__section .box__heading span {
                  padding: 0 6px;
                  background: #f5f7f8; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .price__section p {
                margin: 5px 0 0 0; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .price__section .basket__item__edit {
                width: 100%;
                margin: 3px 0 0 0;
                border-radius: 0; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .price__section .edit__price__icon {
                text-align: end; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .price__section select {
                width: 100%;
                font-size: 12px;
                height: 25px;
                background: #fff;
                padding: 0px 3px;
                outline: none;
                margin: 5px 0 0; }
            .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .internal-note__section {
              width: 50%;
              margin-left: 4px; }
              .mobile__basket__modal .modal-dialog .modal-content .modal-body .basket__items__list--mobile .more__details__container .pricing__internal-note__grouped .internal-note__section textarea {
                width: 100%;
                height: 100%;
                padding: 7px;
                border-radius: 0; }

.sortable__item__container {
  background-color: transparent; }
  .sortable__item__container:after {
    content: "";
    clear: both;
    display: table; }

.show-more {
  padding-top: 3px;
  padding-right: 5px; }

.more-details-modal {
  z-index: 10001; }
  @media (max-width: 767px) {
    .more-details-modal .modal-dialog {
      width: 100%; } }

.Top {
  vertical-align: top; }

.modal-table tr {
  line-height: 30px;
  border-bottom: 2px #000; }

.sortableHelper.desktop__items__box {
  z-index: 10000;
  width: 100%;
  height: 100%;
  padding: 5px;
  position: relative;
  border: 1px solid #2356b4;
  background-color: #f5f7f8;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .sortableHelper.desktop__items__box .item__serial__number {
    position: absolute;
    top: -11px;
    background: #fff;
    padding: 0 5px;
    font-weight: 700; }
  .sortableHelper.desktop__items__box .item__thumbnail__box {
    display: flex;
    align-items: center;
    height: 100px; }
    .sortableHelper.desktop__items__box .item__thumbnail__box img {
      width: 100%;
      max-width: 100%;
      max-height: 100px; }
  .sortableHelper.desktop__items__box .items__details {
    width: 100%; }
    .sortableHelper.desktop__items__box .items__details span {
      margin-left: 5px; }

.p-0 {
  padding: 0px; }

.sortableHelperMobile.basket__items__list--mobile {
  z-index: 10000;
  margin-bottom: 10px;
  border: 1px solid #0966f1;
  background: #dadada; }
  .sortableHelperMobile.basket__items__list--mobile .es__basket__product {
    width: 100%;
    font-size: 0.85em;
    display: flex;
    padding: 12px 0 8px; }
    .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__actions__container {
      width: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between; }
      .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__actions__container .drag__class {
        width: 60%; }
        .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__actions__container .drag__class .drag__icon {
          width: 100%;
          height: auto; }
      .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__actions__container .item__quantity__info {
        width: 70%; }
        .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__actions__container .item__quantity__info input {
          width: 100%;
          text-align: center;
          border: 1px solid #616161;
          border-radius: 0; }
      .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__actions__container .remove__product {
        width: 60%; }
        .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__actions__container .remove__product .remove__icon {
          width: 100%;
          height: auto; }
    .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__image__box {
      width: 30%;
      border: 1px solid #e3e3e3;
      background: #fff;
      position: relative; }
      .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__image__box .product-info_box_heading {
        padding: 5px;
        margin: 0;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        text-align: center;
        color: #3d4148;
        font-family: "Proxima Nova Semibold";
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase; }
        .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__image__box .product-info_box_heading span {
          background: #f5f7f8;
          margin-left: 8px; }
      .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__image__box .item__thumbnail__box {
        top: 14%;
        left: 0;
        right: 0;
        margin-top: 6px !important;
        margin-left: 7px !important;
        margin-right: 7px;
        margin-bottom: 6px;
        text-align: center; }
        .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__image__box .item__thumbnail__box .product-thumbnail {
          max-width: 100%;
          max-height: 100px;
          margin-top: 5px; }
    .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container {
      width: 60%;
      padding: 0 10px; }
      .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box {
        padding: 10px 2px 2px 2px;
        border: 1px solid #e3e3e3;
        height: 100%;
        background: #fff;
        position: relative; }
        .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product-stylenumber_heading {
          padding: 5px;
          margin: 0;
          position: absolute;
          top: -10px;
          display: flex;
          left: 0;
          right: 0;
          text-align: left;
          color: #3d4148;
          font-family: "Proxima Nova Semibold";
          font-size: 1em;
          font-weight: bold;
          text-transform: uppercase; }
          .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product-stylenumber_heading span {
            background: #f5f7f8;
            padding: 1px 2px; }
        .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container {
          display: none;
          width: 100%; }
          @media (max-width: 767px) {
            .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container {
              display: block;
              text-align: right; } }
          .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table {
            width: 100%; }
            .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody {
              line-height: 21px; }
              .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody td {
                padding-left: 3px;
                font-weight: 700;
                width: 50%;
                text-align: left; }
                .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody td .es__mobile-product-details-table__label {
                  font-size: 0.75em;
                  color: #a0a0a0;
                  margin-left: 6px;
                  font-weight: 100; }
                .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody td .pdf-icon {
                  height: 13px;
                  margin: -1px 0px 0px 7px; }
                .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody td button {
                  width: 22px;
                  padding: 0;
                  background-color: #f9f9f9;
                  margin-bottom: 3px;
                  border-radius: 0px;
                  float: right;
                  margin-right: 6px;
                  border: #d6e0e8 1px solid;
                  box-shadow: none;
                  background-image: none;
                  outline: none; }
              .sortableHelperMobile.basket__items__list--mobile .es__basket__product .product__info__container .product-info_box .product__details__container table tbody .gia-pdf-data > div {
                display: inline-block; }

.show-more {
  color: #337ab7;
  cursor: pointer; }

.p-left {
  padding-left: 8px; }

.text-left {
  text-align: right; }

.pdf_modal,
.email_modal,
.attachment_modal {
  z-index: 110001; }
  @media (max-width: 767px) {
    .pdf_modal .modal-dialog,
    .email_modal .modal-dialog,
    .attachment_modal .modal-dialog {
      width: 100%;
      height: 100%; } }
  .pdf_modal .modal-dialog .css-42igfv,
  .email_modal .modal-dialog .css-42igfv,
  .attachment_modal .modal-dialog .css-42igfv {
    margin: 40% 45%; }
  .pdf_modal .modal-dialog div.tab-content > div.tab-pane > h5,
  .email_modal .modal-dialog div.tab-content > div.tab-pane > h5,
  .attachment_modal .modal-dialog div.tab-content > div.tab-pane > h5 {
    margin: 10px 10px 0px !important; }
  .pdf_modal .modal-dialog h4,
  .email_modal .modal-dialog h4,
  .attachment_modal .modal-dialog h4 {
    margin-left: 10px;
    font-weight: bold; }
  .pdf_modal .modal-dialog .basket__input__wrapper,
  .email_modal .modal-dialog .basket__input__wrapper,
  .attachment_modal .modal-dialog .basket__input__wrapper {
    padding: 20px; }
    .pdf_modal .modal-dialog .basket__input__wrapper .basket__input,
    .email_modal .modal-dialog .basket__input__wrapper .basket__input,
    .attachment_modal .modal-dialog .basket__input__wrapper .basket__input {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      letter-spacing: 0.6px; }
      @media (max-width: 767px) {
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input {
          flex-direction: column; } }
      .pdf_modal .modal-dialog .basket__input__wrapper .basket__input label,
      .email_modal .modal-dialog .basket__input__wrapper .basket__input label,
      .attachment_modal .modal-dialog .basket__input__wrapper .basket__input label {
        width: 15%;
        font-size: 15px;
        color: #3d4148;
        letter-spacing: 0.6px;
        display: flex; }
        @media (max-width: 767px) {
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input label,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input label,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input label {
            font-size: 13px;
            width: 100%; } }
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input label p,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input label p,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input label p {
          color: red !important;
          margin-right: 2px; }
      .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email,
      .email_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email,
      .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email {
        padding: 2px 2px !important;
        border: 1.5px solid #58585a !important;
        height: auto;
        width: 80%; }
        @media (max-width: 767px) {
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email {
            font-size: 13px;
            width: 100%; } }
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email label,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email label,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email label {
          width: auto;
          border: 1px solid black;
          padding: 5px 5px 5px 5px;
          border-radius: 25px;
          margin: 0 0 0 1px;
          font-size: 14px; }
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email label span,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email label span,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email label span {
            padding: 1px 5px 2px 5px; }
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email input,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email input,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .react-multi-email input {
          margin-left: 3px;
          font-size: 14px !important;
          height: 28px; }
      .pdf_modal .modal-dialog .basket__input__wrapper .basket__input input,
      .email_modal .modal-dialog .basket__input__wrapper .basket__input input,
      .attachment_modal .modal-dialog .basket__input__wrapper .basket__input input {
        width: 90%;
        height: 35px;
        margin-left: 6px; }
        @media (max-width: 767px) {
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input input,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input input,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input input {
            margin-left: 0px !important;
            width: 100%; } }
      .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .Select,
      .email_modal .modal-dialog .basket__input__wrapper .basket__input .Select,
      .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .Select {
        height: 33px;
        width: 85%; }
        @media (max-width: 767px) {
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .Select,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .Select,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .Select {
            width: 100%; } }
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .Select .css-yk16xz-control .css-1hwfws3,
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .Select .css-1pahdxg-control .css-1hwfws3,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .Select .css-yk16xz-control .css-1hwfws3,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .Select .css-1pahdxg-control .css-1hwfws3,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .Select .css-yk16xz-control .css-1hwfws3,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .Select .css-1pahdxg-control .css-1hwfws3 {
          height: 33px; }
      .pdf_modal .modal-dialog .basket__input__wrapper .basket__input input.focus-visible,
      .email_modal .modal-dialog .basket__input__wrapper .basket__input input.focus-visible,
      .attachment_modal .modal-dialog .basket__input__wrapper .basket__input input.focus-visible {
        outline: none !important; }
      .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill,
      .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container,
      .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill,
      .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container,
      .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill,
      .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container {
        width: 90%;
        margin-left: 6px;
        border: 1.5px solid #58585a !important; }
        @media (max-width: 767px) {
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill,
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container {
            margin-left: 0px;
            width: 100%; } }
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow,
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow {
          border-top: none !important;
          border-left: none !important;
          border-right: none !important; }
          @media (max-width: 767px) {
            .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow,
            .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow,
            .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow,
            .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow,
            .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow,
            .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: row; }
              .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow .ql-toolbar.ql-snow > span.ql-formats,
              .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow .ql-toolbar.ql-snow > span.ql-formats,
              .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow .ql-toolbar.ql-snow > span.ql-formats,
              .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow .ql-toolbar.ql-snow > span.ql-formats,
              .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-toolbar.ql-snow .ql-toolbar.ql-snow > span.ql-formats,
              .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-toolbar.ql-snow .ql-toolbar.ql-snow > span.ql-formats {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: flex-start; } }
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow,
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow {
          border: none;
          height: 210px;
          overflow: auto; }
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.ql-blank,
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.focus-visible,
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor,
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.ql-blank,
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.focus-visible,
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.ql-blank,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.focus-visible,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.ql-blank,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.focus-visible,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.ql-blank,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.focus-visible,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.ql-blank,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.focus-visible,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor {
            height: 100%; }
            .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.ql-blank p,
            .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.focus-visible p,
            .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor p,
            .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.ql-blank p,
            .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.focus-visible p,
            .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor p,
            .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.ql-blank p,
            .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.focus-visible p,
            .email_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor p,
            .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.ql-blank p,
            .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.focus-visible p,
            .email_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor p,
            .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.ql-blank p,
            .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor.focus-visible p,
            .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .quill .ql-container.ql-snow .ql-editor p,
            .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.ql-blank p,
            .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor.focus-visible p,
            .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .message_container .ql-container.ql-snow .ql-editor p {
              font-size: 12px !important;
              display: block;
              margin-block-start: 1em;
              margin-block-end: 1em;
              margin-inline-start: 0px;
              margin-inline-end: 0px; }
      .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper,
      .email_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper,
      .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper {
        width: 100%;
        padding: 1px 15%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: 8px; }
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper h4,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper h4,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper h4 {
          font-size: 14px;
          margin: 3px 0 0 0; }
        .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle,
        .email_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle,
        .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle {
          margin-top: 8px;
          display: flex;
          align-items: center;
          font-size: 13px !important;
          column-gap: 4px;
          width: 100%; }
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="radio"] {
            margin: 2px 0px 0px 0px;
            width: 12px;
            height: 12px; }
            @media (max-width: 767px) {
              .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .email_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="radio"] {
                width: auto;
                height: auto; } }
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"] {
            margin: 3px 0px 0px;
            width: 12px;
            height: 12px; }
            @media (max-width: 767px) {
              .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .email_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"] {
                width: auto;
                height: auto; } }
          .pdf_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle label,
          .email_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle label,
          .attachment_modal .modal-dialog .basket__input__wrapper .basket__input .price_label_checkbox_wrapper .price_label_toggle label {
            margin-bottom: 0px !important;
            cursor: pointer;
            width: 80%;
            font-size: 13px; }
    .pdf_modal .modal-dialog .basket__input__wrapper .template,
    .email_modal .modal-dialog .basket__input__wrapper .template,
    .attachment_modal .modal-dialog .basket__input__wrapper .template {
      flex-direction: row !important;
      flex-wrap: wrap !important; }
  .pdf_modal .modal-dialog .email_modal_header,
  .email_modal .modal-dialog .email_modal_header,
  .attachment_modal .modal-dialog .email_modal_header {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important; }
    .pdf_modal .modal-dialog .email_modal_header .action-buttons,
    .email_modal .modal-dialog .email_modal_header .action-buttons,
    .attachment_modal .modal-dialog .email_modal_header .action-buttons {
      margin-left: 68%; }
  .pdf_modal .modal-dialog .email_modal_buttons,
  .email_modal .modal-dialog .email_modal_buttons,
  .attachment_modal .modal-dialog .email_modal_buttons {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important; }
  .pdf_modal .modal-dialog .pdf_modal_header,
  .pdf_modal .modal-dialog .email_modal_header,
  .pdf_modal .modal-dialog .email_modal_buttons,
  .email_modal .modal-dialog .pdf_modal_header,
  .email_modal .modal-dialog .email_modal_header,
  .email_modal .modal-dialog .email_modal_buttons,
  .attachment_modal .modal-dialog .pdf_modal_header,
  .attachment_modal .modal-dialog .email_modal_header,
  .attachment_modal .modal-dialog .email_modal_buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
    .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options,
    .pdf_modal .modal-dialog .email_modal_header .lookbook_options,
    .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options,
    .email_modal .modal-dialog .pdf_modal_header .lookbook_options,
    .email_modal .modal-dialog .email_modal_header .lookbook_options,
    .email_modal .modal-dialog .email_modal_buttons .lookbook_options,
    .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options,
    .attachment_modal .modal-dialog .email_modal_header .lookbook_options,
    .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options {
      width: 60%;
      padding-top: 10px; }
      .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper,
      .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper,
      .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper,
      .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper,
      .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper,
      .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper,
      .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper,
      .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper,
      .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper,
      .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper,
      .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper,
      .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper,
      .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper,
      .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper,
      .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper,
      .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper,
      .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper,
      .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper {
        padding: 1px 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: 8px; }
        @media (max-width: 767px) {
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper,
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper,
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper,
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper,
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper,
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper,
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper,
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper,
          .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper,
          .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper,
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper,
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper,
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper,
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper,
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper,
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper,
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper,
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper {
            padding-right: 17%; } }
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper h4,
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper h4,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper h4,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper h4,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper h4,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper h4,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper h4,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper h4,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper h4,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper h4,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper h4,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper h4,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper h4,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper h4,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper h4,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper h4,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper h4,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper h4 {
          font-size: 14px;
          margin: 3px 0 0 0; }
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle {
          margin-top: 3px;
          display: flex;
          align-items: center;
          font-size: 13px;
          column-gap: 4px; }
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"] {
            margin: 2px 0px 0px 0px;
            width: 12px;
            height: 12px; }
            @media (max-width: 767px) {
              .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"],
              .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="radio"],
              .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="radio"],
              .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="radio"],
              .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="radio"] {
                width: auto;
                height: auto; } }
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"] {
            margin: 3px 0px 0px;
            width: 12px;
            height: 12px; }
            @media (max-width: 767px) {
              .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle input[type="checkbox"],
              .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle input[type="checkbox"] {
                width: auto;
                height: auto; } }
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label,
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .pdf_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label,
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label,
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .email_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label,
          .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .email_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .email_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label,
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .email_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label,
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label,
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .attachment_modal .modal-dialog .email_modal_header .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label,
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .item_No_toggle label,
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .item_No_checkbox_wrapper .price_label_toggle label,
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .item_No_toggle label,
          .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .price_label_checkbox_wrapper .price_label_toggle label {
            margin-bottom: 0px !important;
            cursor: pointer; }
      .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox,
      .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox,
      .pdf_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox,
      .pdf_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox,
      .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox,
      .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox,
      .email_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox,
      .email_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox,
      .email_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox,
      .email_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox,
      .email_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox,
      .email_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox,
      .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox,
      .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox,
      .attachment_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox,
      .attachment_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox,
      .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox,
      .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox {
        padding: 1px 10px;
        display: flex;
        align-items: center;
        column-gap: 4px; }
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox input[type="radio"]#stone_check,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox input[type="radio"]#stone_check,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox input[type="radio"]#stone_check,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox input[type="radio"]#stone_check,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox input[type="radio"]#stone_check,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox input[type="radio"]#stone_check,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox input[type="radio"]#stone_check,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox input[type="radio"]#stone_check,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox input[type="radio"]#stone_check,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox input[type="radio"]#stone_check {
          margin-top: 0px; }
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox label,
        .pdf_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox label,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox label,
        .pdf_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox label,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox label,
        .pdf_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox label,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox label,
        .email_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox label,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox label,
        .email_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox label,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox label,
        .email_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox label,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .include_stones_checkbox label,
        .attachment_modal .modal-dialog .pdf_modal_header .lookbook_options .include_location_checkbox label,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .include_stones_checkbox label,
        .attachment_modal .modal-dialog .email_modal_header .lookbook_options .include_location_checkbox label,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .include_stones_checkbox label,
        .attachment_modal .modal-dialog .email_modal_buttons .lookbook_options .include_location_checkbox label {
          margin: 0;
          cursor: pointer; }
    .pdf_modal .modal-dialog .pdf_modal_header .action-buttons,
    .pdf_modal .modal-dialog .email_modal_header .action-buttons,
    .pdf_modal .modal-dialog .email_modal_buttons .action-buttons,
    .email_modal .modal-dialog .pdf_modal_header .action-buttons,
    .email_modal .modal-dialog .email_modal_header .action-buttons,
    .email_modal .modal-dialog .email_modal_buttons .action-buttons,
    .attachment_modal .modal-dialog .pdf_modal_header .action-buttons,
    .attachment_modal .modal-dialog .email_modal_header .action-buttons,
    .attachment_modal .modal-dialog .email_modal_buttons .action-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .pdf_modal .modal-dialog .pdf_modal_header .action-buttons button.email,
      .pdf_modal .modal-dialog .email_modal_header .action-buttons button.email,
      .pdf_modal .modal-dialog .email_modal_buttons .action-buttons button.email,
      .email_modal .modal-dialog .pdf_modal_header .action-buttons button.email,
      .email_modal .modal-dialog .email_modal_header .action-buttons button.email,
      .email_modal .modal-dialog .email_modal_buttons .action-buttons button.email,
      .attachment_modal .modal-dialog .pdf_modal_header .action-buttons button.email,
      .attachment_modal .modal-dialog .email_modal_header .action-buttons button.email,
      .attachment_modal .modal-dialog .email_modal_buttons .action-buttons button.email {
        font-size: 14px;
        margin: 2px 7px;
        border: 1.5px solid #58585a;
        padding: 5px 15px;
        border-radius: 0px;
        color: #ffffff;
        background-color: #58585a;
        outline: none; }
        @media (max-width: 767px) {
          .pdf_modal .modal-dialog .pdf_modal_header .action-buttons button.email,
          .pdf_modal .modal-dialog .email_modal_header .action-buttons button.email,
          .pdf_modal .modal-dialog .email_modal_buttons .action-buttons button.email,
          .email_modal .modal-dialog .pdf_modal_header .action-buttons button.email,
          .email_modal .modal-dialog .email_modal_header .action-buttons button.email,
          .email_modal .modal-dialog .email_modal_buttons .action-buttons button.email,
          .attachment_modal .modal-dialog .pdf_modal_header .action-buttons button.email,
          .attachment_modal .modal-dialog .email_modal_header .action-buttons button.email,
          .attachment_modal .modal-dialog .email_modal_buttons .action-buttons button.email {
            margin-left: 12px; } }
      .pdf_modal .modal-dialog .pdf_modal_header .action-buttons button.clear,
      .pdf_modal .modal-dialog .pdf_modal_header .action-buttons button.back,
      .pdf_modal .modal-dialog .email_modal_header .action-buttons button.clear,
      .pdf_modal .modal-dialog .email_modal_header .action-buttons button.back,
      .pdf_modal .modal-dialog .email_modal_buttons .action-buttons button.clear,
      .pdf_modal .modal-dialog .email_modal_buttons .action-buttons button.back,
      .email_modal .modal-dialog .pdf_modal_header .action-buttons button.clear,
      .email_modal .modal-dialog .pdf_modal_header .action-buttons button.back,
      .email_modal .modal-dialog .email_modal_header .action-buttons button.clear,
      .email_modal .modal-dialog .email_modal_header .action-buttons button.back,
      .email_modal .modal-dialog .email_modal_buttons .action-buttons button.clear,
      .email_modal .modal-dialog .email_modal_buttons .action-buttons button.back,
      .attachment_modal .modal-dialog .pdf_modal_header .action-buttons button.clear,
      .attachment_modal .modal-dialog .pdf_modal_header .action-buttons button.back,
      .attachment_modal .modal-dialog .email_modal_header .action-buttons button.clear,
      .attachment_modal .modal-dialog .email_modal_header .action-buttons button.back,
      .attachment_modal .modal-dialog .email_modal_buttons .action-buttons button.clear,
      .attachment_modal .modal-dialog .email_modal_buttons .action-buttons button.back {
        font-size: 14px;
        margin: 2px 7px;
        border: 1.5px solid #58585a;
        padding: 5px 15px;
        border-radius: 0px;
        color: #434343;
        background-color: #ffffff;
        outline: none; }
        @media (max-width: 767px) {
          .pdf_modal .modal-dialog .pdf_modal_header .action-buttons button.clear,
          .pdf_modal .modal-dialog .pdf_modal_header .action-buttons button.back,
          .pdf_modal .modal-dialog .email_modal_header .action-buttons button.clear,
          .pdf_modal .modal-dialog .email_modal_header .action-buttons button.back,
          .pdf_modal .modal-dialog .email_modal_buttons .action-buttons button.clear,
          .pdf_modal .modal-dialog .email_modal_buttons .action-buttons button.back,
          .email_modal .modal-dialog .pdf_modal_header .action-buttons button.clear,
          .email_modal .modal-dialog .pdf_modal_header .action-buttons button.back,
          .email_modal .modal-dialog .email_modal_header .action-buttons button.clear,
          .email_modal .modal-dialog .email_modal_header .action-buttons button.back,
          .email_modal .modal-dialog .email_modal_buttons .action-buttons button.clear,
          .email_modal .modal-dialog .email_modal_buttons .action-buttons button.back,
          .attachment_modal .modal-dialog .pdf_modal_header .action-buttons button.clear,
          .attachment_modal .modal-dialog .pdf_modal_header .action-buttons button.back,
          .attachment_modal .modal-dialog .email_modal_header .action-buttons button.clear,
          .attachment_modal .modal-dialog .email_modal_header .action-buttons button.back,
          .attachment_modal .modal-dialog .email_modal_buttons .action-buttons button.clear,
          .attachment_modal .modal-dialog .email_modal_buttons .action-buttons button.back {
            margin-left: 12px; } }
  .pdf_modal .modal-dialog hr.divider,
  .email_modal .modal-dialog hr.divider,
  .attachment_modal .modal-dialog hr.divider {
    background-color: #58585a;
    height: 2px; }
  .pdf_modal .modal-dialog .preview-header,
  .email_modal .modal-dialog .preview-header,
  .attachment_modal .modal-dialog .preview-header {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .pdf_modal .modal-dialog .preview-header h4,
    .email_modal .modal-dialog .preview-header h4,
    .attachment_modal .modal-dialog .preview-header h4 {
      width: 80%; }
      @media (max-width: 767px) {
        .pdf_modal .modal-dialog .preview-header h4,
        .email_modal .modal-dialog .preview-header h4,
        .attachment_modal .modal-dialog .preview-header h4 {
          width: 60%; } }
    .pdf_modal .modal-dialog .preview-header button,
    .pdf_modal .modal-dialog .preview-header .csv-link,
    .email_modal .modal-dialog .preview-header button,
    .email_modal .modal-dialog .preview-header .csv-link,
    .attachment_modal .modal-dialog .preview-header button,
    .attachment_modal .modal-dialog .preview-header .csv-link {
      font-size: 12px;
      margin: 2px 7px;
      border: 1.5px solid #58585a;
      padding: 5px 15px;
      border-radius: 0px;
      color: #ffffff;
      background-color: #58585a;
      outline: none; }
  .pdf_modal .modal-dialog .csvData_container,
  .email_modal .modal-dialog .csvData_container,
  .attachment_modal .modal-dialog .csvData_container {
    width: 100%;
    overflow: auto;
    height: 500px;
    margin: 20px 0px 0px; }
  .pdf_modal .modal-dialog .layout_second_header_preview,
  .email_modal .modal-dialog .layout_second_header_preview,
  .attachment_modal .modal-dialog .layout_second_header_preview {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 20px; }
  .pdf_modal .modal-dialog .layout_second_footer_preview,
  .email_modal .modal-dialog .layout_second_footer_preview,
  .attachment_modal .modal-dialog .layout_second_footer_preview {
    border-top: solid #b8b8b8 1px;
    text-align: center;
    font-weight: 500;
    padding: 6px 0px 4px 0px;
    margin: 20px 0.4in 0px 0.4in;
    height: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 9px;
    font-family: Arial, Helvetica, sans-serif; }
    .pdf_modal .modal-dialog .layout_second_footer_preview span:first-child,
    .email_modal .modal-dialog .layout_second_footer_preview span:first-child,
    .attachment_modal .modal-dialog .layout_second_footer_preview span:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around; }
      .pdf_modal .modal-dialog .layout_second_footer_preview span:first-child .copyright,
      .email_modal .modal-dialog .layout_second_footer_preview span:first-child .copyright,
      .attachment_modal .modal-dialog .layout_second_footer_preview span:first-child .copyright {
        font-size: 5px; }
    .pdf_modal .modal-dialog .layout_second_footer_preview span:nth-child(2),
    .email_modal .modal-dialog .layout_second_footer_preview span:nth-child(2),
    .attachment_modal .modal-dialog .layout_second_footer_preview span:nth-child(2) {
      width: 50%; }
    .pdf_modal .modal-dialog .layout_second_footer_preview .kwiat,
    .email_modal .modal-dialog .layout_second_footer_preview .kwiat,
    .attachment_modal .modal-dialog .layout_second_footer_preview .kwiat {
      max-width: 70px; }
    .pdf_modal .modal-dialog .layout_second_footer_preview .fred,
    .email_modal .modal-dialog .layout_second_footer_preview .fred,
    .attachment_modal .modal-dialog .layout_second_footer_preview .fred {
      max-width: 230px !important; }
    .pdf_modal .modal-dialog .layout_second_footer_preview .kwfl,
    .email_modal .modal-dialog .layout_second_footer_preview .kwfl,
    .attachment_modal .modal-dialog .layout_second_footer_preview .kwfl {
      max-width: 130px !important; }
    .pdf_modal .modal-dialog .layout_second_footer_preview .bridal,
    .email_modal .modal-dialog .layout_second_footer_preview .bridal,
    .attachment_modal .modal-dialog .layout_second_footer_preview .bridal {
      max-width: 70px !important; }
  .pdf_modal .modal-dialog .modal-content > button,
  .email_modal .modal-dialog .modal-content > button,
  .attachment_modal .modal-dialog .modal-content > button {
    cursor: pointer;
    position: sticky;
    right: 10px;
    bottom: 35px;
    margin-left: 120%;
    width: 50px;
    height: 50px;
    border: 1.5px solid #d0d0d0 !important;
    background-color: #d0d0d0;
    border-radius: 80%; }
    @media (max-width: 767px) {
      .pdf_modal .modal-dialog .modal-content > button,
      .email_modal .modal-dialog .modal-content > button,
      .attachment_modal .modal-dialog .modal-content > button {
        margin-left: 80%; } }

.tooltip {
  z-index: 10000 !important; }

.choose-layout-modal {
  z-index: 10000; }
  .choose-layout-modal .modal-dialog {
    width: 50vw; }
    @media (max-width: 767px) {
      .choose-layout-modal .modal-dialog {
        width: 100%;
        height: 100%; } }
    .choose-layout-modal .modal-dialog .modal-content .modal-header {
      font-size: 18px;
      font-weight: bold; }
      @media (max-width: 767px) {
        .choose-layout-modal .modal-dialog .modal-content .modal-header {
          font-size: 16px;
          font-weight: bold; } }
    @media (max-width: 767px) {
      .choose-layout-modal .modal-dialog .modal-content .modal-body {
        padding: 5px; } }
    .choose-layout-modal .modal-dialog .modal-content .modal-body h4 {
      font-weight: bold;
      font-size: 14px;
      margin: 6px 0px; }
    .choose-layout-modal .modal-dialog .modal-content .modal-body .price__checkbox__wrapper {
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap; }
      @media (max-width: 767px) {
        .choose-layout-modal .modal-dialog .modal-content .modal-body .price__checkbox__wrapper {
          padding-right: 17%; } }
      .choose-layout-modal .modal-dialog .modal-content .modal-body .price__checkbox__wrapper .price__toggle {
        margin-top: 4px;
        display: flex;
        align-items: center;
        font-size: 13px; }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .price__checkbox__wrapper .price__toggle input[type="checkbox"] {
          margin: 0px 6px 0px 10px;
          width: 13px;
          height: 13px; }
          @media (max-width: 767px) {
            .choose-layout-modal .modal-dialog .modal-content .modal-body .price__checkbox__wrapper .price__toggle input[type="checkbox"] {
              width: auto;
              height: auto; } }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .price__checkbox__wrapper .price__toggle label {
          cursor: pointer;
          margin: 0px 0px 2px 0px; }
    .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper,
    .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper {
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap; }
      @media (max-width: 767px) {
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper {
          padding-right: 17%; } }
      .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .links__toggle,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .gia__toggle,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper .links__toggle,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper .gia__toggle {
        display: flex;
        align-items: center;
        font-size: 13px; }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .links__toggle input[type="radio"],
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .gia__toggle input[type="radio"],
        .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper .links__toggle input[type="radio"],
        .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper .gia__toggle input[type="radio"] {
          margin: 2px 6px 0px 10px;
          width: 13px;
          height: 13px; }
          @media (max-width: 767px) {
            .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .links__toggle input[type="radio"],
            .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .gia__toggle input[type="radio"],
            .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper .links__toggle input[type="radio"],
            .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper .gia__toggle input[type="radio"] {
              width: auto;
              height: auto; } }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .links__toggle label,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .gia__toggle label,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper .links__toggle label,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .gia__checkbox__wrapper .gia__toggle label {
          margin: 0px;
          cursor: pointer; }
    .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper,
    .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper {
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap; }
      @media (max-width: 767px) {
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper {
          padding-right: 17%; } }
      .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .links__toggle,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .gia__toggle,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper .links__toggle,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper .gia__toggle {
        display: flex;
        align-items: center;
        font-size: 13px; }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .links__toggle input[type="checkbox"],
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .gia__toggle input[type="checkbox"],
        .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper .links__toggle input[type="checkbox"],
        .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper .gia__toggle input[type="checkbox"] {
          margin: 2px 6px 0px 10px;
          width: 13px;
          height: 13px; }
          @media (max-width: 767px) {
            .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .links__toggle input[type="checkbox"],
            .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .gia__toggle input[type="checkbox"],
            .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper .links__toggle input[type="checkbox"],
            .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper .gia__toggle input[type="checkbox"] {
              width: auto;
              height: auto; } }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .links__toggle label,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .links__checkbox__wrapper .gia__toggle label,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper .links__toggle label,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .mts__ktd__checkbox__wrapper .gia__toggle label {
          margin: 0px;
          cursor: pointer; }
    .choose-layout-modal .modal-dialog .modal-content .modal-body .shapes__checkbox__wrapper {
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap; }
      @media (max-width: 767px) {
        .choose-layout-modal .modal-dialog .modal-content .modal-body .shapes__checkbox__wrapper {
          padding-right: 17%; } }
      .choose-layout-modal .modal-dialog .modal-content .modal-body .shapes__checkbox__wrapper .price__toggle {
        margin-top: 4px;
        display: flex;
        align-items: center;
        font-size: 13px; }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .shapes__checkbox__wrapper .price__toggle input[type="checkbox"] {
          margin: 0px 6px 0px 10px;
          width: 13px;
          height: 13px; }
          @media (max-width: 767px) {
            .choose-layout-modal .modal-dialog .modal-content .modal-body .shapes__checkbox__wrapper .price__toggle input[type="checkbox"] {
              width: auto;
              height: auto; } }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .shapes__checkbox__wrapper .price__toggle label {
          cursor: pointer;
          margin: 0px 0px 2px 0px; }
    .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts,
    .choose-layout-modal .modal-dialog .modal-content .modal-body .covers,
    .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated {
      padding: 5px 10px; }
      @media (max-width: 767px) {
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated {
          margin-bottom: 10px; } }
      .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts h4,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .covers h4,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated h4 {
        font-weight: bold; }
      .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select {
        height: 33px;
        width: 70%; }
        @media (max-width: 767px) {
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select {
            width: 65%; } }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-yk16xz-control .css-1hwfws3,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-1pahdxg-control .css-1hwfws3,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-26l3qy-menu .css-1hwfws3,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-yk16xz-control .css-1hwfws3,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-1pahdxg-control .css-1hwfws3,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-26l3qy-menu .css-1hwfws3,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-yk16xz-control .css-1hwfws3,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-1pahdxg-control .css-1hwfws3,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-26l3qy-menu .css-1hwfws3 {
          height: 33px; }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-yk16xz-control div.cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-1pahdxg-control div.cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-26l3qy-menu div.cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-yk16xz-control div.cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-1pahdxg-control div.cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-26l3qy-menu div.cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-yk16xz-control div.cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-1pahdxg-control div.cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-26l3qy-menu div.cover_option {
          display: flex;
          gap: 10px;
          align-items: center; }
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-yk16xz-control div.cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-1pahdxg-control div.cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .Select .css-26l3qy-menu div.cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-yk16xz-control div.cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-1pahdxg-control div.cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .Select .css-26l3qy-menu div.cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-yk16xz-control div.cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-1pahdxg-control div.cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .Select .css-26l3qy-menu div.cover_option img {
            width: 30px; }
      .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper,
      .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper {
        list-style: none;
        display: inline-flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        row-gap: 15px;
        column-gap: 15px; }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .layout_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .layout_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .layout_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .layout_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .layout_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .cover_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .layout_option,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .cover_option {
          font-size: 14px;
          font-weight: bold;
          padding: 10px;
          cursor: pointer;
          border: 1.5px solid #58585a !important;
          background-color: #fffefe;
          color: #58585a;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around; }
          @media (max-width: 767px) {
            .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .layout_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .cover_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .layout_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .cover_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .layout_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .cover_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .layout_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .cover_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .layout_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .cover_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .layout_option,
            .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .cover_option {
              font-size: 14px;
              padding: 5px;
              margin: 2px 10px;
              text-align: center; } }
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .layout_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .layout_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .layout_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .layout_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .layout_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .cover_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .layout_option img,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .cover_option img {
            max-width: 80px;
            min-height: 80px;
            max-height: 80px; }
            @media (max-width: 767px) {
              .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .layout_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .cover_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .layout_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .cover_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .layout_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .cover_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .layout_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .cover_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .layout_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .cover_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .layout_option img,
              .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .cover_option img {
                max-width: 100px;
                height: auto; } }
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .layout_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .cover_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .layout_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .cover_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .layout_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .cover_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .layout_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .cover_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .layout_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .cover_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .layout_option span,
          .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .cover_option span {
            margin-top: 10px;
            font-size: 11px; }
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .layouts-wrapper .Active,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .layouts .covers-wrapper .Active,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .layouts-wrapper .Active,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers .covers-wrapper .Active,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .layouts-wrapper .Active,
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated .covers-wrapper .Active {
          border: 1.5px solid #58585a !important;
          background-color: #58585a;
          color: #fffefe; }
    .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated {
      display: flex !important;
      align-items: center !important;
      gap: 15px !important; }
      @media (max-width: 767px) {
        .choose-layout-modal .modal-dialog .modal-content .modal-body .covers_updated {
          gap: 10px; } }
    .choose-layout-modal .modal-dialog .modal-content .modal-body .action-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .choose-layout-modal .modal-dialog .modal-content .modal-body .action-buttons button {
        font-size: 14px;
        margin: 2px 7px;
        border: 1.5px solid #58585a;
        padding: 5px 15px;
        border-radius: 0px;
        color: #ffffff;
        background-color: #58585a;
        outline: none; }

.basket_change_modal {
  z-index: 10000; }
  .basket_change_modal .modal-dialog {
    width: 25vw; }
    @media (max-width: 767px) {
      .basket_change_modal .modal-dialog {
        width: 100%; } }
    .basket_change_modal .modal-dialog .modal-content .modal-header {
      font-size: 18px;
      font-weight: bold; }
      @media (max-width: 767px) {
        .basket_change_modal .modal-dialog .modal-content .modal-header {
          font-size: 16px;
          font-weight: bold; } }
    .basket_change_modal .modal-dialog .modal-content .modal-body .basket_change_title {
      padding: 20px;
      font-size: 16px;
      text-align: center; }
    .basket_change_modal .modal-dialog .modal-content .modal-body .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center; }
      .basket_change_modal .modal-dialog .modal-content .modal-body .action-buttons button {
        font-size: 14px;
        margin: 2px 7px;
        border: 1.5px solid #58585a;
        padding: 5px 8px;
        border-radius: 0px;
        color: #ffffff;
        background-color: #58585a;
        outline: none; }

.show-export-modal,
.show-diamond-export-modal {
  z-index: 10000; }
  @media (max-width: 767px) {
    .show-export-modal .modal-dialog,
    .show-diamond-export-modal .modal-dialog {
      width: 100%; } }
  .show-export-modal .modal-content .modal-header,
  .show-diamond-export-modal .modal-content .modal-header {
    font-size: 18px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .show-export-modal .modal-content .modal-header,
      .show-diamond-export-modal .modal-content .modal-header {
        font-size: 16px;
        font-weight: bold; } }
  .show-export-modal .price__checkbox__wrapper,
  .show-diamond-export-modal .price__checkbox__wrapper {
    padding-right: 7%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px; }
    @media (max-width: 767px) {
      .show-export-modal .price__checkbox__wrapper,
      .show-diamond-export-modal .price__checkbox__wrapper {
        padding-right: 17%; } }
    .show-export-modal .price__checkbox__wrapper .price__toggle,
    .show-diamond-export-modal .price__checkbox__wrapper .price__toggle {
      margin-left: 50px;
      display: flex;
      align-items: center; }
      .show-export-modal .price__checkbox__wrapper .price__toggle input[type="checkbox"],
      .show-diamond-export-modal .price__checkbox__wrapper .price__toggle input[type="checkbox"] {
        margin: 0 0 0px 10px;
        width: 18px;
        height: 18px; }
  .show-export-modal .export-option,
  .show-diamond-export-modal .export-option {
    padding: 10px 100px 20px 100px; }
    @media (max-width: 767px) {
      .show-export-modal .export-option,
      .show-diamond-export-modal .export-option {
        padding: 25px 5px; } }
    .show-export-modal .export-option ul,
    .show-diamond-export-modal .export-option ul {
      margin: auto;
      list-style: none;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 0; }
      .show-export-modal .export-option ul li,
      .show-export-modal .export-option ul button,
      .show-diamond-export-modal .export-option ul li,
      .show-diamond-export-modal .export-option ul button {
        margin: 2px 10px;
        font-size: 14px;
        font-weight: bold;
        padding: 10px;
        cursor: pointer;
        border: 1.5px solid #58585a !important;
        background-color: #58585a;
        color: #fffefe; }
        @media (max-width: 767px) {
          .show-export-modal .export-option ul li,
          .show-export-modal .export-option ul button,
          .show-diamond-export-modal .export-option ul li,
          .show-diamond-export-modal .export-option ul button {
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            margin: 2px 10px;
            padding: 5px; } }

.show-diamond-export-modal {
  z-index: 100001 !important; }

.export-jewelry-modal {
  z-index: 10001; }
  @media (max-width: 767px) {
    .export-jewelry-modal .modal-dialog {
      width: 100%; } }
  .export-jewelry-modal .modal-content .modal-header {
    font-size: 18px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .export-jewelry-modal .modal-content .modal-header {
        font-size: 16px;
        font-weight: bold; } }
  .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 70%;
    margin: auto; }
    .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper h4 {
      position: relative;
      font-weight: 600; }
      @media (max-width: 767px) {
        .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper h4 {
          font-size: 15px;
          font-weight: bold; } }
      .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper h4 input[type="checkbox"] {
        margin: 0 0 0 20px;
        width: 18px;
        height: 18px;
        position: absolute; }
        @media (max-width: 767px) {
          .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper h4 input[type="checkbox"] {
            width: 15px !important;
            height: 15px !important; } }
    .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .field_toggle,
    .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .advanced_field_toggle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 5px; }
      .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .field_toggle label,
      .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .advanced_field_toggle label {
        width: 50%; }
        @media (max-width: 767px) {
          .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .field_toggle label,
          .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .advanced_field_toggle label {
            width: 70%; } }
      .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .field_toggle input[type="checkbox"],
      .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .advanced_field_toggle input[type="checkbox"] {
        margin: 0 0 0 10px;
        width: 18px;
        height: 18px; }
        @media (max-width: 767px) {
          .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .field_toggle input[type="checkbox"],
          .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .advanced_field_toggle input[type="checkbox"] {
            width: 15px !important;
            height: 15px !important; } }
      .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .field_toggle span,
      .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .advanced_field_toggle span {
        position: absolute;
        left: 100%;
        font-size: 22px;
        cursor: pointer;
        color: #337ab7;
        width: 5%;
        text-align: center; }
        @media (max-width: 767px) {
          .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .field_toggle span,
          .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .advanced_field_toggle span {
            font-size: 18px; } }
    .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper .advanced_field_toggle {
      display: none; }
    .export-jewelry-modal .modal-content .modal-body .fields_checkbox_wrapper button {
      font-size: 14px;
      font-weight: 800;
      margin: 15px 7px 2px 7px;
      border: 1.5px solid #58585a;
      padding: 5px 15px;
      border-radius: 0px;
      color: #ffffff;
      background-color: #58585a;
      outline: none; }

.show_bulk_add_modal {
  z-index: 10001; }
  @media (max-width: 767px) {
    .show_bulk_add_modal .modal-dialog {
      width: 100%; } }
  .show_bulk_add_modal .modal-content .modal-header {
    font-size: 18px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .show_bulk_add_modal .modal-content .modal-header {
        font-size: 16px;
        font-weight: bold; } }
  .show_bulk_add_modal .modal-content .modal-body .import_bulk_product_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px; }
    .show_bulk_add_modal .modal-content .modal-body .import_bulk_product_wrapper select {
      height: 33px; }
    .show_bulk_add_modal .modal-content .modal-body .import_bulk_product_wrapper textarea {
      height: 250px;
      padding: 10px; }
    .show_bulk_add_modal .modal-content .modal-body .import_bulk_product_wrapper button {
      width: 24%;
      height: 33px;
      background: #424242;
      border: none;
      color: #fff;
      outline: none; }

.show_alert_modal {
  z-index: 1000002 !important; }
  .show_alert_modal .modal-dialog {
    width: 50vw; }
    @media (max-width: 767px) {
      .show_alert_modal .modal-dialog {
        width: 100%; } }
  .show_alert_modal .modal-content .modal-header {
    font-size: 18px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .show_alert_modal .modal-content .modal-header {
        font-size: 16px;
        font-weight: bold; } }
  .show_alert_modal .modal-content .modal-body ul {
    list-style: none; }
    .show_alert_modal .modal-content .modal-body ul li {
      font-weight: bold;
      padding: 2px 0px; }
  .show_alert_modal .modal-content .modal-body button {
    width: 24%;
    height: 33px;
    background: #424242;
    border: none;
    color: #fff;
    outline: none; }
  .show_alert_modal .modal-content .modal-body .price_check_wrapper {
    display: flex;
    flex-direction: column; }
    .show_alert_modal .modal-content .modal-body .price_check_wrapper .price_check_item_wrapper {
      margin: 4px; }
      .show_alert_modal .modal-content .modal-body .price_check_wrapper .price_check_item_wrapper .price_check_item {
        width: 40%; }
      .show_alert_modal .modal-content .modal-body .price_check_wrapper .price_check_item_wrapper button {
        height: auto;
        width: 15%;
        margin-left: 6px;
        padding: 4px 6px; }
    .show_alert_modal .modal-content .modal-body .price_check_wrapper > button:last-child {
      margin-top: 20px !important; }
  .show_alert_modal .modal-content .modal-body .price_check_table {
    width: 100%; }
    .show_alert_modal .modal-content .modal-body .price_check_table th {
      padding: 2px 12px 2px;
      margin-bottom: 5px;
      width: 10px;
      border-right: 1px solid #8a8a8a; }
    .show_alert_modal .modal-content .modal-body .price_check_table > thead tr th:last-child {
      border: none !important; }
    .show_alert_modal .modal-content .modal-body .price_check_table td {
      padding: 2px 12px 2px;
      border-right: 1px solid #8a8a8a; }
      .show_alert_modal .modal-content .modal-body .price_check_table td button {
        height: auto;
        width: auto;
        padding: 4px 6px; }
    .show_alert_modal .modal-content .modal-body .price_check_table > tbody tr td:last-child {
      border: none !important; }
    .show_alert_modal .modal-content .modal-body .price_check_table > tbody tr:last-child td {
      text-align: center;
      padding-top: 20px; }
      .show_alert_modal .modal-content .modal-body .price_check_table > tbody tr:last-child td button {
        width: 10%; }

.email-template-modal,
.template-edit-modal {
  z-index: 9999 !important; }
  @media (max-width: 767px) {
    .email-template-modal .modal-dialog,
    .template-edit-modal .modal-dialog {
      width: 100%; } }
  .email-template-modal .modal-dialog .modal-content .modal-header,
  .template-edit-modal .modal-dialog .modal-content .modal-header {
    font-weight: 600;
    font-size: 18px; }
  .email-template-modal .modal-dialog .modal-content .modal-body .template_list,
  .email-template-modal .modal-dialog .modal-content .modal-body .template_edit,
  .template-edit-modal .modal-dialog .modal-content .modal-body .template_list,
  .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .email-template-modal .modal-dialog .modal-content .modal-body .template_list h5,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_edit h5,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_list h5,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit h5 {
      font-weight: 400;
      font-size: 16px; }
    .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_container,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_container,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_container,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_container,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 6px;
      width: 100%;
      border-bottom: 0.5px solid lightgrey;
      padding: 5px;
      gap: 15px; }
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_container label,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input label,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_container label,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input label,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_container label,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input label,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_container label,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input label {
        width: 75%;
        font-weight: 500; }
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_container button,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input button,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_container button,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input button,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_container button,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input button,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_container button,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input button {
        border-radius: 0;
        border: 0.5px solid; }
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_container input,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_container textarea,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_container select,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input input,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input textarea,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input select,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_container input,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_container textarea,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_container select,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input input,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input textarea,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input select,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_container input,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_container textarea,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_container select,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input input,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input textarea,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input select,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_container input,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_container textarea,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_container select,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input input,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input textarea,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input select {
        width: 80%; }
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_container textarea,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input textarea,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_container textarea,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input textarea,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_container textarea,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input textarea,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_container textarea,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input textarea {
        height: 220px; }
    .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_container:last-child,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_container:last-child,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_container:last-child,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_container:last-child,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input {
      border: none !important; }
    .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input {
      column-gap: 10px; }
      .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input label,
      .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input label,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input label,
      .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input label {
        width: 15% !important; }
    .email-template-modal .modal-dialog .modal-content .modal-body .template_list .template_input:last-child,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_edit .template_input:last-child,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_list .template_input:last-child,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit .template_input:last-child {
      justify-content: flex-end; }
    .email-template-modal .modal-dialog .modal-content .modal-body .template_list button,
    .email-template-modal .modal-dialog .modal-content .modal-body .template_edit button,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_list button,
    .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit button {
      width: 10%; }
      @media (max-width: 767px) {
        .email-template-modal .modal-dialog .modal-content .modal-body .template_list button,
        .email-template-modal .modal-dialog .modal-content .modal-body .template_edit button,
        .template-edit-modal .modal-dialog .modal-content .modal-body .template_list button,
        .template-edit-modal .modal-dialog .modal-content .modal-body .template_edit button {
          width: 16%; } }

.hold_notes_modal {
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: hidden; }
  .hold_notes_modal .modal-dialog {
    width: 80%;
    max-height: 70%; }
    @media (max-width: 767px) {
      .hold_notes_modal .modal-dialog {
        width: 95%;
        max-height: 100%; } }
    .hold_notes_modal .modal-dialog .modal-content {
      height: 100%; }
      .hold_notes_modal .modal-dialog .modal-content .modal-header {
        height: 15%; }
      .hold_notes_modal .modal-dialog .modal-content .modal-body {
        height: 85%; }
        .hold_notes_modal .modal-dialog .modal-content .modal-body .hold_notes_container {
          overflow-y: auto;
          height: 100%; }

.hold_notes_create_modal {
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: hidden; }
  .hold_notes_create_modal .modal-dialog {
    max-width: 90%;
    min-width: 70%;
    max-height: 90%; }
    @media (max-width: 767px) {
      .hold_notes_create_modal .modal-dialog {
        max-width: 100%;
        min-width: 70%;
        max-height: 100%; } }
    .hold_notes_create_modal .modal-dialog .modal-content {
      height: 100%; }
      .hold_notes_create_modal .modal-dialog .modal-content .modal-header {
        height: 15%; }
      .hold_notes_create_modal .modal-dialog .modal-content .modal-body {
        height: 85%; }
        .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container {
          overflow-y: auto;
          height: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          /* align-items: center; */
          row-gap: 10px; }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .table_container {
            width: 100%;
            overflow-y: scroll; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .table_container .table {
              font-size: 12px; }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .table_container .table thead th {
                white-space: nowrap;
                font-weight: bolder; }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container hr {
            width: 100%;
            margin: 10px 0px !important;
            border-top: 2px solid #c7c7c7 !important; }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper {
            width: 45%;
            display: flex;
            flex-direction: row;
            align-items: flex-start; }
            @media (max-width: 767px) {
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper {
                width: 100% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper label {
              width: 40%; }
              @media (max-width: 767px) {
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper label {
                  width: 35% !important; } }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper label span {
                color: red !important; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper input[type="date"] {
              width: 25%;
              height: 33px;
              padding: 0px 6px; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select {
              width: 45%; }
              @media (max-width: 767px) {
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select {
                  width: 75% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select {
              font-size: 13px !important;
              height: 33px;
              font-size: 13px !important; }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-yk16xz-control,
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-1pahdxg-control {
                height: 33px;
                border: 1px solid;
                border-radius: 0;
                min-height: 33px; }
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-yk16xz-control .css-1hwfws3,
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-yk16xz-control .css-1wy0on6,
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-1pahdxg-control .css-1hwfws3,
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-1pahdxg-control .css-1wy0on6 {
                  height: 33px; }
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-yk16xz-control .css-1hwfws3 .css-1uccc91-singleValue,
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-yk16xz-control .css-1wy0on6 .css-1uccc91-singleValue,
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-1pahdxg-control .css-1hwfws3 .css-1uccc91-singleValue,
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-1pahdxg-control .css-1wy0on6 .css-1uccc91-singleValue {
                    top: 45%; }
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-yk16xz-control .css-1hwfws3,
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-1pahdxg-control .css-1hwfws3 {
                  -webkit-box-align: center;
                  align-items: center;
                  display: flex;
                  flex: 1 1 0%;
                  flex-wrap: nowrap;
                  padding: 2px 8px;
                  position: relative;
                  overflow-x: hidden;
                  overflow-y: hidden;
                  box-sizing: border-box; }
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-yk16xz-control .css-1hwfws3 .css-1rhbuit-multiValue,
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-1pahdxg-control .css-1hwfws3 .css-1rhbuit-multiValue {
                    display: none; }
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-yk16xz-control .css-1hwfws3 .css-1rhbuit-multiValue:nth-last-child(2),
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-1pahdxg-control .css-1hwfws3 .css-1rhbuit-multiValue:nth-last-child(2) {
                    background-color: #e6e6e6;
                    border-radius: 2px;
                    display: flex;
                    margin: 2px;
                    min-width: auto;
                    box-sizing: border-box; }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper .Select .css-26l3qy-menu .css-11unzgr {
                max-height: 150px;
                overflow-y: auto;
                padding-bottom: 4px;
                padding-top: 4px;
                position: relative;
                box-sizing: border-box; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper textarea,
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper input {
              border: 1px solid; }
              @media (max-width: 767px) {
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper textarea,
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper input {
                  width: 70% !important; } }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .basket__input.customer__input.form-group.has-feedback {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 0px; }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .customer__input .input__dropdown__container {
            width: 70%;
            display: flex;
            flex-direction: column; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .customer__input .input__dropdown__container input {
              width: 100%;
              border-radius: 0px; }
              @media (max-width: 767px) {
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .customer__input .input__dropdown__container input {
                  width: 100% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .customer__input .input__dropdown__container span.form-control-feedback {
              display: none; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .customer__input .input__dropdown__container .downshift-dropdown {
              list-style: none;
              margin: 0;
              padding: 0;
              border: 0.5px solid #bbbbbb;
              position: absolute;
              top: 100%;
              left: 27%;
              max-height: 250px;
              overflow: auto;
              z-index: 9999;
              width: 70%; }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .customer__input .input__dropdown__container .downshift-dropdown li {
                border-bottom: 0.5px solid #bbbbbb;
                padding: 5px; }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) {
            width: 35% !important; }
            @media (max-width: 767px) {
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) {
                width: 100% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) label {
              width: 28% !important; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) input[type="date"] {
              width: 35%; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) .Select {
              width: 70% !important; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) .basket__input.customer__input.form-group.has-feedback {
              display: flex;
              align-items: center;
              width: 100%;
              margin-bottom: 0px; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) .customer__input .input__dropdown__container {
              width: 70%;
              display: flex;
              flex-direction: column; }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) .customer__input .input__dropdown__container input {
                width: 100%;
                border-radius: 0px; }
                @media (max-width: 767px) {
                  .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) .customer__input .input__dropdown__container input {
                    width: 100% !important; } }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) .customer__input .input__dropdown__container span.form-control-feedback {
                display: none; }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) .customer__input .input__dropdown__container .downshift-dropdown {
                list-style: none;
                margin: 0;
                padding: 0;
                border: 0.5px solid #bbbbbb;
                position: absolute;
                top: 100%;
                left: 27%;
                max-height: 250px;
                overflow: auto;
                z-index: 9999;
                width: 70%; }
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(odd) .customer__input .input__dropdown__container .downshift-dropdown li {
                  border-bottom: 0.5px solid #bbbbbb;
                  padding: 5px; }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) {
            width: 65%;
            flex-wrap: wrap; }
            @media (max-width: 767px) {
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) {
                width: 100% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) label {
              width: 25% !important; }
              @media (max-width: 767px) {
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) label {
                  width: 28% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) .Select {
              width: 38% !important; }
              @media (max-width: 767px) {
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) .Select {
                  width: 70% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) .options_selected {
              width: 100%;
              height: 33px;
              display: flex;
              flex-direction: row;
              gap: 8px;
              overflow-x: auto;
              overflow-y: hidden;
              align-items: center; }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) .options_selected span {
                background-color: #e6e6e6;
                border-radius: 2px;
                padding: 3px 10px 3px 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
                font-size: 12px; }
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-child(even) .options_selected i {
                font-style: initial;
                margin: 0px 0px 0px 6px;
                cursor: pointer; }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(3) {
            width: 100%; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(3) label {
              width: 10% !important; }
              @media (max-width: 767px) {
                .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(3) label {
                  width: 28% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(3) textarea {
              width: 67%;
              height: 100px; }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(3) .Select {
              width: 21%; }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(2) {
            width: 45% !important;
            gap: 5px;
            padding-left: 10%; }
            @media (max-width: 767px) {
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(2) {
                padding-left: 28% !important; } }
            .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(2) label {
              margin: 0 0 2px; }
            @media (max-width: 767px) {
              .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_note_field_wrapper:nth-last-child(2) input {
                width: 5% !important; } }
          .hold_notes_create_modal .modal-dialog .modal-content .modal-body .hold_notes_container .hold_notes_action_buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: 10px; }
