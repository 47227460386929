.fl_cover {
  height: 11in;
  margin: 0.75in 0.55in;

  .fl_cover_logo {
    text-align: center;
    margin-bottom: 40px;
    // height: 50%;
    // margin: auto;
    // padding: 20%;

    img {
      vertical-align: middle;
      max-width: 60%;
    }
  }
  .fl_cover_desc {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .fl_cover_image {
    text-align: center;
    height: 45%;
    margin-top: 20px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .fl_cover_footer {
    margin-top: 30px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
  }
}
.cover_footer {
  position: absolute;
  top: 94%;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}
