/*!
 * https://getbootstrap.com/docs/4.1/utilities/flex/
 * Bootstrap v4.1.3 flex utilities (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (max-width: 767px) {
  .customer .es__flex-xs-order-1 {
    order: -1; } }

.customer .es__position-relative {
  position: relative; }

.customer .clickable {
  cursor: pointer; }

.customer .toggle-filters-listCol {
  display: none; }

.customer .es__base-component {
  font-family: "Proxima Nova Regular" !important; }

.customer .es-scroll-button .ScrollUpButton__Container {
  border: 5px solid lightgrey !important;
  margin-right: 12px; }
  .customer .es-scroll-button .ScrollUpButton__Container > svg {
    fill: lightgrey; }

.customer .es-product-loader {
  text-align: center;
  padding: 5em;
  font-size: 22px; }

.customer .es-tab-content-loader {
  text-align: center;
  padding: 5em;
  font-size: 22px; }

.customer .css-u5onnz li {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important; }

.customer .css-ixn26d {
  margin-left: 30px; }

.customer .css-16nr2ca {
  top: 7px !important; }

.customer .css-eaw4vb {
  top: 7px !important; }

.customer .css-dnqvel {
  font-size: 11px !important; }

.customer .css-10df2xd {
  top: 7px !important; }

.customer .banner {
  opacity: 0.4;
  max-width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 767px) {
    .customer .banner {
      display: none; } }

.customer .show-code {
  display: flex;
  margin-top: 7px;
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect changes */ }
  .customer .show-code [type="checkbox"]:not(:checked),
  .customer .show-code [type="checkbox"]:checked {
    position: absolute;
    left: -9999px; }
  .customer .show-code [type="checkbox"]:not(:checked) + label,
  .customer .show-code [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #58585a; }
  .customer .show-code [type="checkbox"]:not(:checked) + label:before,
  .customer .show-code [type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 14px;
    height: 14px;
    border: 1px solid #b3afaf;
    background: #fff;
    border-radius: 3px; }
  .customer .show-code [type="checkbox"]:not(:checked) + label:after,
  .customer .show-code [type="checkbox"]:checked + label:after {
    content: "\2713\0020";
    position: absolute;
    top: 5px;
    left: 2px;
    font-size: 13px;
    line-height: 0.8;
    color: black;
    transition: all 0.2s; }
  .customer .show-code [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  .customer .show-code [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
    top: 6px;
    left: 1px;
    font-family: FontAwesome;
    font-size: 12px;
    content: "\f00c";
    color: gray; }

.customer .see-more-btn {
  margin-top: 7px;
  font-size: 12px; }

.customer .page {
  margin-top: 15px; }
  .customer .page .es__filters {
    background: #f5f7f8;
    padding: 10px 5px;
    border: 1px solid #d2d4d7;
    margin-left: 15px;
    margin-right: 15px; }
    @media (max-width: 767px) {
      .customer .page .es__filters .mobile-view-all {
        display: block; } }
    @media (max-width: 767px) {
      .customer .page .es__filters .mobile-view-less {
        display: none; } }
    .customer .page .es__filters .es__filters__line-break {
      width: 97%;
      height: 20px;
      border-bottom: 1.4px solid #58585a;
      text-align: center;
      margin: 10px auto 30px auto; }
      @media (max-width: 767px) {
        .customer .page .es__filters .es__filters__line-break {
          height: 14px;
          margin: 10px auto 16px auto; } }
      .customer .page .es__filters .es__filters__line-break .es__filters-title {
        font-size: 28px;
        background-color: #f3f5f6;
        padding: 0 23px; }
        @media (max-width: 767px) {
          .customer .page .es__filters .es__filters__line-break .es__filters-title {
            padding: 0px 5px;
            font-size: 20px; } }
    .customer .page .es__filters .switching {
      margin: 8px 0;
      text-align: center; }
      .customer .page .es__filters .switching > span {
        margin-left: 24px; }
        @media (max-width: 767px) {
          .customer .page .es__filters .switching > span {
            margin-left: 14px; } }
      .customer .page .es__filters .switching .switching__radio-label {
        margin-left: 8px;
        font-size: 14px;
        color: #58585a;
        font-weight: 100; }
      .customer .page .es__filters .switching .switching__label--active {
        font-weight: 700; }
    .customer .page .es__filters .es__filters-list-column {
      padding-left: 20px;
      padding-right: 20px;
      width: 25%; }
      .customer .page .es__filters .es__filters-list-column .css-amqc5y {
        visibility: hidden;
        position: relative; }
      .customer .page .es__filters .es__filters-list-column .css-amqc5y:after {
        visibility: visible;
        position: absolute;
        content: "to";
        color: #8a8a8a; }
      .customer .page .es__filters .es__filters-list-column .serial-barcode-grouped {
        display: flex;
        justify-content: space-between; }
        .customer .page .es__filters .es__filters-list-column .serial-barcode-grouped .camera-icon {
          display: none; }
          @media (max-width: 767px) {
            .customer .page .es__filters .es__filters-list-column .serial-barcode-grouped .camera-icon {
              display: block;
              width: 30px;
              height: 30px;
              margin-top: 22px;
              opacity: 0.5; } }
      @media (max-width: 767px) {
        .customer .page .es__filters .es__filters-list-column {
          width: 100%; } }
      .customer .page .es__filters .es__filters-list-column .carat-range-input div > div > input {
        background: #fff; }
      .customer .page .es__filters .es__filters-list-column .carat-range-input .carat-range-input__carat-slider {
        display: flex;
        align-items: center;
        vertical-align: middle;
        width: 100%; }
        .customer .page .es__filters .es__filters-list-column .carat-range-input .carat-range-input__carat-slider h2 {
          font-size: 14px;
          font-family: "Proxima Nova Regular" !important;
          line-height: 23px;
          color: #58585a;
          margin: 0px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .customer .page .es__filters .es__filters-list-column .carat-range-input .carat-range-input__carat-input {
        width: 100%;
        height: 30px; }
      .customer .page .es__filters .es__filters-list-column .price-range {
        margin-bottom: 8px; }
        .customer .page .es__filters .es__filters-list-column .price-range .price-range__input > div {
          width: 100%; }
          .customer .page .es__filters .es__filters-list-column .price-range .price-range__input > div > div > input {
            background: #fff; }
            @media (-webkit-min-device-pixel-ratio: 0) {
              .customer .page .es__filters .es__filters-list-column .price-range .price-range__input > div > div > input {
                height: 30px; } }
          .customer .page .es__filters .es__filters-list-column .price-range .price-range__input > div > h2 {
            font-size: 14px;
            color: #58585a;
            line-height: 23px;
            margin: 0; }
        .customer .page .es__filters .es__filters-list-column .price-range .price-range__input .price-range__input--price-input {
          width: 100%;
          height: 30px; }
      .customer .page .es__filters .es__filters-list-column .check-filters {
        margin-top: 24px; }
        @media (max-width: 767px) {
          .customer .page .es__filters .es__filters-list-column .check-filters {
            min-height: 0px; } }
        .customer .page .es__filters .es__filters-list-column .check-filters .check-filters__toggle {
          display: flex; }
          .customer .page .es__filters .es__filters-list-column .check-filters .check-filters__toggle .check-filters__toggle--check-select a {
            font-size: 14px;
            background: #ffff;
            border-color: #b3afaf;
            min-height: 90%;
            width: 14px;
            margin-top: 9px;
            padding: 0; }
          .customer .page .es__filters .es__filters-list-column .check-filters .check-filters__toggle > h2 {
            margin-top: 10px;
            margin-left: 10px;
            font-size: 14px;
            color: #58585a;
            margin-bottom: 0px; }
        .customer .page .es__filters .es__filters-list-column .check-filters .toggle__filters ul {
          list-style: none;
          padding: 0;
          margin: 0; }
          .customer .page .es__filters .es__filters-list-column .check-filters .toggle__filters ul input[type="checkbox"] {
            height: 14px;
            width: 14px;
            margin-right: 7px; }
          .customer .page .es__filters .es__filters-list-column .check-filters .toggle__filters ul li {
            display: flex; }
            .customer .page .es__filters .es__filters-list-column .check-filters .toggle__filters ul li .toggle--label {
              font-size: 14px;
              color: #58585a;
              line-height: 25px;
              margin: 0px;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
          .customer .page .es__filters .es__filters-list-column .check-filters .toggle__filters ul li:first-child {
            display: none; }
      .customer .page .es__filters .es__filters-list-column .include-sold-range,
      .customer .page .es__filters .es__filters-list-column .is-rtv-range,
      .customer .page .es__filters .es__filters-list-column .is-kwiat-range,
      .customer .page .es__filters .es__filters-list-column .is-tiara-range,
      .customer .page .es__filters .es__filters-list-column .in-production-range {
        display: none; }
    .customer .page .es__filters .advanced-search-btn {
      border: none;
      background: transparent;
      font-size: 12px;
      margin-top: 10px;
      padding: 0;
      outline: none; }
    .customer .page .es__filters .es__advanced-search {
      transition: all 0.5s ease-in-out;
      display: flex;
      margin-top: 22px; }
      @media (max-width: 767px) {
        .customer .page .es__filters .es__advanced-search {
          display: block; } }
      .customer .page .es__filters .es__advanced-search .advanced-search-heading {
        padding: 0 15px; }
      .customer .page .es__filters .es__advanced-search .advanced-search-border {
        display: flex;
        border: 1px solid #d2d4d7;
        padding: 10px 15px; }
      .customer .page .es__filters .es__advanced-search .wr-advanced {
        display: block; }
        .customer .page .es__filters .es__advanced-search .wr-advanced .grouped-fields {
          display: flex;
          justify-content: space-between; }
          .customer .page .es__filters .es__advanced-search .wr-advanced .grouped-fields .grouped-fields__wr {
            width: 41%; }
            .customer .page .es__filters .es__advanced-search .wr-advanced .grouped-fields .grouped-fields__wr > div > div > h2 {
              display: block;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
          .customer .page .es__filters .es__advanced-search .wr-advanced .grouped-fields .grouped-fields__eternpart {
            width: 41%; }
            .customer .page .es__filters .es__advanced-search .wr-advanced .grouped-fields .grouped-fields__eternpart > div > div > h2 {
              display: block;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
      .customer .page .es__filters .es__advanced-search .es__filter-columns {
        padding: 0 5px; }
        .customer .page .es__filters .es__advanced-search .es__filter-columns .jewelry-advanced-fields {
          display: block; }
      .customer .page .es__filters .es__advanced-search .engagement-rings-lcol {
        padding: 0 20px 0 0;
        width: 50%; }
      .customer .page .es__filters .es__advanced-search .engagement-rings-rcol {
        padding: 0 0 0 20px;
        width: 50%; }
      .customer .page .es__filters .es__advanced-search .center-size-range {
        margin-bottom: 8px; }
        .customer .page .es__filters .es__advanced-search .center-size-range .e1i1ftc40 {
          display: none; }
        .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input {
          display: none; }
          .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input > div {
            width: 30%; }
            .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input > div > div > input {
              background: #fff; }
              @media screen and (-webkit-min-device-pixel-ratio: 0) {
                .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input > div > div > input {
                  height: 30px; } }
            .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input > div > h2 {
              font-size: 14px;
              color: #58585a;
              line-height: 26px;
              margin: 0px; }
          .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input span {
            margin: 2px 0 0;
            text-align: center;
            display: inline-block;
            width: 45px; }
          .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input .size-input-container {
            width: 73%;
            height: 30px; }
            @media screen and (min-width: 1330px) and (max-width: 1430px) {
              .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input .size-input-container {
                width: 73%; } }
            @media screen and (min-width: 1100px) and (max-width: 1320px) {
              .customer .page .es__filters .es__advanced-search .center-size-range .size-range-input .size-input-container {
                width: 73%; } }
        .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container > h2 {
          font-size: 14px;
          color: #58585a;
          line-height: 23px;
          margin: 0;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns {
          display: flex; }
          .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns > span {
            width: 18%;
            text-align: center;
            color: #8a8a8a;
            padding-top: 4px; }
          .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns .start-range,
          .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns .end-range {
            width: 41%;
            margin-top: 0px; }
            @media (max-width: 767px) {
              .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns .start-range,
              .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns .end-range {
                width: 40%; } }
            .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns .start-range > div > button,
            .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns .end-range > div > button {
              font-size: 12px;
              color: #58585a;
              min-height: 30px; }
            .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns .start-range > div > ul,
            .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .range-dropdowns .end-range > div > ul {
              top: 30px;
              font-size: 11px; }
        .customer .page .es__filters .es__advanced-search .center-size-range .size-input-dropdowns-container .hide__multilist {
          display: none; }
    .customer .page .es__filters .search-btn {
      text-align: center;
      margin: 19px 42px;
      padding: 0px; }
      @media (max-width: 767px) {
        .customer .page .es__filters .search-btn {
          margin: 19px; } }
      .customer .page .es__filters .search-btn .search-btn__button {
        display: inline-block;
        outline: none;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        margin: 2px 0;
        border: solid 1px transparent;
        border-radius: 0px;
        padding: 0.5em 3.5em;
        color: #ffffff;
        background-color: #58585a;
        width: 285px; }
        @media (max-width: 767px) {
          .customer .page .es__filters .search-btn .search-btn__button {
            width: 100%; } }
    .customer .page .es__filters .search-btn__button.focus,
    .customer .page .es__filters .search-btn__button:focus,
    .customer .page .es__filters .search-btn__button:hover {
      color: #fff; }
    .customer .page .es__filters .mobile-view-option {
      margin: auto;
      text-align: center; }
      @media (min-width: 767px) {
        .customer .page .es__filters .mobile-view-option {
          display: none; } }
  .customer .page .es__filters__selection {
    display: flex;
    justify-content: space-between; }
    .customer .page .es__filters__selection .selected--filters--container {
      padding: 0 15px; }
      .customer .page .es__filters__selection .selected--filters--container .clear-filter-btn,
      .customer .page .es__filters__selection .selected--filters--container .clear-all-btn {
        font-size: 14px;
        padding: 4px 15px 4px 0;
        -webkit-appearance: none;
        border: none;
        outline: none;
        background: transparent; }
        .customer .page .es__filters__selection .selected--filters--container .clear-filter-btn .clear--icon,
        .customer .page .es__filters__selection .selected--filters--container .clear-all-btn .clear--icon {
          margin-left: 6px;
          opacity: 0.7; }
    .customer .page .es__filters__selection .dropdown-sort {
      float: right;
      margin-right: 30px;
      margin-top: 10px; }
    .customer .page .es__filters__selection .dropdown-sort > div > ul > ul {
      display: flex;
      flex-direction: column;
      width: 100%; }
      @media (max-width: 767px) {
        .customer .page .es__filters__selection .dropdown-sort > div > ul > ul {
          margin: 0; } }
      .customer .page .es__filters__selection .dropdown-sort > div > ul > ul > li:hover {
        background-color: #efefef; }
    .customer .page .es__filters__selection div.dropdown-sort > div > ul {
      left: -78px; }
    .customer .page .es__filters__selection #sort {
      font-size: 14px !important;
      margin-right: 10px;
      background: none !important;
      color: inherit;
      border: none;
      padding: 0 !important;
      font: inherit;
      cursor: pointer;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
    .customer .page .es__filters__selection ul.nav-sort > li {
      padding: 5px 0 0 10px;
      line-height: 25px;
      font-family: "Proxima Nova Regular";
      color: #424242; }
  .customer .page .es__results {
    padding: 0px;
    background: #fff; }
    .customer .page .es__results > div {
      font-size: 14px;
      padding: 5px 15px; }
      .customer .page .es__results > div .csv-btn {
        color: #58585a;
        border: none;
        background: transparent;
        font-size: 14px;
        outline: none;
        text-decoration: underline; }
    .customer .page .es__results .es__product {
      display: flex;
      margin-bottom: 5px;
      border: 1px solid #d2d4d7;
      background: #f5f7f8;
      padding: 12px 0 12px;
      font-size: 12px; }
      @media (max-width: 767px) {
        .customer .page .es__results .es__product {
          border: none;
          font-size: 0.75em; } }
      .customer .page .es__results .es__product .es__product__hovered {
        padding: 4px 2px 4px 2px !important;
        margin: 0 4px;
        display: none;
        flex-direction: column;
        height: auto !important;
        justify-content: space-around; }
        .customer .page .es__results .es__product .es__product__hovered .share-icons {
          width: 20px;
          padding: 0 3px;
          opacity: 0.7;
          cursor: pointer; }
      .customer .page .es__results .es__product .share-hovered {
        display: flex; }
        @media (max-width: 767px) {
          .customer .page .es__results .es__product .share-hovered {
            display: none; } }
      .customer .page .es__results .es__product .product-index_container {
        display: flex;
        align-items: center;
        vertical-align: middle;
        align-self: center;
        padding: 0 10px;
        width: 3%; }
        @media (max-width: 767px) {
          .customer .page .es__results .es__product .product-index_container {
            padding: 0 5px;
            width: auto; } }
      .customer .page .es__results .es__product .serial-hovered {
        display: none; }
        @media (max-width: 767px) {
          .customer .page .es__results .es__product .serial-hovered {
            display: flex; } }
      .customer .page .es__results .es__product .es__product-image-title {
        background: #fff;
        border: 1px solid #e3e3e3;
        background: #fff;
        flex-basis: 11%; }
        @media (max-width: 767px) {
          .customer .page .es__results .es__product .es__product-image-title {
            flex-basis: 25%; } }
        .customer .page .es__results .es__product .es__product-image-title .es__product-image-container {
          display: flex;
          align-items: center;
          margin-top: 6px;
          margin-left: 4px;
          margin-right: 4px;
          margin-bottom: 0;
          text-align: center;
          height: 100%;
          justify-content: center; }
          .customer .page .es__results .es__product .es__product-image-title .es__product-image-container .es__product-image {
            max-width: 100%; }
        .customer .page .es__results .es__product .es__product-image-title .not-clickable {
          cursor: default; }
      .customer .page .es__results .es__product .es__bordered-box {
        padding: 10px 2px 4px 2px;
        border: 1px solid #e3e3e3;
        background: #fff;
        height: 100%; }
      .customer .page .es__results .es__product .es__product-details {
        display: flex;
        flex-grow: 1; }
        .customer .page .es__results .es__product .es__product-details > div {
          padding-left: 8px;
          width: 24%; }
        .customer .page .es__results .es__product .es__product-details .es__product-basic-details {
          width: 73%; }
          @media (max-width: 767px) {
            .customer .page .es__results .es__product .es__product-details .es__product-basic-details {
              width: 100%;
              margin-right: 10px; } }
          .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading {
            position: absolute;
            top: -17px;
            left: 0;
            right: 0;
            text-align: left;
            display: flex;
            align-items: center;
            margin-left: 13px;
            margin-right: 5px; }
            .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading .iframe-link {
              white-space: nowrap;
              background: #f5f7f8;
              padding-left: 2px; }
            .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading .es__istiara {
              position: relative;
              width: 25px;
              margin-left: 0 !important;
              padding: 0 !important;
              height: 17px;
              bottom: 0;
              content: url("https://cdn.kwiat.com/apps/kwiat-elastic-search/test-upgrade/icons/KwiatLogo-TiaraOnly.svg");
              background: #f5f7f8; }
            .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading .es__isfl {
              position: relative;
              height: 17px;
              margin-left: 0 !important;
              padding: 0 !important;
              bottom: 2px;
              content: url("https://cdn.kwiat.com/apps/kwiat-elastic-search/test-upgrade/icons/FL-DarkGrey.svg");
              background: #f5f7f8; }
            .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading .es__product-title-interval {
              padding: 0 6px;
              background: #f5f7f8; }
              @media (max-width: 767px) {
                .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading .es__product-title-interval {
                  display: none; } }
            .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading .es__product-title-description {
              background: #f5f7f8;
              text-transform: uppercase;
              letter-spacing: 0.3px;
              white-space: nowrap;
              width: 12em;
              overflow: hidden;
              text-overflow: ellipsis;
              width: fit-content; }
              .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading .es__product-title-description .crop {
                white-space: normal; }
              @media (max-width: 767px) {
                .customer .page .es__results .es__product .es__product-details .es__product-basic-details .es__style-number-heading .es__product-title-description {
                  display: none; } }
        .customer .page .es__results .es__product .es__product-details .es__product-status-details {
          width: 15%;
          padding-right: 8px; }
          @media (max-width: 767px) {
            .customer .page .es__results .es__product .es__product-details .es__product-status-details {
              display: none; } }
        .customer .page .es__results .es__product .es__product-details .es__product-pricing-details {
          width: 15%; }
          @media (max-width: 767px) {
            .customer .page .es__results .es__product .es__product-details .es__product-pricing-details {
              display: none; } }
      @media (max-width: 767px) {
        .customer .page .es__results .es__product .es__product-details-table {
          display: none; } }
      .customer .page .es__results .es__product table {
        width: 100%;
        margin-left: 4px; }
        .customer .page .es__results .es__product table tbody {
          line-height: 11px; }
          .customer .page .es__results .es__product table tbody tr {
            height: 18px; }
          .customer .page .es__results .es__product table tbody td {
            text-align: left;
            width: 25%; }
        .customer .page .es__results .es__product table .es__product-details-table__label-row td {
          font-size: 10px;
          color: #a0a0a0;
          vertical-align: top; }
        .customer .page .es__results .es__product table .es__product-details-table__data-row td {
          font-size: 12px;
          color: #3d4148;
          vertical-align: bottom;
          padding-right: 5px; }
      .customer .page .es__results .es__product .es__mobile-product {
        display: none;
        width: 100%; }
        @media (max-width: 767px) {
          .customer .page .es__results .es__product .es__mobile-product {
            display: block;
            text-align: right; } }
        .customer .page .es__results .es__product .es__mobile-product table {
          width: 100%; }
          .customer .page .es__results .es__product .es__mobile-product table tbody {
            line-height: 21px; }
            .customer .page .es__results .es__product .es__mobile-product table tbody td {
              padding-left: 3px;
              font-weight: 700;
              width: 50%; }
              .customer .page .es__results .es__product .es__mobile-product table tbody td .es__mobile-product-details-table__label {
                font-size: 0.62em;
                color: #a0a0a0;
                margin-left: 6px;
                font-weight: 100; }
              .customer .page .es__results .es__product .es__mobile-product table tbody td button {
                width: 22px;
                padding: 0;
                margin-bottom: 3px;
                border-radius: 0px;
                float: right;
                margin-right: 6px;
                border: #d6e0e8 1px solid;
                box-shadow: none; }

.customer .iframeModal {
  cursor: pointer; }
  .customer .iframeModal .modal-dialog {
    width: 95vw;
    max-height: 100vh; }
    @media (max-width: 767px) {
      .customer .iframeModal .modal-dialog {
        width: 100%;
        max-height: 90%; } }
  .customer .iframeModal .modal-body {
    padding: 0; }
  .customer .iframeModal iframe {
    width: 100%;
    min-height: 85vh; }

.customer .iframe-link {
  cursor: pointer; }

.customer .image-modal {
  cursor: pointer; }
  @media (max-width: 767px) {
    .customer .image-modal .modal-dialog {
      min-width: 95%;
      max-height: 90%; } }
  .customer .image-modal .modal-dialog .modal-content {
    border-radius: 0;
    border: 1px solid grey; }
    .customer .image-modal .modal-dialog .modal-content .modal-body {
      min-height: 40vh; }
  .customer .image-modal .modal-dialog .modal-img {
    width: 100%;
    max-width: 1024px;
    max-height: 768px;
    min-height: 36vh; }
  .customer .image-modal .modal-header {
    border-bottom: 0;
    padding: 15px 15px 0px 15px; }
    .customer .image-modal .modal-header .close:focus {
      outline: none; }
  .customer .image-modal .image-modal-con {
    text-align: center; }

.customer .full-popup-modal {
  background: none !important;
  padding: 0 !important;
  font-family: "Proxima Nova Regular"; }
  .customer .full-popup-modal .customer-name {
    color: red !important; }
  .customer .full-popup-modal .product-ring-detail {
    min-width: 430px;
    margin-right: 8px; }
    .customer .full-popup-modal .product-ring-detail .result__product--info-box {
      padding-left: 5px !important; }
  .customer .full-popup-modal .product-stock_details {
    width: 28%;
    min-width: 280px;
    margin-right: 8px; }
    .customer .full-popup-modal .product-stock_details .result__product--info-box {
      padding-left: 5px !important; }
    .customer .full-popup-modal .product-stock_details .result__product--info-box {
      min-height: 130px; }
    .customer .full-popup-modal .product-stock_details .result__product--info_background {
      padding: 0px 10px 0px 10px;
      line-height: 18px; }
  .customer .full-popup-modal .edit-btn {
    float: right;
    margin-right: 16px; }
  .customer .full-popup-modal .modal-dialog {
    max-width: 50%; }
    .customer .full-popup-modal .modal-dialog .product-d-flex-container {
      margin-top: 10px; }
      .customer .full-popup-modal .modal-dialog .product-d-flex-container div {
        padding-left: 0; }
    .customer .full-popup-modal .modal-dialog .modal-content {
      border-radius: 0;
      border: 2px solid #8a8a8a;
      background-color: ghostwhite; }
      .customer .full-popup-modal .modal-dialog .modal-content img {
        width: 100%;
        height: auto; }
      .customer .full-popup-modal .modal-dialog .modal-content .product-img {
        border: 1px solid #e3e3e3;
        margin-bottom: 15px;
        background-color: #e3e3e3; }
      .customer .full-popup-modal .modal-dialog .modal-content h3 {
        text-align: center;
        color: red; }
      .customer .full-popup-modal .modal-dialog .modal-content .cust-name {
        color: red; }
      .customer .full-popup-modal .modal-dialog .modal-content .modal-header-con {
        text-align: center;
        border-bottom: 0; }
        .customer .full-popup-modal .modal-dialog .modal-content .modal-header-con .modal-title {
          color: #3d4148;
          margin-left: 66px; }

.customer .es__mobile-popup-modal .modal-dialog {
  width: 100%;
  max-height: 90%; }

.customer .es__mobile-popup-modal .modal-header {
  background: #f5f7f8;
  border: none;
  padding: 15px 0 0 0;
  position: relative;
  text-align: center; }
  .customer .es__mobile-popup-modal .modal-header > button {
    position: absolute;
    right: 16px; }

.customer .es__mobile-popup-modal .modal-body {
  background: #f5f7f8; }
  .customer .es__mobile-popup-modal .modal-body .es__product-modal-section1 {
    display: flex; }
    .customer .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-image-title {
      background: #fff;
      border: 1px solid #e3e3e3;
      background: #fff;
      flex-basis: 46%; }
      .customer .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-image-title .es__product-image-container {
        margin: 8px 4px 4px 4px;
        height: auto; }
    .customer .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-details {
      width: 100%;
      margin-left: 10px; }
      .customer .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-details > div {
        padding-left: 8px;
        width: 24%; }
      .customer .es__mobile-popup-modal .modal-body .es__product-modal-section1 .es__product-details .es__product-description {
        width: auto;
        display: grid; }
  .customer .es__mobile-popup-modal .modal-body .es__product-modal-section2 {
    margin-top: 5px; }
    .customer .es__mobile-popup-modal .modal-body .es__product-modal-section2 table {
      width: 95%;
      margin: 0px 9px; }
      .customer .es__mobile-popup-modal .modal-body .es__product-modal-section2 table tr {
        height: 27px; }
      .customer .es__mobile-popup-modal .modal-body .es__product-modal-section2 table td {
        width: 50%;
        font-size: 12px;
        color: #3d4148; }
        .customer .es__mobile-popup-modal .modal-body .es__product-modal-section2 table td .es__mobile-product-details-table__label {
          font-size: 10px;
          color: #8a8a8a;
          margin-left: 6px; }
        .customer .es__mobile-popup-modal .modal-body .es__product-modal-section2 table td hr {
          border-top: 1px solid #e3e3e3;
          margin-top: 10px;
          margin-bottom: 10px;
          display: block; }
  .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 {
    margin-top: 18px;
    display: flex;
    justify-content: space-between; }
    .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section,
    .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section {
      width: 49%;
      position: relative; }
      .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section table,
      .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section table {
        width: 100%; }
        .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section table tr,
        .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section table tr {
          height: 18px; }
        .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section table .es__product-details__label-row td,
        .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section table .es__product-details__label-row td {
          font-size: 10px;
          color: #a0a0a0;
          vertical-align: top;
          padding-left: 10px; }
        .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-pricing-section table .es__product-details__data-row td,
        .customer .es__mobile-popup-modal .modal-body .es__product-modal-section3 .es__product-status-section table .es__product-details__data-row td {
          font-size: 12px;
          color: #3d4148;
          vertical-align: bottom;
          padding-left: 10px; }
  .customer .es__mobile-popup-modal .modal-body .es__product-modal-section4 {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 8px 0 !important;
    max-height: 38px; }
    .customer .es__mobile-popup-modal .modal-body .es__product-modal-section4 .share-icons {
      width: 20px; }
  .customer .es__mobile-popup-modal .modal-body .es__bordered-box {
    padding: 6px 2px 6px 2px;
    border: 1px solid #e3e3e3;
    background: #fff; }

.customer .stock-popup-modal {
  font-family: "Proxima Nova Regular";
  background: none !important;
  padding: 0 !important; }
  .customer .stock-popup-modal .modal-dialog {
    max-width: 50%; }
    .customer .stock-popup-modal .modal-dialog .modal-content {
      border-radius: 0;
      border: 2px solid #8a8a8a; }
      .customer .stock-popup-modal .modal-dialog .modal-content .modal-header-con {
        text-align: center;
        border-bottom: 0; }
        .customer .stock-popup-modal .modal-dialog .modal-content .modal-header-con .modal-title {
          color: #3d4148; }
      .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details section {
        display: flex;
        justify-content: space-around; }
        .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details section > div {
          flex: 1 1 0; }
      .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box {
        padding: 10px 2px 2px 2px;
        border: 1px solid #e3e3e3;
        min-height: 100%;
        background: #fff; }
        .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__es__product-details-heading span {
          background: #fff !important;
          font-size: 16px; }
        .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background {
          padding-top: 15px !important;
          padding-left: 15px !important; }
          .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background .stock-details__info-box-label {
            color: #58585a !important;
            margin: 3px 0 8px 0;
            color: #3d4148; }
            .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background .stock-details__info-box-label a {
              color: #337ab7; }
            .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background .stock-details__info-box-label a:hover {
              color: #23527c; }
            .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background .stock-details__info-box-label span {
              margin-top: 2px;
              line-height: 10px; }
          .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background .stock-details__info-box-view-label {
            color: #8a8a8a;
            opacity: 0.5 !important;
            font-size: 14px !important;
            line-height: 24px !important;
            color: #58585a !important; }
          .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background .product-details_lcol {
            margin-right: 50px; }
            .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background .product-details_lcol .highlight-text {
              color: green !important; }
          .customer .stock-popup-modal .modal-dialog .modal-content .modal-body .stock-details .stock-details__info-box .stock-details__info-box-info_background .stock-details__info-box-line-break {
            display: block;
            font-size: 16px; }
  .customer .stock-popup-modal .see-more {
    margin-top: 5px;
    text-align: center; }
    .customer .stock-popup-modal .see-more .see-more-btn {
      background: #8a8a8a;
      background-image: none;
      text-shadow: none;
      color: #fff;
      width: 50%;
      border-radius: 0; }

.customer .light-title {
  color: #8a8a8a;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase; }

.customer .options-label {
  font-size: 14px;
  color: #19191ad9;
  font-weight: 700;
  display: inline; }

.customer .es__product-dark-title {
  color: #3d4148;
  font-family: "Proxima Nova Semibold";
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .customer .es__product-dark-title {
      font-size: 1em; } }

.customer .es__product-details-heading {
  padding: 5px;
  margin: 0;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  text-align: center; }
  @media (max-width: 767px) {
    .customer .es__product-details-heading {
      top: -12px; } }
  .customer .es__product-details-heading span {
    background: #f5f7f8; }

.customer .form-field-wrap {
  margin-bottom: 8px;
  width: 100%; }
  .customer .form-field-wrap .form-field {
    display: block; }
    .customer .form-field-wrap .form-field h2 {
      font-family: "Proxima Nova Regular";
      width: 100%;
      font-size: 14px;
      font-weight: 100;
      color: #58585a;
      line-height: 23px;
      margin: 0px;
      display: block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .customer .form-field-wrap .form-field > div {
      width: 100%; }
      .customer .form-field-wrap .form-field > div > input {
        font-size: 13px;
        height: 30px;
        background-color: #fff; }
      .customer .form-field-wrap .form-field > div > button {
        font-size: 13px;
        color: #58585a;
        min-height: 30px; }
        .customer .form-field-wrap .form-field > div > button > div {
          padding: 3px; }
      .customer .form-field-wrap .form-field > div > ul {
        top: 30px; }
        .customer .form-field-wrap .form-field > div > ul input {
          font-size: 13px; }
        .customer .form-field-wrap .form-field > div > ul > li {
          font-size: 11px; }

.customer .load__more--button {
  margin: 40px 0; }
  .customer .load__more--button button {
    border-radius: 5%;
    background: #58585a !important;
    color: #fff !important;
    text-shadow: none !important;
    border-color: #58585a !important; }
