.kwiat_cover {
  height: 11in;
  margin: 0.75in 0.55in;

  .kwiat_cover_logo {
    text-align: center;
    margin-bottom: 40px;    
    // height: 50%;
    // margin: auto;
    // padding: 20%;

    img {
      vertical-align: middle;
      max-width: 30%;
    }
  }
  .kwiat_cover_desc {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .kwiat_cover_image {
    text-align: center;
    height: 45%;
    margin-top: 20px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .kwiat_cover_footer {
    margin-top: 30px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
  }
}
